import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { BiSolidError } from "react-icons/bi";

const TicketFilters = ({
  groups,
  setGroups,
  setSelectedProperty,
  addGroup,
  selectedGroupIndex,
  setSelectedGroupIndex,
}) => {
  const [errors, setErrors] = useState({});
  useEffect(() => {
    console.log("groups", groups);
    validateFilters();
  }, [groups]);

  const getErrorCount = () => {
    let count = 0;

    Object.values(errors).forEach((groupErrors) => {
      count += Object.keys(groupErrors).length;
    });

    return count;
  };

  const validateFilters = () => {
    const newErrors = {};

    groups.forEach((group, groupIndex) => {
      group.filters.forEach((filter, filterIndex) => {
        const { operator, value } = filter;

        // Check if operator is not "is_known" or "is_unknown" and value is empty
        if (
          operator !== "is_known" &&
          operator !== "is_unknown" &&
          (value === "" || value === null || value === undefined)
        ) {
          if (!newErrors[groupIndex]) {
            newErrors[groupIndex] = {};
          }
          newErrors[groupIndex][filterIndex] = "A value is required.";
        }
      });
    });

    setErrors(newErrors);
  };

  const handleDeleteFilter = (groupIndex, filterIndex) => {
    setSelectedProperty(null);
    const updatedGroups = groups.map((group, gIdx) =>
      gIdx === groupIndex
        ? {
            ...group,
            filters: group.filters.filter((_, fIdx) => fIdx !== filterIndex),
          }
        : group
    );
    setGroups(updatedGroups);
  };

  const handleDeleteGroup = (groupIndex) => {
    setSelectedProperty(null);
    const updatedGroups = groups.filter((_, idx) => idx !== groupIndex);
    setGroups(updatedGroups);
  };

  return (
    <Container>
      <span className="heading">Advanced Filters</span>

      {getErrorCount() > 0 && (
        <div className="error-total">
          <BiSolidError />
          <span>{getErrorCount()} fields with errors</span>
        </div>
      )}
      {groups &&
      groups.length > 0 &&
      groups.every((group) => !group?.filters?.length) ? (
        <div className="not-filter-text-wrapper">
          <span className="not-filter-text">
            This view doesn't have any advanced filters.
          </span>
          <span className="not-filter-text">Select a filter to begin.</span>
        </div>
      ) : (
        <>
          {groups.map((group, groupIndex) => (
            <>
              <div
                // onClick={() => handleGroupSelection(groupIndex)}
                key={groupIndex}
                className="group-wrapper"
              >
                <div className="header">
                  <span className="name">
                    {groupIndex === 0 ? "Group 1" : `Group ${groupIndex + 1}`}
                  </span>

                  <span
                    className="icon"
                    onClick={() => handleDeleteGroup(groupIndex)}
                  >
                    <RiDeleteBin6Fill color="rgb(76, 187, 203)" />
                  </span>
                </div>
                {group.filters.map((filter, filterIndex) => (
                  <div
                    style={{
                      border:
                        errors[groupIndex]?.[filterIndex] &&
                        "1px solid #fe6a7c",
                    }}
                    className="outer-div"
                    key={filterIndex}
                  >
                    <div className="headerr">
                      {filterIndex > 0 ? (
                        <span className="name">AND</span>
                      ) : (
                        <span className="name"></span>
                      )}

                      <span
                        className="icon"
                        onClick={() =>
                          handleDeleteFilter(groupIndex, filterIndex)
                        }
                      >
                        <RiDeleteBin6Fill color="rgb(76, 187, 203)" />
                      </span>
                    </div>
                    <span className="text">
                      <span>{filter?.field?.name}</span>
                      <span>
                        {" " + filter?.operator?.replace(/_/g, " ") + " "}
                      </span>
                      {filter?.field?.name === "Associated Contact" &&
                      filter?.value?.first_name ? (
                        <span className="name">
                          {`${filter?.value?.first_name} ${filter?.value?.last_name}`}
                        </span>
                      ) : filter?.field?.name === "Company" &&
                        filter?.value?.name ? (
                        <span className="name">{`${filter?.value?.name}`}</span>
                      ) : filter?.field?.name === "Priority" &&
                        filter?.value ? (
                        <span className="name">{`${filter?.value}`}</span>
                      ) : filter?.field?.name === "Support Category" &&
                        filter?.value ? (
                        <span className="name">{`${filter?.value}`}</span>
                      ) : filter?.field?.name === "Channel" && filter?.value ? (
                        <span className="name">{`${filter?.value}`}</span>
                      ) : filter?.field?.name === "Status" && filter?.value ? (
                        <span className="name">{`${filter?.value}`}</span>
                      ) : filter?.field?.name === "Pipeline" &&
                        filter?.value[0]?.name ? (
                        <span className="name">
                          {filter?.value?.map((item, index) => (
                            <span key={index}>
                              {item.name}
                              {index < filter.value.length - 1 && ", "}{" "}
                            </span>
                          ))}
                        </span>
                      ) : filter?.field?.name === "Stage" &&
                        filter?.value[0]?.name ? (
                        <span className="name">
                          {filter?.value?.map((item, index) => (
                            <span key={index}>
                              {item.name}
                              {index < filter.value.length - 1 && ", "}{" "}
                            </span>
                          ))}
                        </span>
                      ) : filter?.field?.name === "Ticket Name" &&
                        filter?.value ? (
                        <span className="name">{filter?.value}</span>
                      ) : filter?.field?.name === "Ticket Description" &&
                        filter?.value ? (
                        <span className="name">{filter?.value}</span>
                      ) : filter?.field?.name === "Ticket Owner" &&
                        filter?.value?.first_name ? (
                        <span className="name">
                          {`${filter?.value?.first_name} ${filter?.value?.last_name}`}
                        </span>
                      ) : null}
                    </span>
                    {/* Show error if validation fails */}
                    {errors[groupIndex]?.[filterIndex] && (
                      <span className="error">
                        {errors[groupIndex][filterIndex]}
                      </span>
                    )}
                  </div>
                ))}

                <div className="outer-div-btn">
                  <div
                    onClick={() => {
                      setSelectedProperty(null);
                      setSelectedGroupIndex(groupIndex);
                    }}
                    className="btn add-filter-btn"
                  >
                    <FaPlus />
                    <span>Add Filter</span>
                  </div>
                </div>
              </div>
              <div className="sprtor">
                <span className="sprtor-line"></span>
                <span className="sprtor-text">OR</span>
                <span className="sprtor-line"></span>
              </div>
            </>
          ))}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <div className="outer-div-btn">
              <div onClick={addGroup} className="btn">
                <FaPlus />
                <span>Add Group</span>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TicketFilters;
