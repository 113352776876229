import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-self: stretch;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .heading {
    font-size: 16px;
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.49px;
  }

  .search-field {
    height: 52px;
    width: 100%;
    margin-top: 10px;

    .form-item {
      width: 100% !important;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.63px;

      .ant-form-item-control {
        width: 100%;
      }

      .ant-form-item {
        width: 100% !important; /* Ensure each form item takes full width */
        margin-bottom: 0;
      }

      .ant-form-item-row {
        width: 100%; /* Ensure the form item row takes full width */
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start;
        gap: 4px;
      }

      .location {
        align-items: center;
        width: 100% !important;
        height: 41px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        height: auto;
        min-height: 42px;
        padding-top: 4px;
        padding-right: 15px !important;

        .ant-select-selector {
          border: none;
          outline: none;
          box-shadow: none;
          .ant-select-selection.ant-select-selection--single {
            background: green;
          }
        }
      }
      .location::placeholder {
        color: var(--Gray-40, rgba(85, 99, 115, 0.4));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .location:focus::placeholder {
        color: transparent;
      }
      .ant-select-selection-item {
        ${
          "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
        }
      }
    }
  }

  .list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;

    .list-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      }

      .icon {
        margin-right: 10px;
        color: var(--Gray-100, #556373);
        font-size: 12px;
      }

      .text {
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--Gray-100, #556373);
      }
    }
  }

  .outer-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #d0dee1;
    border-radius: 5px;
    margin-top: 20px;
    padding: 7px;
    gap: 10px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .name {
        font-size: 14px;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.49px;
      }
      .icon {
        cursor: pointer;
      }
    }

    .form-main {
      display: flex;
      width: 100%;

      .form {
        display: flex;
        flex-direction: column;
        height: 50px;
        width: 100%;
        .form-items {
          margin-bottom: 0;
          height: 64px;
          width: 100%;
        }

        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }

        .ant-form-item-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          width: 100%;
          align-self: stretch;
        }
        .location {
          align-items: center;
          width: 100%;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
          }
          .location::placeholder {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .location:focus::placeholder {
            color: transparent;
          }
          .ant-select-selection-item {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }
  }
`;

export { Container };
