import React, { useEffect, useRef, useState } from "react";
import { Container, ProductContainer, MainConatiner } from "./styles";
import { Modal, Form, Input, Select } from "antd";
import useGetData from "../../../../hooks/useGetData";
import { InstanceWithAuth } from "../../../../App";
import { useSelector } from "react-redux";
import "./styles";
import Spinner from "../../../../common/Spinner";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactTostify from "../../../../common/ReactTostify";

function LiveAnswerSettings({ companyData }) {
  const [selcetedContary, setSelcetedContary] = useState();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showCursor, setShowCursor] = useState(false);
  const [dataSending, setDataSending] = useState(false);
  const { auth } = useSelector((state) => state);
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { data: productSettingsData, error } =
    useGetData(`services/3/settings`);
  const {
    data: stateData,
    loading: stateDataLoading,
    error: stateDataError,
  } = useGetData(`countries/${selcetedContary}`);
  const { data: CountryData, loading: countryDataLoading } =
    useGetData("countries");

  useEffect(() => {
    console.log("productSettingsData:", productSettingsData);

    if (productSettingsData?.data) {
      form.setFieldsValue({
        incoming_minutes_per_month:
          productSettingsData.data.incoming_minutes_per_month,
        outgoing_minutes_per_month:
          productSettingsData.data.outgoing_minutes_per_month,
        call_handling: productSettingsData.data.call_handling,
        prefered_area_codes: productSettingsData.data.prefered_area_codes,
        virtual_receptionist_greeting:
          productSettingsData.data.virtual_receptionist_greeting,
        voice_mail_options: productSettingsData.data.voice_mail_options,
        additional_incoming_minute_charges:
          productSettingsData.data.additional_incoming_minute_charges,
        additional_outgoing_minute_charges:
          productSettingsData.data.additional_outgoing_minute_charges,
      });
    }
  }, [productSettingsData, form]);

  const onFinish = async (values) => {
    const formData = new URLSearchParams();
    formData.append(
      "incoming_minutes_per_month",
      values?.incoming_minutes_per_month
    );
    formData.append(
      "outgoing_minutes_per_month",
      values?.outgoing_minutes_per_month
    );
    formData.append(
      "additional_incoming_minute_charges",
      values?.additional_incoming_minute_charges
    );
    formData.append(
      "additional_outgoing_minute_charges",
      values?.additional_outgoing_minute_charges
    );

    try {
      setDataSending(true);
      const response = await InstanceWithAuth.put(
        "services/3/settings",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setDataSending(false);
        ReactTostify("success", response?.data?.message);
      }
    } catch (error) {
      setDataSending(false);
      ReactTostify("error", error?.response?.data?.message);
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handlesSelcetedContary = (event, item) => {
    setSelcetedContary(item?.key);
  };
  const handleClick = (selectedValues) => {
    setSelectedCompanies(selectedValues);
  };

  return (
    <MainConatiner>
      <Container>
        <div className="title-main">
          <p className="title">Live Answer Settings</p>
        </div>
        <ProductContainer>
          <Form
            form={form}
            requiredMark={false}
            className="form"
            onFinish={onFinish}
            ref={formRef}
          >
            <Form.Item
              label="Default Incoming Minutes per Month"
              name="incoming_minutes_per_month"
              className="input-label"
            >
              <Input
                className="username-input"
                placeholder="Default Incoming Minutes per Month"
              />
            </Form.Item>
            <Form.Item
              label="Deafult Outgoing Minutes per Month"
              name="outgoing_minutes_per_month"
              className="input-label"
            >
              <Input
                className="username-input"
                placeholder="Deafult Outgoing Minutes per Month"
              />
            </Form.Item>
            <Form.Item
              label="Call Handling"
              name="call_handling"
              className="input-label"
            >
              <Select
                suffixIcon={
                  <MdOutlineKeyboardArrowDown
                    size={15}
                    width="1em"
                    height="1em"
                    fill="rgba(85, 99, 115, 0.6)"
                  ></MdOutlineKeyboardArrowDown>
                }
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Call Handling"
                onChange={handleClick}
                className={
                  showCursor
                    ? "form-select half-input"
                    : "form-select half-input hide-cursor"
                }
              >
                {CountryData?.countries?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Preferred Area Codes"
              name="prefered_area_codes"
              className="input-label"
            >
              <Select
                mode="multiple"
                suffixIcon={
                  <MdOutlineKeyboardArrowDown
                    size={15}
                    width="1em"
                    height="1em"
                    fill="rgba(85, 99, 115, 0.6)"
                  ></MdOutlineKeyboardArrowDown>
                }
                style={{ width: "100%" }}
                placeholder="Preferred Area Codes"
                onChange={handleClick}
                className={
                  showCursor
                    ? "form-select half-input"
                    : "form-select half-input hide-cursor"
                }
              >
                {CountryData?.countries?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Virtual Receptionist Greeting"
              name="virtual_receptionist_greeting"
              className="input-label"
            >
              <Select
                mode="multiple"
                suffixIcon={
                  <MdOutlineKeyboardArrowDown
                    size={15}
                    width="1em"
                    height="1em"
                    fill="rgba(85, 99, 115, 0.6)"
                  ></MdOutlineKeyboardArrowDown>
                }
                style={{ width: "100%" }}
                placeholder="Virtual Receptionist Greeting"
                onChange={handleClick}
                className={
                  showCursor
                    ? "form-select half-input"
                    : "form-select half-input hide-cursor"
                }
              >
                {CountryData?.countries?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Voice Mail Options"
              name="voice_mail_options"
              className="input-label"
            >
              <Select
                mode="multiple"
                suffixIcon={
                  <MdOutlineKeyboardArrowDown
                    size={15}
                    width="1em"
                    height="1em"
                    fill="rgba(85, 99, 115, 0.6)"
                  ></MdOutlineKeyboardArrowDown>
                }
                style={{ width: "100%" }}
                placeholder="Voice Mail Options"
                onChange={handleClick}
                className={
                  showCursor
                    ? "form-select half-input"
                    : "form-select half-input hide-cursor"
                }
              >
                {CountryData?.countries?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Additional Incoming Minute Charges"
              name="additional_incoming_minute_charges"
              className="input-label"
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>
            <Form.Item
              label="Additional Outgoing Minute Charges"
              name="additional_outgoing_minute_charges"
              className="input-label"
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>
          </Form>
        </ProductContainer>
        <div className="button-div">
          <button type="" onClick={handleSubmit}>
            {dataSending ? <Spinner></Spinner> : "Save"}
          </button>
        </div>
      </Container>
      {/* <div className="button-div">
        <button type="" onClick={handleSubmit}>
          {dataSending ? <Spinner></Spinner> : 'Save'}
        </button>
      </div> */}
    </MainConatiner>
  );
}

export default LiveAnswerSettings;
