import styled from 'styled-components'

const BannerContainer = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 50px;
  justify-content: center;
  background-color: #fafbfc;
  position: fixed;
  z-index: 3;
  width: -webkit-fill-available;
  top: 42px;

  .main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .save-btn {
      display: flex;
      height: 26px;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #4cbaca;
      border: none;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    .user-detail {
      display: flex;
      min-width: 448px;
      align-items: center;
      gap: 24px;
      height: 36px;
      flex-shrink: 0;
      width: fit-content;

      .user {
        width: 130px;
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        gap: 12px;
        width: fit-content;

        .user-img {
          width: 36px;
          height: 36px;
          border-radius: 36px;
          /* background-color: black; */
          border: 1px solid #e3eced;
          background: white;
        }

        .user-name {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.49px;
          /* height: 25px; */
        }
      }

      .btns {
        width: 140px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;

        .impression-btn {
          display: flex;
          width: 50px;
          height: 26px;
          padding: 9px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-left: 1px solid rgba(208, 222, 225, 0.4);
          /* border: none; */
          border-right: none;
          border-top: none;
          border-bottom: none;
          background: transparent;
          cursor: pointer;

          .btn-icon {
            width: 12px;
            height: 12px;
          }

          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }
        }
      }
    }

    .search {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 4px 7px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
    }
  }
`

export { BannerContainer }
