import styled from 'styled-components'

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? 'var(--Blue-100, #4cbbcb)'
            : 'var(--Gray-5, rgba(85, 99, 115, 0.05))'};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? 'var(--White-100, #fff)'
              : 'var(--Dark-Gray-100, #2B3746)'};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? 'var(--White-100, #fff)'
                  : 'var(--Dark-Gray-100, #2B3746)'};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 7px;
  }

  .form-collapased {
    border: none;
    .panel {
      border: none;
      .ant-collapse-header {
        width: 339px;
        display: flex;
        height: 48px;
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
        opacity: 0.8;
        background: var(--Gray-2, rgba(85, 99, 115, 0.02));
        .ant-collapse-header-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: #2b3746;
            }
          }
        }
      }
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const CompanyCards = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #fff;

  .company-labels {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;

    .company-name {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
      margin: 0px;
    }

    .office {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      /* text-decoration-line: underline; */
    }
  }
  .storage-FormatDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .date {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin: 0px;
    }

    .td-storage {
      max-width: 134px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .storage {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }

      .ant-progress {
        width: 100%;
        height: 12px;
        /* @media (max-width: 1450px) {
          width: 100px;
        } */
      }
      .ant-progress-bg {
        /* border: 1px solid #70c9d5;
        clip-path: inset(0px auto 0px 0px) !important; */
        /* border-radius: 4px !important; */
        background: linear-gradient(
            0deg,
            rgba(112, 201, 213, 0.52) 0%,
            rgba(112, 201, 213, 0.52) 100%
          ),
          #fff;
        /* width: 94px; */
        height: 12px !important;
      }
    }
    .full {
      max-width: 134px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .storage {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }

      .ant-progress {
        width: 100%;
        height: 12px;
        /* @media (max-width: 1450px) {
          width: 100px;
        } */
      }
      .ant-progress-bg {
        background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.52) 0%,
            rgba(255, 0, 0, 0.52) 100%
          ),
          #fff;
        /* width: 94px; */
        height: 12px !important;
      }
    }
  }
  .time-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    .btns {
      display: flex;
      align-items: center;
      gap: 8px;

      .view-btn {
        display: flex;
        cursor: pointer;
        padding: 6px 12px;
        align-items: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;

        .btn-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
    }
  }
`

export { Container, ContentContainer, CompanyCards }
