import React, { useState } from "react";
import { Container } from "./styles";
import "./style.css";
import { Form, Input, Switch, Checkbox, DatePicker, Select } from "antd";

const DefaultEndDate = ({ id }) => {
  const [useLiveAnsweringPlan, setUseLiveAnsweringPlan] = useState(false);
  const [value, setValue] = useState([]);

  const options = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];

  const handleOneTimeProductChange = () => {
    setUseLiveAnsweringPlan(!useLiveAnsweringPlan);
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="form-main">
        <div className="title">Live Answering Plan</div>

        <div className="radio-container">
          <span className="default-label">
            Do you want to user live answering plan
          </span>
          <Form.Item valuePropName="checked" name="is_one_time">
            <Switch onChange={handleOneTimeProductChange} />
          </Form.Item>
        </div>
      </div>

      <div className="form-main">
        <div className="form">
          <Form.Item
            label="Live Answering Plans"
            name="location_ids"
            className="form-items"
          >
            <Select
              showSearch
              placeholder="Live Answering Plans"
              className="location"
              value={value}
              style={{
                width: "100% !important",
              }}
              onChange={setValue}
              options={options}
            />
          </Form.Item>
        </div>
      </div>
    </Container>
  );
};

export default DefaultEndDate;
