import React, { useEffect, useRef, useState } from 'react'
import ChatContainer from './styles'
import ChatMessage from '../chatMessage/ChatMessage'
import moment from 'moment'
import { message, Skeleton } from 'antd'
import LiveChatMessage from '../chatMessage/LiveChatMessage'
function LiveChartCard({
  email,
  currentEmployeeImage,
  allAvailbleConversations,
}) {
  const chatContainerRef = useRef(null)
  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    const chatContainer = chatContainerRef.current

    const handleScroll = () => {
      setIsScrolling(true)
      clearTimeout(chatContainer.scrollTimeout)
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false)
      }, 1000)
    }

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [email])
  console.log(email, 'live chats thread contartec')
  if (!email) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: '10px', width: 'auto' }}
      ></Skeleton>
    )
  }

  // Combine activities and threads
  // const combineThreadsAndActivities = () => {
  //   const combined = []

  //   Object.keys(threads).forEach((date) => {
  //     threads[date].forEach((thread) => {
  //       combined.push({
  //         type: 'message',
  //         content: thread,
  //         timestamp: thread.created_at,
  //       })
  //     })
  //   })

  //   activities.forEach((activity) => {
  //     combined.push({
  //       type: 'activity',
  //       content: activity,
  //       timestamp: activity.created_at,
  //     })
  //   })

  //   return combined.sort(
  //     (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  //   )
  // }

  const formatTime = (time) => {
    return moment(time).format('ddd, MMM D, YYYY')
  }

  const formatTime1 = (time) => {
    return moment(time).format('h:mm A')
  }

  const formatActivityMessage = (activity) => {
    const formattedTime = moment(activity?.created_at).format(
      'MMM D, YYYY h:mm A'
    )
    return `${activity?.activity} on ${formattedTime}`
  }
  console.log(email, 'email sin smasjkln')

  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? 'scrolling' : ''}
    >
      {email &&
        email?.map((item, index) => (
          <div key={index}>
            {true ? (
              <LiveChatMessage
                key={item?.id}
                emailData={email?.data}
                message={item?.message}
                sender={item?.owner}
                timestamp={formatTime(item?.created_at)}
                time={formatTime1(item?.created_at)}
                imageName={'user'}
                currentEmployeeImage={currentEmployeeImage}
                attachments={item?.attachments}
                sender_avatar={item?.sender_avatar}
                allAvailbleConversations={allAvailbleConversations}
                currentThread={item}
              />
            ) : (
              <div className="activity">
                <span>{formatActivityMessage(item?.content)}</span>
              </div>
            )}
          </div>
        ))}
    </ChatContainer>
  )
}

export default LiveChartCard
