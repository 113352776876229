import styled from "styled-components";

const ChatInputComponentContainer = styled.div`
  background-color: white;
  width: 100%;
  border-top: 1px solid #e3eced;
  min-height: 100px;
  padding: 0;

  .tabs {
    display: flex;
    ${"" /* padding-left: 30px; */}
    ${'' /* background-color: #fafbfc; */}
    ${'' /* border-bottom: 1px solid #e3eced; */}
    position: relative;
    ${'' /* background: red; */}
    padding: 0;
    height: 10px;

    .drag-button {
      position: absolute;
      left: 50%;
      right: 50%;
      top: 0;
      cursor: row-resize;
      ${"" /* padding: 0px 30px 10px 10px; */}
    }
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }

  .tab.active {
    color: #4cbaca;
    border-bottom: 3px solid #4cbaca;
  }
`;

export default ChatInputComponentContainer;
