import React, { useState, useEffect, useRef } from "react";
import {
  ChatMessagesContainer,
  MessageContainer,
  Avatar,
  MessageContent,
  SenderName,
  Timestamp,
  MessageText,
  ReactionContainer,
  SelectedEmojis,
  AttachmentList,
} from "./styles";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { LuReply, LuSmilePlus } from "react-icons/lu";
import EmojiPicker from "emoji-picker-react";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../App";

const ChatMessages = ({
  setQuotedMessage,
  directMessage,
  refetchDirectMessage,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [openEmojiId, setOpenEmojiId] = useState(null);
  const { auth } = useSelector((state) => state);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);

  // Store the local reactions in a state object
  const [localReactions, setLocalReactions] = useState({});

  const emojiPickerRef = useRef(null);
  const messageRefs = useRef({});

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setOpenEmojiId(null);
    }
  };

  useEffect(() => {
    if (openEmojiId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEmojiId]);

  const handleEmojiClick = async (messageId, emojiObject) => {
    setOpenEmojiId(null);

    const formData = new FormData();
    formData.append("reactions", emojiObject.emoji);
    formData.append("unified", emojiObject.unified);

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/sessions/${directMessage?.data?.id}/message/${messageId}/add-reaction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setLocalReactions((prevReactions) => ({
          ...prevReactions,
          [messageId]: [...(prevReactions[messageId] || []), emojiObject.emoji],
        }));
      }
    } catch (error) {
      console.error("Failed to add reaction:", error);
    }
  };

  const handleEmojiRemoveClick = async (messageId, emoji) => {
    const formData = new FormData();
    formData.append("reactions", emoji);

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/sessions/${directMessage?.data?.id}/message/${messageId}/remove-reaction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setLocalReactions((prevReactions) => {
          // Set all emojis of the message in localReactions before removing the specific emoji
          const updatedEmojis =
            prevReactions[messageId] ||
            directMessage?.data?.messages[messageId]?.reactions ||
            [];
          const filteredEmojis = updatedEmojis.filter(
            (existingEmoji) => existingEmoji !== emoji
          );

          return {
            ...prevReactions,
            [messageId]: filteredEmojis,
          };
        });
      }
    } catch (error) {
      console.error("Failed to remove reaction:", error);
    }
  };

  const scrollToMessage = (messageId) => {
    const messageElement = messageRefs.current[messageId];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });

      setHighlightedMessageId(messageId);

      setTimeout(() => {
        setHighlightedMessageId(null);
      }, 1500);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const options = { weekday: "long", day: "numeric", month: "long" };
    const yearOptions = { year: "numeric", ...options };

    const dateFormatted =
      date.getFullYear() === today.getFullYear()
        ? date.toLocaleDateString(undefined, options)
        : date.toLocaleDateString(undefined, yearOptions);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return dateFormatted;
    }
  }

  return (
    <ChatMessagesContainer>
      {directMessage?.data?.messages &&
        Object.keys(directMessage?.data?.messages)?.map((date) => (
          <React.Fragment key={date}>
            <div className="separator-div">
              <span className="line"></span>
              <span className="date">{formatDate(date)}</span>
              <span className="line"></span>
            </div>
            {directMessage?.data?.messages[date]?.map((message) => {
              const emojisToShow =
                localReactions[message.id] || message.reactions || [];

              return (
                <MessageContainer
                  key={message?.id}
                  ref={(el) => (messageRefs.current[message.id] = el)}
                  currentUserId={auth?.userObj?.id}
                  userId={message?.user_id}
                >
                  <Avatar src={message?.user_avatar} alt={message?.user_name} />
                  <MessageContent
                    currentUserId={auth?.userObj?.id}
                    userId={message?.user_id}
                    onMouseEnter={() => setHoveredMessageId(message?.id)}
                    onMouseLeave={() => setHoveredMessageId(null)}
                  >
                    <div className="sender-div">
                      <div className="make-row">
                        <SenderName
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          {message?.user_name}
                        </SenderName>
                        <Timestamp
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          {new Date(message?.created_at).toLocaleString()}
                        </Timestamp>
                      </div>
                      {hoveredMessageId === message?.id && (
                        <ReactionContainer
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          <span onClick={() => setOpenEmojiId(message?.id)}>
                            <LuSmilePlus />
                          </span>

                          <span>
                            <LuReply
                              onClick={() => setQuotedMessage(message)}
                            />
                          </span>
                          {/* <span>
                            <HiOutlineDotsVertical />
                          </span> */}
                        </ReactionContainer>
                      )}
                      {openEmojiId === message?.id && (
                        <div className="emoji-div" ref={emojiPickerRef}>
                          <EmojiPicker
                            height={400}
                            onEmojiClick={(emojiObject) =>
                              handleEmojiClick(message?.id, emojiObject)
                            }
                          />
                        </div>
                      )}
                    </div>
                    {message?.replied_to_message !== null && (
                      <div
                        className="reply-message-div"
                        onClick={() =>
                          scrollToMessage(message?.replied_to_message?.id)
                        }
                      >
                        <div className="reply-header">
                          <div className="left">
                            <span className="image-container">
                              <img
                                src={message?.replied_to_message?.user_avatar}
                                alt={message?.replied_to_message?.user_nam}
                              />
                            </span>
                            <span className="name">
                              {message?.replied_to_message?.user_name}
                            </span>
                          </div>
                        </div>
                        <span className="quoted-message">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: message?.replied_to_message?.message,
                            }}
                          />
                        </span>
                      </div>
                    )}

                    <MessageText
                      currentUserId={auth?.userObj?.id}
                      userId={message?.user_id}
                      replied_to_message={message?.replied_to_message}
                      style={{
                        border:
                          highlightedMessageId === message.id
                            ? "1px solid var(--Blue-100, #4cbbcb)"
                            : "none",
                        boxShadow:
                          highlightedMessageId === message.id
                            ? "0px 0px 10px 0px rgba(86, 191, 207, 0.24)"
                            : "none",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: message?.message }}
                      />
                    </MessageText>

                    <AttachmentList>
                      {message?.attachments?.map((file, index) => (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          className="AttachmentItem"
                        >
                          <img className="file-image" src={file?.url} alt=" " />
                        </a>
                      ))}
                    </AttachmentList>

                    {emojisToShow.length > 0 && (
                      <SelectedEmojis
                        currentUserId={auth?.userObj?.id}
                        userId={message?.user_id}
                      >
                        {emojisToShow.map((emoji, index) => (
                          <span
                            key={index}
                            style={{
                              fontSize: "20px",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleEmojiRemoveClick(message.id, emoji)
                            }
                          >
                            {emoji}
                          </span>
                        ))}
                      </SelectedEmojis>
                    )}
                  </MessageContent>
                </MessageContainer>
              );
            })}
          </React.Fragment>
        ))}
    </ChatMessagesContainer>
  );
};

export default ChatMessages;
