import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import chatVector from "./chating-vector.svg";
import { FaPlus } from "react-icons/fa";
import { IoPeopleOutline } from "react-icons/io5";

const EmptyChat = ({ setManageMembersClick, selectedSpace }) => {
  return (
    <MainContainer>
      <div className="main-div">
        <img
          src={chatVector}
          alt=""
          style={{ width: "350px", height: "350px", display: "block" }}
        />
        {selectedSpace?.logedin_user_is_admin === 1 && (
          <div onClick={() => setManageMembersClick(true)} className="add-btn">
            <IoPeopleOutline size={20} />
            <span>Add members</span>
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default EmptyChat;
