import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  .main-div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: ${(props) => props.mainDivWidth}%;

    .chat-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 0px;
      overflow-y: auto !important;

      .skelton-wrapper {
        width: 90%;
      }
    }

    .chat-input {
      width: 100%;
      margin-top: 10px;
      justify-content: center;
      display: flex;
      padding: 10px;
      margin-bottom: 40px !important;
    }
  }

  .resizer {
    width: 15px;
    background-color: #e3eced;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: ew-resize;
      background: #878787;
      height: 130px;
      width: 5px;
      cursor: ew-resize;
    }
  }

  .thread-div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: calc(100% - ${(props) => props.mainDivWidth}% - 5px);
    position: relative !important;

    .thread-header {
      position: absolute;
      top: 2px;
      width: 100%;
      background: white;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      z-index: 1000;

      span {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .close-btn {
        cursor: pointer;
      }
    }

    .thread-chat-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 0px;
      overflow-y: auto !important;

      .skelton-wrapper {
        width: 90%;
      }
    }

    .chat-input {
      width: 100%;
      ${"" /* min-height: 80px !important; */}
      margin-top: 10px;
      justify-content: center;
      display: flex;
      padding: 10px;
      padding-bottom: 40px;
    }
  }
`;

export { MainContainer };
