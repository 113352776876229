import React, { useEffect, useRef, useState } from "react";
import ChatContainer from "./styles";
import ChatMessage from "../chatMessage/ChatMessage";
import Comment from "../comment/Comment";
import moment from "moment";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";

function MailsAndComments({
  email,
  currentEmployeeImage,
  selectedEmailId,
  notes,
  refetchNotes,
  onEdit,
  handleForwardMail,
  handleReplyEmail,
  loadingEmailThread,
  setSelectedTab,
}) {
  const chatContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const { messageId } = useParams();
  const messageRefs = useRef({});

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(chatContainer.scrollTimeout);
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (messageId && messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [messageId, email]);

  useEffect(() => {
    if (chatContainerRef.current && !messageId) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [email]);

  if (!notes) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      ></Skeleton>
    );
  }

  if (!email || !email?.data || !email?.data?.threads) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      ></Skeleton>
    );
  }

  const { threads, activities } = email?.data;

  // Combine activities and threads
  const combineThreadsAndActivities = () => {
    const combined = [];

    Object.keys(threads).forEach((date) => {
      threads[date].forEach((thread) => {
        combined.push({
          type: "message",
          content: thread,
          timestamp: thread.created_at,
        });
      });
    });

    activities.forEach((activity) => {
      combined.push({
        type: "activity",
        content: activity,
        timestamp: activity.created_at,
      });
    });

    return combined.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  };

  const formatTime = (time) => {
    return moment(time).format("ddd, MMM D, YYYY");
  };

  const formatTime1 = (time) => {
    return moment(time).format("h:mm A");
  };

  const formatActivityMessage = (activity) => {
    const formattedTime = moment(activity.created_at).format(
      "MMM D, YYYY h:mm A"
    );
    return `${activity.activity} on ${formattedTime}`;
  };
  const combinedData = combineThreadsAndActivities();

  // Add notes to the combined data
  // const getAllDataSorted = () => {
  //   const notesData = notes.data.map((note) => ({
  //     type: 'note',
  //     content: note,
  //     timestamp: note.created_at,
  //   }))

  //   return [...combinedData, ...notesData].sort(
  //     (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  //   )
  // }

  const getAllDataSorted = () => {
    const notesData = notes?.data
      .filter((note) => note?.created_at)
      .map((note) => ({
        type: "note",
        content: note,
        timestamp: note.created_at,
      }));

    const mergedData = [...combinedData, ...(notesData ? notesData : [])];

    return mergedData.sort((a, b) => {
      const dateA = new Date(
        a.type === "note" ? a.content.created_at : a.created_at
      );
      const dateB = new Date(
        b.type === "note" ? b.content.created_at : b.created_at
      );
      return dateA - dateB;
    });
  };

  const allData = getAllDataSorted();
  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? "scrolling" : ""}
    >
      {loadingEmailThread ? (
        <Skeleton
          rows={10}
          paragraph={{ rows: 7 }}
          active
          style={{ margin: "10px", width: "100%" }}
        ></Skeleton>
      ) : (
        <>
          {allData.map((item, index) => (
            <div
              key={index}
              ref={(el) => {
                if (item.content.id) {
                  messageRefs.current[item.content.id] = el;
                }
              }}
            >
              {item.type === "message" ? (
                <ChatMessage
                  emailData={email?.data}
                  message={item.content.email_body}
                  sender={item.content.sender}
                  timestamp={formatTime(item.timestamp)}
                  time={formatTime1(item.timestamp)}
                  imageName={"user"}
                  currentEmployeeImage={currentEmployeeImage}
                  attachments={item.content.attachments}
                  sender_avatar={item.content.sender_avatar}
                  handleForwardMail={handleForwardMail}
                  handleReplyEmail={handleReplyEmail}
                  setSelectedTab={setSelectedTab}
                  mailDetails={item}
                />
              ) : item.type === "activity" ? (
                <div className="activity">
                  <span>{formatActivityMessage(item.content)}</span>
                </div>
              ) : (
                <Comment
                  key={item.content.id}
                  note={item.content}
                  selectedEmailId={selectedEmailId}
                  refetchNotes={refetchNotes}
                  onEdit={onEdit}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </div>
          ))}
        </>
      )}
    </ChatContainer>
  );
}

export default MailsAndComments;
