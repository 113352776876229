import styled from "styled-components";

const ChatInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto !important;
`;

const AttachmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0px 10px 14px 10px;
`;

const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f7f7f7;
  flex-basis: calc(50% - 10px);
  position: relative;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 140px;
`;

const RemoveIcon = styled.div`
  margin-left: 10px;
  ${"" /* color: red; */}
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 5;
  bottom: 5;
  right: 4px;
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4.3px 5.5px;
  background-color: white;
  border: 1px solid #e3eced;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1px;

  svg {
    font-size: 14px;
    color: #2b3746;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 0px;
  padding-bottom: 5px;
  min-height: 45px;
  background-color: #f7f9fa;
  border-top: 1px solid #e3eced;
  border-radius: 5px;
  ${"" /* height: auto; */}
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  flex-direction: column;

  .reply-message-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px; /* Thickness of the blue border */
    border-left: 3px solid #4cbbcb;
    border-top-left-radius: 10px; /* For the top-left curve */
    border-bottom-left-radius: 10px; /* For the bottom-left curve */
    border-top: 3px solid #4cbbcb;
  }

  .reply-message-div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 20px; /* Adjust height of the curve */
    width: 20px; /* Adjust width of the curve */
    border-top: 3px solid #4cbbcb;
    border-left: 3px solid #4cbbcb;
    border-top-left-radius: 10px; /* Round the corner for smooth effect */
  }

  .reply-message-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #f7f9fa;
    position: relative; /* Needed for the pseudo-element */
    margin: 6px 0;
    border-radius: 10px; /* Slight rounding for the box */

    .reply-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        .image-container {
          height: 30px;
          width: 30px;
          overflow: hidden;
          border-radius: 30px;
        }
        .name {
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: var(--Gray-100, #556373);
        }
      }

      .cross-icon {
        cursor: pointer;
      }
    }

    .quoted-message {
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
      margin-top: 5px;
    }
  }

  .main-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: auto;
    max-height: 200px;
    width: 100%;

    .ql-toolbar.ql-snow {
      border: none;
      font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      padding: 8px 0px;
      margin-top: -0px !important;
    }

    .ql-editor {
      border: none;
    }

    .ql-snow * {
      border: none;
    }

    .quill-editor {
      height: 100%;
    }
    .quill-editor .ql-container {
      border: none;
    }

    .quill-editor .ql-toolbar {
      margin: 0;
    }

    .text-area-wrapper {
      height: auto;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      margin-right: 20px;
    }
    .custom-toolbar {
      padding: 0;
      display: flex;
      gap: 10px;
      button {
        background-color: white;
        border: 1px solid #e3eced;
        border-radius: 4px;
        cursor: pointer;
        ${
          "" /* width: 40px;
      height: 30px; */
        }
      }
    }

    .send-button {
      margin-left: auto;
      border-radius: 4px;
      background: var(--Blue-100, #4cbbcb);
      color: var(--White-100, #fff);
      text-align: center;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border: none;
      cursor: pointer;
      padding: 4px 15px;
      margin-left: 20px;

      .spinner-icon {
        animation: spin 1s linear infinite;
        color: white;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export {
  ChatInputContainer,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
  Button,
  ButtonsWrapper,
};
