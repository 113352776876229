import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -100px;

  .main-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .add-btn {
      cursor: pointer;
      display: flex;
      padding: 7px 20px;
      align-items: center;
      border-radius: 20px;
      border: 1px solid var(--Blue-100, #4cbbcb);
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      gap: 15px;
    }
  }
`;

export { MainContainer };
