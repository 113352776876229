import React, { useState, useEffect } from "react";
import {
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  MainConatiner,
} from "./styles";
import { FaExpandAlt, FaMinus, FaSquare } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaRegSquareFull } from "react-icons/fa6";
import useGetData from "../../../hooks/useGetData";
import { CrossIcon } from "../../../common/customPill/styles";
import { handleRemoveInternalChatSpace } from "../../../redux/app/appSlice";
import SpaceSmallerView from "../spaceSmallerView/SpaceSmallerView";

const SpaceCard = ({ space }) => {
  const { auth, app } = useSelector((state) => state);
  const [savingComment, setSavingComment] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [sendingMail, setSendingMail] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TruncateText = ({ text }) => {
    const maxLength = 25;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const removeSpaceFromList = () => {
    dispatch(handleRemoveInternalChatSpace(space));
  };

  return (
    <MainConatiner style={{ height: isVisible ? "500px" : "50px" }}>
      <Header>
        <div className="make-row">
          <div className="icon">{space?.icon}</div>
          <HeaderTitle>
            <TruncateText text={space?.name || ""} />
          </HeaderTitle>
        </div>
        <div className="make-row">
          {isVisible ? (
            <FaMinus
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(false)}
            />
          ) : (
            <FaRegSquareFull
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(true)}
            />
          )}
          <FaExpandAlt
            style={{ cursor: "pointer" }}
            size={16}
            onClick={() => {
              removeSpaceFromList();
            }}
          />
          <CrossIcon
            size={17}
            style={{ cursor: "pointer", marginLeft: "1px" }}
            onClick={() => removeSpaceFromList()}
          />
        </div>
      </Header>
      {isVisible && (
        <>
          <SpaceSmallerView space={space} />
        </>
      )}
    </MainConatiner>
  );
};

export default SpaceCard;
