import React, { useState } from "react";
import ThirdColumnContainer from "./styles";
import UserDetailSidebar from "../../userList/userDetailSidebar/UserDetailSidebar";
import CompanyDetailSidebar from "../../componyFlowComponents/companyDetailSidebar/CompanyDetailSidebar";
import SupportHeader from "./supportHeader/SupportHeaderr";

const ThirdColumn = ({ ticket }) => {
  const [clickedCompany, setClickedCompany] = useState(null);
  

  return (
    <ThirdColumnContainer>
      <SupportHeader
        clickedCompany={clickedCompany}
        setClickedCompany={setClickedCompany}
        userID={ticket?.contact_id}
        ticket={ticket}
      />
      {clickedCompany ? (
        <CompanyDetailSidebar
          open={true}
          userID={clickedCompany?.id}
          // fetchUserDetails={fetchUserDetails}
          // fetchAllContacts={hanleSearchContact}
          // hanleSearchContact={hanleSearchContact}
          canHide={true}
        />
      ) : (
        <UserDetailSidebar
          open={true}
          canHide={false}
          supportHeader={true}
          userID={ticket?.contact_id}
          calllingFromTicketPage={true}
          // additionalTabs={true}
          // selectedEmailId={4}
          setClickedCompany={setClickedCompany}
        />
      )}
    </ThirdColumnContainer>
  );
};

export default ThirdColumn;
