import React from "react";
import { Layout, Menu, Badge, Button } from "antd";
import {
  SideBarContainer,
  SidebarItemsContainer,
  SidebarLogo,
  SidebarItem,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { handleCurrentPage } from "../../../redux/app/appSlice";
import { useSelector, useDispatch } from "react-redux";
import starthubsmallLogo from "../../../assets/starthubsmallLogo.png";
import GetLogo from "../../getlogo/getlogo";
import { ReactComponent as LiveAnsweringIcon } from "./user_headset.svg";
import { FaRegCommentDots } from "react-icons/fa";

const { Sider } = Layout;
const SmallSidebar = ({
  secondMenuitems,
  menuItems,
  handleCollapsed,
  collapsed,
  logOutuser,
}) => {
  const dispatch = useDispatch();
  const { app, auth } = useSelector((state) => state);
  const navigate = useNavigate();

  const handleCLikItem = (item) => {
    if (item.path !== "") {
      item?.label !== "Menu" && dispatch(handleCurrentPage(item?.label));
      item?.path && navigate(item?.path);
    }
  };

  return (
    <SideBarContainer>
      <Sider
        style={{
          position: "fixed",
          left: 0,
          // overflowX: 'hidden',
          height: "100%",
        }}
        trigger={null}
        collapsible
        className="side-bar"
      >
        <SidebarItemsContainer>
          <SidebarLogo>
            <div className="startHub-log" onClick={handleCollapsed}>
              <img
                src={starthubsmallLogo}
                className="logo"
                width="100%"
                height="100%"
              />
            </div>
          </SidebarLogo>
          <SidebarItem>
            <div className="sidebar-item">
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                className="sidebar-menu"
              >
                {menuItems?.map((item) => (
                  <Menu.Item
                    icon={item?.icon}
                    label={item?.label}
                    key={item?.key}
                    title={item?.label}
                    onClick={() => handleCLikItem(item)}
                    className={
                      app?.currentPage == item?.label
                        ? `custom-active-item ${item?.className}`
                        : item?.className
                    }
                  >
                    {item?.isBadge && (
                      <Badge count={item?.count} className="badges"></Badge>
                    )}
                  </Menu.Item>
                ))}
              </Menu>
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                className="sidebar-menu-two"
              >
                {secondMenuitems?.map((item) => (
                  <Menu.Item
                    icon={
                      item?.key === "4" ? (
                        <LiveAnsweringIcon
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#86898a",
                          }}
                        />
                      ) : item?.key === "5" ? (
                        <FaRegCommentDots color="#86898a" size={14} />
                      ) : (
                        item?.icon
                      )
                    }
                    label={item?.label}
                    key={item?.key}
                    title={item?.label}
                    onClick={() => handleCLikItem(item)}
                    className={
                      app?.currentPage == item?.label
                        ? `custom-active-item ${item?.className}`
                        : item?.className
                    }
                  >
                    {item?.isBadge && (
                      <Badge
                        count={item?.count > 99 ? item.count : item?.count}
                        className="badges"
                        overflowCount={999}
                      ></Badge>
                    )}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
            <div className="logout-btn">
              <Menu className="logout-sidebar" mode="inline">
                <Menu.Item
                  className="menu-item"
                  icon={GetLogo("logout")}
                  onClick={logOutuser}
                ></Menu.Item>
              </Menu>
            </div>
          </SidebarItem>
        </SidebarItemsContainer>
      </Sider>
    </SideBarContainer>
  );
};

export default SmallSidebar;
