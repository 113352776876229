// hooks/useGlobalMessage.js
import React, { createContext, useContext } from "react";
import { message } from "antd";

// Create a context for the global alert message
const GlobalAlertMessageContext = createContext(null);

export const GlobalAlertMessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  // Provide a function to show messages
  const showMessage = ({ type, content }) => {
    messageApi.open({ type, content });
  };

  return (
    <GlobalAlertMessageContext.Provider value={{ showMessage }}>
      {contextHolder}
      {children}
    </GlobalAlertMessageContext.Provider>
  );
};

// Custom hook to use the alert message
export const useGlobalAlertMessage = () => {
  const context = useContext(GlobalAlertMessageContext);
  if (!context) {
    throw new Error(
      "useGlobalAlertMessage must be used within a GlobalAlertMessageProvider"
    );
  }
  return context;
};
