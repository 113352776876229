import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { PiFileAudioThin } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import { Container, ProductContainer, MainConatiner } from "./styles";
import { FaMusic, FaPlay } from "react-icons/fa";
import { TbPlayerStopFilled } from "react-icons/tb";

const VoiceMailSettingsModel = ({
  isModalOpen,
  handleCancel,
  handleOk,
  selectedFile,
  setSelectedFile,
  voiceMailTitle,
  setVoiceMailTitle,
  audioSrc,
  setAudioSrc,
  isPlaying,
  setIsPlaying,
  audioRef, // Remove this if you pass a ref from a parent, otherwise leave it.
}) => {
  const [currentTime, setCurrentTime] = useState(0); // New state for current time
  const [duration, setDuration] = useState(0); // New state for total duration

  // Create a reference for the audio element if it's not coming from parent props
  const internalAudioRef = useRef(null);
  const audioElementRef = audioRef || internalAudioRef;

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (audioElement) {
      const handleLoadedMetadata = () => {
        console.log("Audio duration:", audioElement.duration);
        setDuration(audioElement.duration);
      };

      const handleTimeUpdate = () => {
        console.log("Current time:", audioElement.currentTime);
        setCurrentTime(audioElement.currentTime);
      };

      audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        audioElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [audioSrc, audioElementRef]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setAudioSrc(fileURL);
      setIsPlaying(false);
      setCurrentTime(0); // Reset current time when a new file is loaded
    } else {
      alert("Please select a valid audio file.");
    }
  };

  const handleDivClick = () => {
    document.getElementById("file-input").click();
  };

  const toggleAudioPlayback = () => {
    const audioElement = audioElementRef.current;

    if (!audioElement) return;

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleDeleteAudio = () => {
    setSelectedFile(null);
    setAudioSrc(null);
    setIsPlaying(false);
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.currentTime = 0; // Reset the audio
    }
    setCurrentTime(0); // Reset the progress bar
  };

  const handleTitleChange = (event) => {
    setVoiceMailTitle(event.target.value);
  };

  const handleProgressBarClick = (event) => {
    const audioElement = audioElementRef.current;
    if (!audioElement) return;

    // Get the width of the progress bar
    const progressBar = event.currentTarget;
    const totalWidth = progressBar.clientWidth;

    // Get the click position
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;

    // Calculate the new current time
    const newTime = (clickX / totalWidth) * duration;

    // Set the audio current time
    audioElement.currentTime = newTime;

    // Update currentTime state
    setCurrentTime(newTime);
  };

  const progressPercentage = duration ? (currentTime / duration) * 100 : 0;
  console.log("Progress percentage:", progressPercentage);

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Add Voicemail</p>
          </div>

          <ProductContainer>
            <div className="product-items">
              <span className="label">Title</span>
              <input
                className="input"
                placeholder="Enter title here..."
                value={voiceMailTitle}
                onChange={handleTitleChange}
              />
            </div>
            {!audioSrc && (
              <div className="file-selector">
                <div onClick={handleDivClick}>
                  <PiFileAudioThin color="#4cbbcb" size={50} />
                  <span className="label">Upload Audio file</span>
                </div>
              </div>
            )}

            <input
              id="file-input"
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />

            {audioSrc && (
              <div className="audio-file-display">
                <div className="audio-icon">
                  <FaMusic color="#4cbbcb" size={15} />
                </div>
                <div className="audio-details">
                  <span className="audio-filename">{selectedFile?.name}</span>
                </div>
                <div className="audio-controls">
                  {isPlaying ? (
                    <TbPlayerStopFilled
                      color="#55637366"
                      size={20}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FaPlay
                      color="#55637366"
                      size={16}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <AiOutlineDelete
                    color="#55637366"
                    size={20}
                    onClick={handleDeleteAudio}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                {/* Progress Bar */}
                <div
                  className="progress-bar"
                  onClick={handleProgressBarClick}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="progress"
                    style={{
                      width:
                        progressPercentage > 0
                          ? `${progressPercentage}%`
                          : "1px",
                      backgroundColor: "#4cbbcb",
                      height: "5px",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </ProductContainer>
        </Container>

        <div className="button-div">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              handleDeleteAudio();
              handleCancel();
            }}
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => {
              handleDeleteAudio();
              handleOk();
            }}
          >
            Save
          </button>
        </div>
      </MainConatiner>

      {/* The audio element to control playback */}
      {audioSrc && <audio ref={audioElementRef} src={audioSrc} />}
    </Modal>
  );
};

export default VoiceMailSettingsModel;
