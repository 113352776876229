import React, { useState, useEffect } from "react";
import {
  AssociateContactContainer,
  Footer,
  PaginationContainer,
} from "./styles";
import {
  Divider,
  Form,
  Input,
  Pagination,
  Select,
  Skeleton,
  Space,
} from "antd";
import GetLogo from "../../getlogo/getlogo";
import { InstanceWithAuth } from "../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { DownOutlined } from "@ant-design/icons";
import useGetData from "../../../hooks/useGetData";
import ReactTostify from "../../../common/ReactTostify";

const AssociateContact = ({
  type,
  onCloseContactDrawer,
  selectedEmailOrChat,
  refetchEmails,
  refetchEmail,
  refetchData,
}) => {
  const { auth, app } = useSelector((state) => state);
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  const [totalContacts, setTotalContacts] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  // ///////////////////////////////////
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("1");
  const [locationId, setLocationId] = useState("");
  const [form] = Form.useForm();
  ////////////////////////////////////////
  const { data: locationsData, loading: loadingLocations } =
    useGetData("locations");

  const options = locationsData?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (app?.allOpenConversationDetails?.sender_name) {
      const [firstName, ...lastNameArray] =
        app?.allOpenConversationDetails?.sender_name?.split(" ");
      const lastName = lastNameArray?.join(" ");

      form.setFieldsValue({
        first_name: firstName || "",
        last_name: lastName || "",
        email: app?.allOpenConversationDetails?.sender_email || "",
      });
    }
  }, [app?.allOpenConversationDetails]);

  useEffect(() => {
    fetchContacts();
  }, [currentPage, searchTerm]);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await InstanceWithAuth.get(
        `contacts?query=${searchTerm}&per_page=${perPage}&page=${currentPage}&order=DESC`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const { data, total } = response.data;
        setContacts(data);
        setTotalContacts(total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      ReactTostify("error", error?.response?.data?.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleAssociateExisting = async () => {
    setIsSaving(true);

    if (!selectedContactId) {
      Swal.fire({
        icon: "warning",
        title: "No Contact Selected",
        text: "Please select a contact before saving.",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setIsSaving(false);
      return;
    }

    let formData = new FormData();
    formData.append("contact_id", selectedContactId);
    formData.append("create_contact", false);
    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }
    formData.append("type", type);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/${selectedEmailOrChat}/associate_to_contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsSaving(false);
        refetchEmail();
        refetchData();
        ReactTostify("success", response?.data?.message);
        onCloseContactDrawer();
      }
    } catch (error) {
      setIsSaving(false);
      ReactTostify("error", error?.response?.data?.message);
    }
  };

  const handleAssociateNew = async () => {
    setIsSaving(true);
    const [firstNameFromEmail, ...lastNameArray] =
      app?.allOpenConversationDetails?.sender_name?.split(" ");
    const lastNameFromEmail = lastNameArray?.join(" ");
    const zipcodeInt = parseInt(zipcode);
    let formData = new FormData();
    formData.append("create_contact", true);
    formData.append("first_name", firstName || firstNameFromEmail);
    formData.append("last_name", lastName || lastNameFromEmail);
    formData.append(
      "email",
      email || app?.allOpenConversationDetails?.sender_email
    );
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipcode", zipcodeInt);
    formData.append("country", country);
    formData.append("status", status);
    formData.append("location_id", locationId);
    let type;
    if (app?.allOpenConversationDetails?.Model === "SupportEmails") {
      type = "email";
    } else if (app?.allOpenConversationDetails?.Model === "LiveChat") {
      type = "chat";
    }
    formData.append("type", type);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/${selectedEmailOrChat}/associate_to_contact`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsSaving(false);
        refetchEmail();
        refetchData();
        ReactTostify("success", response?.data?.message);
        onCloseContactDrawer();
      }
    } catch (error) {
      setIsSaving(false);
      ReactTostify("error", error?.response?.data?.message);
    }
  };

  return (
    <AssociateContactContainer>
      {type === "addExisting" && (
        <>
          <div>
            <Input
              placeholder="Search contacts"
              suffix={<span className="icon">{GetLogo("searchIcon")}</span>}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="contact-list">
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                <div className="filters">
                  <span className="total">{totalContacts} contacts</span>
                  <span></span>
                </div>
                <div className="list">
                  {contacts.length > 0 ? (
                    contacts.map((contact) => (
                      <div className="list-item" key={contact.id}>
                        <span className="radio-button">
                          <input
                            type="radio"
                            id={contact.id}
                            name="contact"
                            value={contact.id}
                            onChange={(e) =>
                              setSelectedContactId(e.target.value)
                            }
                          />
                        </span>
                        <label className="label" htmlFor={contact.id}>
                          {contact.first_name +
                            " " +
                            contact.last_name +
                            " (" +
                            contact.email +
                            ")"}
                        </label>
                      </div>
                    ))
                  ) : (
                    <span>No contacts found</span>
                  )}
                </div>
              </>
            )}
          </div>

          {!loading && (
            <PaginationContainer>
              <Pagination
                showSizeChanger={false} // Disable page size change for now
                onChange={handlePageChange}
                current={currentPage}
                total={totalContacts}
                pageSize={perPage} // Number of items per page
                nextIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M1.5 7L4.5 4L1.5 1"
                      stroke="#556373"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                prevIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="8"
                    viewBox="0 0 6 8"
                    fill="none"
                  >
                    <path
                      d="M4.5 7L1.5 4L4.5 1"
                      stroke="#556373"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                className="pagination-div"
              />
            </PaginationContainer>
          )}

          {!loading && (
            <Footer>
              <button onClick={onCloseContactDrawer} className="cancel">
                Cancel
              </button>
              <button
                onClick={() => handleAssociateExisting()}
                disabled={isSaving}
                className="save"
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </Footer>
          )}

          {/* <button className="add-btn">
          <CustomDropDownUpwards
            options={[
              { name: '25', id: 1 },
              { name: '50', id: 2 },
              { name: '100', id: 3 },
            ]}
            perPage={perPage}
            setperPage={setperPage}
            onSelect={setperPage}
            setCurentPage={setCurentPage}
          ></CustomDropDownUpwards>
        </button> */}
        </>
      )}
      {type === "createNew" && (
        <div className="form-container">
          <div className="form-main">
            <Form form={form} className="form">
              <Form.Item
                label="First Name"
                name="first_name"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="last_name"
                className="form-items"
              >
                <Input
                  className="input"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Email" name="email" className="form-items">
                <Input
                  className="input"
                  placeholder="john@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Phone" name="phone" className="form-items">
                <Input
                  className="input"
                  placeholder="+1234567890"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Address" name="address" className="form-items">
                <Input
                  className="input"
                  placeholder="123 Main St"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="City" name="city" className="form-items">
                <Input
                  className="input"
                  placeholder="New York"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="State" name="state" className="form-items">
                <Input
                  className="input"
                  placeholder="NY"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Zipcode" name="zipcode" className="form-items">
                <Input
                  className="input"
                  placeholder="10001"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Country" name="country" className="form-items">
                <Input
                  className="input"
                  placeholder="USA"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Status" name="status" className="form-items">
                <Select
                  className="location"
                  placeholder="Select status"
                  value={status}
                  onChange={(value) => setStatus(value)}
                >
                  <Select.Option value="1">Active</Select.Option>
                  <Select.Option value="0">Inactive</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Location"
                name="location_id"
                className="form-items"
              >
                <Select
                  disabled={loadingLocations}
                  showSearch
                  placeholder="Select Location"
                  className="location"
                  value={locationId}
                  style={{
                    width: "100% !important",
                  }}
                  onChange={(value) => setLocationId(value)}
                  options={options}
                />
              </Form.Item>

              <Footer>
                <button onClick={onCloseContactDrawer} className="cancel">
                  Cancel
                </button>
                <button
                  onClick={() => handleAssociateNew()}
                  disabled={isSaving}
                  className="save"
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </Footer>
            </Form>
          </div>
        </div>
      )}
    </AssociateContactContainer>
  );
};

export default AssociateContact;
