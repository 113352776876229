import React, { useState } from 'react'
import { ProductCardWrapper } from './styles'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Progress } from 'antd'
import DataNotFound from '../../../../../../common/DataNotFound'
import GetLogo from '../../../../../getlogo/getlogo'
import StatusLabelButton from '../../../../../statusLabelButton/StatusLabelButton'
function ProductCard({
  productsData,
  setHandleMailActionId,
  setMailBoxProfileNumber,
  companyData,
}) {
  const [isHovered, setIsHovered] = useState(false)
  const [panel1Collapsed, setPanel1Collapsed] = useState(
    productsData?.data.length === 0 ? true : false
  )

  const mailbox = ''
  const productData = ''

  const handlePanel1ButtonClick = () => {
    setPanel1Collapsed(!panel1Collapsed)
  }
  console.log(productsData, 'productsData dddd')

  const handleRedirect = (id, number) => {
    setHandleMailActionId(id)
    setMailBoxProfileNumber(number)
  }
  return (
    <ProductCardWrapper>
      <div className="container">
        <div className="detail-warpper">
          <div onClick={handlePanel1ButtonClick} className="title-div">
            <div className="title-progress-wrapper">
              <span className="forward">
                {panel1Collapsed ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </span>
              <div className="title-wrapper">
                <div className="title">
                  Mailbox{' '}
                  <p style={{ marginLeft: '5px' }}>#{productsData?.number}</p>
                </div>
                <StatusLabelButton Data={productsData}></StatusLabelButton>
              </div>
              {/* <div className="progress-wrapper">
                <span className="storage">
                  Storage {productsData?.storage_used}/
                  {productsData?.total_storage}
                </span>
                <Progress
                  className="storage-progress"
                  percent={
                    (productsData?.storage_used / productsData?.total_storage) *
                    100
                  }
                  size="small"
                  showInfo={false}
                  style={{ width: "183px", height: "12px" }}
                />
              </div> */}
            </div>
            {/* <button
              onClick={() =>
                handleRedirect(productsData?.id, productsData?.number)
              }
              className="view-btn"
            >
              View
            </button> */}
          </div>
        </div>
        {!panel1Collapsed &&
          (productsData?.data?.length === 0 ? (
            <DataNotFound label="No Subscribed Addons!" />
          ) : (
            <div className="item-div">
              {productsData.data.map((item) => (
                <div
                  key={item.id} // Ensure each item has a unique id or use another unique value
                  className="item-wrapper"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="item">
                    <div className="add-title">{item.addon_name}</div>
                    <div className="add-value">{item.quantity}</div>
                    <div className="add-amount">+ ${item.total}</div>
                    <div className="edit-view">
                      {/* Optional additional elements like edited date or view button */}
                      {/* <span className="edited-date">
                Last Edits {item.updated_at}
              </span> */}
                      {/* <button className="view-btn">View</button> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </ProductCardWrapper>
  )
}

export default ProductCard
