import styled from "styled-components";

const ChatInputComponentContainer = styled.div`
  background-color: white;
  width: 100%;
  border-top: 1px solid #e3eced;
  min-height: 300px;
  padding: 0;

  .tabs {
    display: flex;
    padding-left: 30px;
    background-color: #fafbfc;
    border-bottom: 1px solid #e3eced;
    position: relative;

    .drag-button {
      position: absolute;
      left: 50%;
      right: 50%;
      top: 0;
      cursor: row-resize;
      padding: 0px 30px 10px 10px;
    }

    .dropdownMenu-container {
      position: relative;

      .tab {
        padding: 10px 20px;
        cursor: pointer;
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.42px;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
      }

      .tab.active {
        color: #4cbaca;
        border-bottom: 3px solid #4cbaca;
      }

      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        border: 1px solid #e3eced;
        border-radius: 4px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
        min-width: 110px;
        padding: 5px 0;
      }

      .dropdown-item {
        padding: 10px 20px;
        cursor: pointer;
        font-family: Outfit;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.42px;
        color: var(--Gray-100, #556373);

        &:hover {
          background-color: rgba(76, 187, 203, 0.2);
        }
      }
    }
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .tab.active {
    color: #4cbaca;
    border-bottom: 3px solid #4cbaca;
  }
`;

export default ChatInputComponentContainer;
