import React from 'react'
import GetLogo from '../getlogo/getlogo'
import { MainConatiner } from './styles'
function StatusLabelButton({ Data, width = '' }) {
  console.log(Data, 'Data in staus button')
  return (
    <MainConatiner width={width}>
      <button className={Data?.user?.online ? 'manager-btn' : 'manager-btn'}>
        <div
          className={
            Data?.status_label == 'On Hold' && Data?.status == 0
              ? 'on-hold'
              : Data?.status_label == 'Pending'
              ? 'Pending'
              : Data?.status == 0
              ? 'in-active'
              : Data?.status == 2
              ? 'Pending'
              : 'active'
          }
        >
          <span className="btn-icon">{GetLogo('circle')}</span>
          <span className="text">
            {Data?.status_label
              ? Data?.status_label
              : Data?.status === 1
              ? 'Active'
              : Data?.status === 2
              ? 'Pending'
              : 'Inactive'}
          </span>
        </div>
      </button>
    </MainConatiner>
  )
}

export default StatusLabelButton
