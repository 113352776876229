import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles"; // Import CSS for styling
import useGetData from "../../../hooks/useGetData";
import {
  handleUserSelcetedPipeLine,
  handleUserSelcetedTicketType,
} from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
import { Button, message, Space } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const TicketType = ({ setType, app }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setIsOpen(!isOpen);
    setType(option);
    dispatch(handleUserSelcetedTicketType(option));
    app?.userSelcetedTicketType != option &&
      messageApi.open({
        type: "success",
        content: `Type has been set to ${option}`,
      });
  };

  return (
    <MainContainer>
      {contextHolder}
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {app?.userSelcetedTicketType
              ? app?.userSelcetedTicketType
              : "Select Type"}
          </div>
          {isOpen ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            <li onClick={() => handleOptionSelect("Task")}>Task</li>
            <li onClick={() => handleOptionSelect("Ticket")}>Ticket</li>
          </ul>
        )}
      </div>
    </MainContainer>
  );
};

export default TicketType;
