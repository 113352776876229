import styled from 'styled-components'

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .title-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    max-width: 528px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
`

const ProductContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #f3f6f7;
  max-width: 528px;
  width: 100%;
  height: auto;

  .add-products {
    max-width: 500px;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .product-title {
      display: flex;
      width: 242.5px;
      align-items: center;
      gap: 27px;
      width: 242.5px;
      max-width: 100%;

      .title {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        line-height: 120%; /* 16.8px */
      }
    }
    .add-prduct-btn {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 12px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .btn-icon {
        svg {
          width: 16px;
          height: 16px;
          fill: #4cbbcb;
          color: #4cbbcb;
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }

  .product-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-width: 528px;
    width: 100%;
    height: auto;

    .item-main {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      align-self: stretch;
      height: 48px;
      max-width: 488px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 100%;
        .labels {
          display: flex;
          width: 320px;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .item-title {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }

          .mailbox-title {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
        .item-title {
          margin: 0;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }

        .delete-icon {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;

          svg {
            width: 16px;
            height: 16px;
            fill: #4cbbcb;
            color: #4cbbcb;
          }
        }
      }
    }
  }
`

const MainConatiner = styled.div`
  .button-div {
    display: flex;
    justify-content: end;
    margin-right: 15px;
    margin-top: 9px;
    button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
`

const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const HiddenCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  border: ${(props) =>
    props.checked ? '2px solid #4CBBCB' : '2px solid #D8DBE3'};
  background-color: ${(props) =>
    props.checked ? 'rgba(76, 187, 203, 0.70)' : 'transparent'};
  position: relative;

  &:after {
    content: '\u2713';
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(props) => (props.checked ? 'block' : 'none')};
  }
`

const StyledCheckboxText = styled.span`
  color: var(--Dark-Gray-100, #2b3746);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export {
  Container,
  ProductContainer,
  MainConatiner,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledCheckboxText,
  HiddenCheckboxInput,
}
