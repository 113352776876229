import React, { useState } from 'react'
import { Collapse, Skeleton, Progress } from 'antd'
import { CompanyCards, Container, ContentContainer } from './styles'
import './styles'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import DataNotFound from '../../../../../common/DataNotFound'
import FormatDate from '../../../../../common/FormatDate'
import StatusLabelButton from '../../../../statusLabelButton/StatusLabelButton'
const { Panel } = Collapse

export default function PhoneNumberlist({ userID, userData }) {
  const {
    data: phoneNumberData,
    loading,
    error: phoneNumberErro,
  } = useGetData(`live-answering/phone-numbers?contact_id=${userID}`)
  const [collopasedKey, setCollopasedKey] = useState(false)
  const navigate = useNavigate()

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Phone Number" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active></Skeleton>
            ) : phoneNumberData?.data?.length == 0 ||
              phoneNumberData?.data?.length == undefined ? (
              <DataNotFound label={'No Phone Number Found'} />
            ) : (
              phoneNumberData?.data?.map((number) => (
                <>
                  <CompanyCards>
                    <div className="company-labels">
                      <span className="office">
                        {number?.virtual_phone_number}
                      </span>
                      <div className="time-details">
                        <div className="btns">
                          <StatusLabelButton Data={number}></StatusLabelButton>
                          <button
                            className="view-btn"
                            onClick={() =>
                              navigate(
                                `/${number.contact_id}/${number?.company_id}/${number?.id}/edit-number`
                              )
                            }
                          >
                            <span className="btn-text">View</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="storage-FormatDate">
                      <p className="date">
                        {FormatDate(number?.activation_date)}
                      </p>
                      {/* <div className="td-storage">
                        <span className="storage">
                          Minutes {number?.used_minuts}/{number?.used_minuts}
                        </span>
                        <Progress
                          className={`storage-progress ${
                            (number?.used_minuts / number?.used_minuts) * 100 >
                            90
                              ? 'full'
                              : ''
                          }`}
                          percent={
                            (number?.used_minuts / number?.total_minuts) * 100
                          }
                          size="small"
                          showInfo={false}
                        />
                      </div> */}
                    </div>
                  </CompanyCards>
                </>
              ))
            )}
            {/* <Collapse className="form-collapased">
              <Panel header="View All" key="1-1" className="panel">
                {loading ? (
                  <Skeleton active></Skeleton>
                ) : data?.data?.length == 0 ? (
                  <DataNotFound label={'No Company Found'} />
                ) : (
                  <CompanyCards>
                    <div className="company-labels">
                      <p className="company-name">Gonzalez LLC</p>
                      <span className="office">Premium Business Mailbox</span>
                    </div>
                    <div className="time-details">
                      <p className="date">11 October 2023</p>
                      <div className="btns">
                        <button className="active-btn">
                          <span className="btn-text">Active</span>
                        </button>
                        <button className="view-btn">
                          <span className="btn-text">View</span>
                        </button>
                      </div>
                    </div>
                  </CompanyCards>
                )}
              </Panel>
            </Collapse> */}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}
