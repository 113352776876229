import styled from "styled-components";

const MessageBodyContainer = styled.div`
  background-color: white;
  width: 100%;
  margin: 0px !important;
  height: 100%;
  padding: 0px;
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;

  .employee-dropdown {
    z-index: 1000;
    background-color: #fff;
    border-radius: 4px;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ddd;
    .employee-item {
      padding: 10px 15px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      transition: background-color 0.3s ease;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
    }
  }

  .employee-item:hover {
    background-color: #fafbfc;
  }

  /* Active state for selected item */
  .employee-item.selected {
    background-color: #e0e0e0;
  }

  /* Optionally add styling for different states (e.g., if using checkmarks or icons) */
  .employee-item img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }

  /* Clear the default margin/padding from the container */
  .employee-dropdown,
  .employee-item {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #e3eced;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 8px;
    margin-top: -0px !important;
    ${"" /* z-index: 10 !important; */}
  }

  .ql-editor {
    border: none;
  }

  .ql-snow * {
    border: none;
  }

  .quill-editor .ql-container {
    border: none;
  }

  .quill-editor .ql-toolbar {
    margin: 0;
  }

  .text-area-wrapper {
    position: relative;
    height: auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-bottom: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 5px;
  background-color: #f7f9fa;
  border-top: 1px solid #e3eced;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 50;
  right: 50;
  width: 348px;
  margin: 0;

  .custom-toolbar {
    padding: 0;
    display: flex;
    gap: 10px;
    button {
      background-color: white;
      border: 1px solid #e3eced;
      border-radius: 4px;
      cursor: pointer;
      ${
        "" /* width: 40px;
      height: 30px; */
      }
    }
  }

  .send-button {
    margin-left: auto;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    padding: 6px 15px;
  }
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4.3px 5.5px;
  background-color: white;
  border: 1px solid #e3eced;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;

  svg {
    font-size: 14px !important;
    color: #2b3746 !important;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0px 10px 14px 10px;
`;

const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f7f7f7;
  flex-basis: 100%; // Set to take up full width
  position: relative;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 140px;
`;

const RemoveIcon = styled.div`
  margin-left: 10px;
  color: red;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 5;
  bottom: 5;
  right: 4px;
`;

export {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
};
