import React from 'react'
import { Container } from './styles'
import './styles'
import GetLogo from '../../getlogo/getlogo'
import InvoiceCompanyDropDown from '../../invoiceFiltersDropDown/invoiceCompanyDropDown/InvoiceCompanyDropDown'
import BoardCustomDropDown from '../boardCustomDropDown/BoardCustomDropDown'
const TicketCenterSearchBar = ({
  setTimeSearchParam,
  timeSearchParam,
  setQueryParam,
  allCardsClosed,
  ticketBoardDataLoading,
}) => {
  return (
    <Container>
      <div className="search">
        <span className="icon">{GetLogo('searchIcon')}</span>
        <input
          onChange={(e) => setQueryParam(e.target.value)}
          type="text"
          className="input"
          placeholder="Search |"
        />
      </div>
      <div className="msg-text">
        <>
          {!ticketBoardDataLoading && allCardsClosed
            ? 'Good Job! You are up to date with this pipeline'
            : ''}
        </>
      </div>
    </Container>
  )
}

export default TicketCenterSearchBar
