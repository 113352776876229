import React, { useState, useEffect, useRef } from "react";
import {
  ChatMessagesContainer,
  MessageContainer,
  Avatar,
  MessageContent,
  SenderName,
  Timestamp,
  MessageText,
  ThreadContainer,
  ThreadMessage,
  ReactionContainer,
  SelectedEmojis,
  AttachmentList,
} from "./styles";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { LuHourglass, LuReply, LuSmilePlus } from "react-icons/lu";
import EmojiPicker from "emoji-picker-react";
import threadSpool from "../../../../../assets/icons/thread-spool.svg";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../App";

const ChatMessages = ({
  openThread,
  setOpenThread,
  toggleThread,
  data,
  setQuotedMessage,
  spaceMessages = { spaceMessages },
  refetchSpaceMessages,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [openEmojiId, setOpenEmojiId] = useState(null);
  const [messageEmojis, setMessageEmojis] = useState({});
  const { auth } = useSelector((state) => state);
  const [localReactions, setLocalReactions] = useState({});
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);

  const emojiPickerRef = useRef(null);
  const messageRefs = useRef({});

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setOpenEmojiId(null);
    }
  };

  useEffect(() => {
    if (openEmojiId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEmojiId]);

  const handleEmojiClick = async (message, emojiObject) => {
    setOpenEmojiId(null);
    const formData = new FormData();
    formData.append("reaction", emojiObject.emoji);
    formData.append("unified", emojiObject.unified);
    formData.append("message_detail_id", message?.id);
    formData.append("replied_to_type", "message");

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${message?.space_id}/messages/${message?.id}/add-reaction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        refetchSpaceMessages();
      }
    } catch (error) {
      console.error("Failed to add reaction:", error);
    }
  };

  const handleEmojiRemoveClick = async (message, emoji, reaction_id) => {
    if (!reaction_id) {
      return;
    }
    const formData = new FormData();
    formData.append("reaction", emoji);
    formData.append("message_detail_id", message?.id);
    formData.append("replied_to_type", "message");
    formData.append("reaction_id", reaction_id);

    try {
      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${message?.space_id}/messages/${message?.id}/remove-reaction`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        refetchSpaceMessages();
      }
    } catch (error) {
      console.error("Failed to remove reaction:", error);
    }
  };

  const scrollToMessage = (messageId) => {
    const messageElement = messageRefs.current[messageId];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });

      setHighlightedMessageId(messageId);

      setTimeout(() => {
        setHighlightedMessageId(null);
      }, 1500);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const options = { day: "numeric", month: "long" };
    const yearOptions = { year: "numeric", ...options };
    const dateFormatted =
      date.getFullYear() === today.getFullYear()
        ? date.toLocaleDateString(undefined, options)
        : date.toLocaleDateString(undefined, yearOptions);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return dateFormatted;
    }
  }

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <ChatMessagesContainer>
      {spaceMessages?.data &&
        Object.keys(spaceMessages?.data)?.map((date) => (
          <>
            <div className="separator-div">
              <span className="line"></span>
              <span className="date">{formatDate(date)}</span>
              <span className="line"></span>
            </div>
            {spaceMessages?.data[date]?.map((message) => {
              return (
                <MessageContainer
                  ref={(el) => (messageRefs.current[message.id] = el)}
                  key={message?.id}
                  currentUserId={auth?.userObj?.id}
                  userId={message?.user_id}
                >
                  <Avatar src={message?.avatar} alt={message?.user_name} />
                  <MessageContent
                    currentUserId={auth?.userObj?.id}
                    userId={message?.user_id}
                    onMouseEnter={() => setHoveredMessageId(message?.id)}
                    onMouseLeave={() => setHoveredMessageId(null)}
                  >
                    <div className="sender-div">
                      <div className="make-row">
                        <SenderName
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          {message?.user_name}
                        </SenderName>
                        <Timestamp
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          {new Date(message?.created_at).toLocaleString()}
                        </Timestamp>
                      </div>
                      {hoveredMessageId === message?.id && (
                        <ReactionContainer
                          currentUserId={auth?.userObj?.id}
                          userId={message?.user_id}
                        >
                          <span onClick={() => setOpenEmojiId(message?.id)}>
                            <LuSmilePlus />
                          </span>
                          <span onClick={() => toggleThread(message?.id)}>
                            <img
                              src={threadSpool}
                              height={17}
                              width={17}
                              alt=""
                            />
                          </span>
                          <span>
                            <LuReply
                              onClick={() => setQuotedMessage(message)}
                            />
                          </span>
                          {/* <span>
                            <HiOutlineDotsVertical />
                          </span> */}
                        </ReactionContainer>
                      )}
                      {openEmojiId === message?.id && (
                        <div className="emoji-div" ref={emojiPickerRef}>
                          <EmojiPicker
                            height={400}
                            onEmojiClick={(emojiObject) =>
                              handleEmojiClick(message, emojiObject)
                            }
                          />
                        </div>
                      )}
                    </div>
                    {message?.message?.replied_to_message !== null && (
                      <div
                        className="reply-message-div"
                        onClick={() =>
                          scrollToMessage(
                            message?.message?.replied_to_message?.id
                          )
                        }
                      >
                        <div className="reply-header">
                          <div className="left">
                            <span className="image-container">
                              <img
                                src={
                                  message?.message?.replied_to_message?.avatar
                                }
                                alt={
                                  message?.message?.replied_to_message
                                    ?.user_name
                                }
                              />
                            </span>
                            <span className="name">
                              {message?.message?.replied_to_message?.user_name}
                            </span>
                          </div>
                        </div>
                        <span className="quoted-message">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                message?.message?.replied_to_message?.message
                                  ?.message,
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <MessageText
                      currentUserId={auth?.userObj?.id}
                      userId={message?.user_id}
                      replied_to_message={message?.replied_to_message}
                      style={{
                        border:
                          highlightedMessageId === message.id
                            ? "1px solid var(--Blue-100, #4cbbcb)"
                            : "none",
                        boxShadow:
                          highlightedMessageId === message.id
                            ? "0px 0px 10px 0px rgba(86, 191, 207, 0.24)"
                            : "none",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: message?.message?.message,
                        }}
                      />
                    </MessageText>
                    <AttachmentList>
                      {message?.attachments?.map((file, index) => (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          className="AttachmentItem"
                        >
                          <img className="file-image" src={file?.url} alt=" " />
                        </a>
                      ))}
                    </AttachmentList>

                    {message?.message?.reactions?.length > 0 && (
                      <SelectedEmojis
                        currentUserId={auth?.userObj?.id}
                        userId={message?.user_id}
                      >
                        {message?.message?.reactions?.map((emoji, index) => (
                          <>
                            <span
                              key={index}
                              style={{
                                fontSize: "20px",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleEmojiRemoveClick(
                                  message,
                                  emoji?.reaction,
                                  emoji?.id
                                )
                              }
                            >
                              {emoji?.reaction} {emoji?.count}
                            </span>
                          </>
                        ))}
                      </SelectedEmojis>
                    )}
                  </MessageContent>
                </MessageContainer>
              );
            })}
          </>
        ))}
    </ChatMessagesContainer>
  );
};

export default ChatMessages;
