import React, { Fragment, useState, useEffect, useRef } from 'react'
import CommonHaeder from '../../components/header/commonHeader/CommonHaeder'
import TicketCenterSubHeader from '../../components/ticketCenter/ticketCenterSubHeader/TicketCenterSubHeader'
import TicketCenterFilters from '../../components/ticketCenter/ticketCenterFilters/TicketCenterFilters'
import { MainConatiner, BoardContainer } from './styles'
import Board from 'react-trello'
import { Avatar, Drawer, Skeleton } from 'antd'
import TicketBoardCards from '../../components/ticketCenter/ticketCards/TicketBoardCards'
import TicketCenterSearchBar from '../../components/ticketCenter/ticketCenterSearchBar/TicketCenterSearchBar'
import useGetData from '../../hooks/useGetData'
import { useNavigate } from 'react-router-dom'
import { InstanceWithAuth } from '../../App'
import { useSelector } from 'react-redux'
import ReactTostify from '../../common/ReactTostify'
import { CloseOutlined } from '@ant-design/icons'
import FirstColumn from '../../components/ticket/FirstColumn/FirstColumn'
import CustomTooltip from '../../components/allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip'
import NameAvatar from '../../common/NameAvatar'
import DataNotFound from '../../common/DataNotFound'

function TicketCenter() {
  const { auth } = useSelector((state) => state)
  const { app } = useSelector((state) => state)
  const [selectedPipeline, setSelectedPipeline] = useState('')
  const [type, setType] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [selectedPriority, setSelectedPriority] = useState('')
  const [selectedCreatedDate, setSelectedCreatedDate] = useState('')
  const [selectedLastActivityDate, setSelectedLastActivityDate] = useState('')
  const [queryParam, setQueryParam] = useState('')
  const [selectedDate, setSelectTime] = useState('')
  const [startDate, setStartDate] = useState('')
  const [selectedLocation, setSelectedLocation] = useState()
  const [endDate, setEndDate] = useState('')
  const [filterDateType, setFilterDateType] = useState('')
  const [advancedFilters, setAdvancedFilters] = useState(null)
  const [loadingFilteredTicktes, setLoadingFilteredTicktes] = useState(false)
  const [openTicketDrawer, setOpenTicketDrawer] = useState(false)
  const [ticketData, setTicketData] = useState(null)
  const [allCardsClosed, setAllCardsClosed] = useState(false)

  console.log(allCardsClosed, 'allCardsClosed')
  const {
    data: ticketBoardData,
    refetchData: refetchTikcedBoardData,
    loading: ticketBoardDataLoading,
  } = useGetData(
    `support/ticket-board?support_pipeline_id=${
      app?.userSelcetedPipeLine ? app?.userSelcetedPipeLine?.id : ''
    }&owner=${
      selectedOwner && selectedOwner?.name != 'all' ? selectedOwner?.id : ''
    }&priority=${
      selectedPriority?.name && selectedPriority?.name != 'all'
        ? selectedPriority?.name
        : ''
    }&location_id=${
      selectedLocation && selectedLocation?.name != 'all'
        ? selectedLocation?.key
        : ''
    }&query=${queryParam ? queryParam : ''}&from_date=${
      startDate && startDate != '-undefined-undefined' ? startDate : ''
    }&to_date=${endDate && endDate != '-undefined-undefined' ? endDate : ''}`
  )
  const navigate = useNavigate()
  useEffect(() => {
    refetchTikcedBoardData()
  }, [
    selectedPipeline,
    selectedOwner,
    selectedPriority,
    queryParam,
    selectedLocation,
  ])

  useEffect(() => {
    if (selectedCreatedDate?.name == 'all') {
      setStartDate('')
      setEndDate('')
      refetchTikcedBoardData()
    }
  }, [selectedCreatedDate])

  const showDrawerContactDrawer = () => {
    setOpenTicketDrawer(true)
  }
  const onCloseTicketDrawer = () => {
    setOpenTicketDrawer(false)
  }

  const isFirstLoad = useRef(true)
  let showLoading = false
  const now = new Date()
  const cardStyle = {
    border: '1px solid #4cbbcb',
    borderRadius: '8px',
    padding: '10px',
    margin: '5px 0px ',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    color: '#2b3746',
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: 'normal',
    letterSpacing: '-0.49px',
    width: '250px',
    fontWeight: '400',
  }
  const laneStyle = {
    backgroundColor: 'rgba(76, 187, 203, 0.2)',
    fontSize: '20px',
    fontWeight: '600',
  }
  function timeAgo(dateString) {
    const date = new Date(dateString)

    if (isNaN(date.getTime())) {
      return 'Invalid date'
    }

    const now = new Date()
    const seconds = Math.floor((now - date) / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(seconds / 3600)
    const days = Math.floor(seconds / 86400)
    console.log(seconds, 'seconds')

    if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`
    }
    if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`
    }
    if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`
    }
    return 'Just now'
  }

  const [count, setCount] = useState(0) // Global count for tracking movements
  const [mappedData, setMappedData] = useState({ lanes: [] })

  // Initialize lanes and cards
  const truncateText = (text, maxLength = 100) => {
    if (!text) return ''
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
  }

  const CustomCard = ({ title, description, owner, avatar, label, id }) => (
    <div
      style={{
        background: '#d4ebef',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '8px',
        border: '1px solid #b2ebf2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '80px',
        cursor: 'pointer',
      }}
      onClick={() => handleCardClick(id)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            fontWeight: '500',
            // color: '#00796B',
            fontFamily: 'Outfit',
            width: '70%',
          }}
        >
          {title}
        </div>
        <div style={{ fontSize: '12px' }}>{timeAgo(label)}</div>
      </div>

      {/* Description */}
      <div style={{ fontSize: '12px', color: '#333', marginTop: '4px' }}>
        {description}
      </div>

      {/* Horizontal Line */}
      <div style={{ marginTop: '10px', borderTop: '1px solid #ccc' }}></div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {avatar != null ? (
          <CustomTooltip text={owner} marginleft="30px" marginTop="-30px">
            <img
              src={avatar}
              alt="avatar"
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                marginRight: '8px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            />
          </CustomTooltip>
        ) : owner != null && avatar == null ? (
          <CustomTooltip text={owner} marginleft="30px" marginTop="-20px">
            <div
              style={{
                marginRight: '8px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              <NameAvatar val={20} userName={owner} fontSize={12}></NameAvatar>
            </div>
          </CustomTooltip>
        ) : (
          <CustomTooltip text={'Unassigned'}>
            <div
              style={{
                marginRight: '8px',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: '#ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '8px',
                  cursor: 'pointer',
                }}
              ></span>
            </div>
          </CustomTooltip>
        )}
      </div>
    </div>
  )
  useEffect(() => {
    const initializeLanes = () => {
      const dataSource =
        advancedFilters?.status === 'success'
          ? advancedFilters.data
          : Array.isArray(ticketBoardData?.data)
          ? ticketBoardData.data
          : []

      if (Array.isArray(dataSource) && dataSource.length > 0) {
        let allClosed = true // Assume all cards are closed initially

        const lanes = dataSource.map((stage) => {
          const cards = Array.isArray(stage?.cards)
            ? stage.cards.map((card) => {
                // Check if any card is not closed
                if (card.status !== 'Closed') {
                  allClosed = false
                }
                return {
                  id: card?.id?.toString(),
                  title: card?.title,
                  label: card?.label,
                  description: truncateText(card?.description, 80),
                  owner: card?.owner || null,
                  metadata: { sha: 'be312a1' },
                  avatar: card?.avatar || null,
                }
              })
            : []

          return {
            id: stage?.id?.toString(),
            title: stage.title,
            label: `${stage?.cards?.length || 0}`,
            style: {
              background: 'rgba(241, 244, 245, 0.8)',
              fontSize: '14px',
              color: '#2b3746',
              fontFamily: 'Outfit',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              letterSpacing: '-0.49px',
              height: '410px',
              width: '280px',
              margin: '5px 5px 0px 0px',
              header: {
                height: '30px',
              },
            },
            cards,
          }
        })

        setMappedData({ lanes })
        setAllCardsClosed(allClosed) // Update the state based on whether all cards are closed
      } else {
        setMappedData({ lanes: [] }) // Ensure that lanes are cleared when dataSource is empty
        setAllCardsClosed(false) // Reset the state if there's no data
      }
    }

    initializeLanes()
  }, [ticketBoardData?.data, advancedFilters?.data])

  const handleDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    setMappedData((prevData) => {
      const updatedLanes = prevData.lanes.map((lane) => {
        // Case: Same lane
        if (lane.id === sourceLaneId && sourceLaneId === targetLaneId) {
          // Reorder cards within the same lane
          const cardIndex = lane.cards.findIndex((card) => card.id === cardId)
          const updatedCards = [...lane.cards]
          const [movedCard] = updatedCards.splice(cardIndex, 1) // Remove the card
          updatedCards.splice(position, 0, movedCard) // Add it back at the new position

          return {
            ...lane,
            cards: updatedCards,
            label: `${updatedCards.length}`, // Update label
          }
        }

        // Case: Source lane
        if (lane.id === sourceLaneId) {
          return {
            ...lane,
            cards: lane.cards.filter((card) => card.id !== cardId), // Remove card
            label: `${lane.cards.length - 1}`, // Update label
          }
        }

        // Case: Target lane
        if (lane.id === targetLaneId) {
          return {
            ...lane,
            cards: [
              ...lane.cards.slice(0, position),
              cardDetails, // Add card at new position
              ...lane.cards.slice(position),
            ],
            label: `${lane.cards.length + 1}`, // Update label
          }
        }

        return lane
      })

      setCount((prevCount) => prevCount + 1)

      return { lanes: updatedLanes }
    })

    handleSubmit(cardId, sourceLaneId, targetLaneId, position, cardDetails)
  }

  const handleCardClick = async (cardId) => {
    setOpenTicketDrawer(true)
    fetchTicketData(cardId)
  }

  const fetchTicketData = async (cardId) => {
    setTicketData(null)
    try {
      const response = await InstanceWithAuth.get(`support/tickets/${cardId}`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      })

      setTicketData(response)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleSubmit = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    if (sourceLaneId == targetLaneId) return null
    const data = {
      support_pipeline_id: app?.userSelcetedPipeLine?.id,
      support_pipeline_stage_id: targetLaneId,
    }
    const encodedData = new URLSearchParams(data).toString()
    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${cardId}`,
        encodedData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )

      if (response.status === 201 || response.status === 200) {
      }
    } catch (error) {
      ReactTostify('error', error?.response?.data?.message)
    }
  }
  const CustomLaneHeader = ({ title, label }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '30px',
        padding: '10px',
        fontSize: '15px',
        fontWeight: '500',
        marginBottom: '0px',
        padding: '0px 5px',
      }}
    >
      <span>{title}</span>
      <span style={{ fontSize: '12px', color: '#6b6b6b' }}>{label}</span>
    </div>
  )

  console.log(app?.userSelcetedPipeLine, 'app?.userSelcetedPipeLine')
  return (
    <Fragment>
      <CommonHaeder
        firstBcItem={app?.userSelcetedPipeLine?.type}
        firstBcIcon="logTickets"
        secondBcItem={app?.userSelcetedPipeLine?.name}
        secondBcIcon="logTickets"
      />
      <TicketCenterSubHeader />
      <MainConatiner>
        <TicketCenterFilters
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          type={type}
          setType={setType}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          selectedCreatedDate={selectedCreatedDate}
          setSelectedCreatedDate={setSelectedCreatedDate}
          selectedLastActivityDate={selectedLastActivityDate}
          setSelectedLastActivityDate={setSelectedLastActivityDate}
          app={app}
          selectedOption={selectedDate}
          setSelectedOption={setSelectTime}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          setFilterDateType={setFilterDateType}
          filterDateType={filterDateType}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          setAdvancedFilters={setAdvancedFilters}
          setLoadingFilteredTicktes={setLoadingFilteredTicktes}
        />
        <BoardContainer>
          <TicketCenterSearchBar
            setQueryParam={setQueryParam}
            allCardsClosed={allCardsClosed}
            ticketBoardDataLoading={ticketBoardDataLoading}
          />
          <div className="kanban-board">
            {!ticketBoardDataLoading &&
            !loadingFilteredTicktes &&
            mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: '280px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <DataNotFound
                  label={'Good Job! You are up to date with this pipeline'}
                ></DataNotFound>
              </div>
            ) : ticketBoardDataLoading || loadingFilteredTicktes ? (
              <Skeleton active></Skeleton>
            ) : mappedData?.lanes && mappedData?.lanes?.length > 0 ? (
              <Board
                data={mappedData}
                draggable
                // collapsibleLanes

                components={{
                  Card: (props) => (
                    <CustomCard
                      title={props.title}
                      description={props.description}
                      owner={props.owner}
                      avatar={props.avatar}
                      label={props.label}
                      id={props.id}
                    />
                  ),
                  LaneHeader: CustomLaneHeader,
                }}
                laneButtonStyle={{ display: 'none' }}
                handleDragEnd={handleDragEnd}
                cardStyle={cardStyle}
                onCardClick={handleCardClick}
                hideCardDeleteIcon={true}
                laneStyle={laneStyle}
              />
            ) : !ticketBoardDataLoading &&
              !loadingFilteredTicktes &&
              mappedData.lanes.length == 0 ? (
              <div
                style={{
                  minHeight: '280px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <DataNotFound
                  label={'Good Job! You are up to date with this pipeline'}
                ></DataNotFound>
              </div>
            ) : null}
          </div>
        </BoardContainer>

        <Drawer
          mask={false}
          className="drawer"
          width={400}
          padding={0}
          paddingLG={0}
          paddingXS={0}
          onClose={onCloseTicketDrawer}
          open={openTicketDrawer}
          headerStyle={{
            display: 'none',
          }}
          style={{
            padding: '0px',
            margin: '0px',
          }}
          closeIcon={
            <CloseOutlined style={{ color: 'white', fontSize: 'bold' }} />
          }
          title="All Filters"
        >
          {!ticketData?.data ? (
            <>
              <Skeleton />
            </>
          ) : (
            <FirstColumn
              ticket={ticketData?.data?.data}
              refetchData={fetchTicketData}
              CallingFromTicketCenter={true}
              setOpenTicketDrawer={setOpenTicketDrawer}
              refetchTikcedBoardData={refetchTikcedBoardData}
            />
          )}
        </Drawer>
      </MainConatiner>
    </Fragment>
  )
}

export default TicketCenter
