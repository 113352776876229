import React, { useState, useRef, useEffect } from "react";
import { MainContainer } from "./styles";
import { Select } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
const InvoiceCompanyDropDown = ({
  companiesData,
  setSelectedCompnayId,
  setCompanySearchParam,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [filteredMailProfileData, setFilteredMailProfileData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCursor, setShowCursor] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchParam) {
      const filteredOptions = companiesData?.filter((option) => {
        const valueMatches = option?.name
          ?.toString()
          ?.toUpperCase()
          ?.includes(searchParam);
        return valueMatches;
      });
      setFilteredMailProfileData(filteredOptions);
    } else {
      setFilteredMailProfileData(companiesData);
    }
  }, [searchParam, companiesData]);

  const handleSearch = (value) => {
    const upperCaseValue = value.toUpperCase();
    setSearchParam(upperCaseValue);

    const filteredOptions = companiesData?.filter((option) => {
      const valueMatches = option?.name?.toString()?.includes(upperCaseValue);
      return valueMatches;
    });

    setFilteredMailProfileData(filteredOptions);
  };

  const handleDropdownVisibleChange = (open) => {
    setShowCursor(open);
  };

  const handleSelectChangeMail = (value) => {
    let selectedProfile = companiesData?.find((item) => item?.id === value);
    setSelectedCompnayId(value);
    setCompanySearchParam(selectedProfile?.name);
  };

  console.log("filteredMailProfileData", filteredMailProfileData);
  console.log("companiesData", companiesData[0]?.name);

  return (
    <MainContainer>
      <Select
        showSearch
        className={
          showCursor
            ? "username-input custom-select searchable-select"
            : "username-input custom-select hide-cursor searchable-select"
        }
        placeholder="Select Company |"
        removeIcon={true}
        onChange={handleSelectChangeMail}
        onSearch={handleSearch}
        filterOption={false}
        allowClear={true}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        loading={isLoading}
        popupClassName="companyDataPopUp"
        style={{ padding: "0px" }}
        suffixIcon={<MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>}
      >
        {filteredMailProfileData?.map((option, index) => (
          <Select.Option
            key={option?.id}
            value={option?.id}
            label={option?.name}
          >
            <div
              className="MailBox-handleUserDetails"
              style={{
                paddingLeft: "5px",
                fontWeight: "400",
                fontSize: "12px",
                color: "var(--Gray-100, #556373)",
                fontFamily: "Outfit",
                lineHeight: "16px",
                padding: "8px",
              }}
            >
              {option?.name}
            </div>
          </Select.Option>
        ))}
      </Select>
    </MainContainer>
  );
};

export default InvoiceCompanyDropDown;
