import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.333px;

  .custom-dropdown {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .dropdown-header {
    cursor: pointer;
    display: flex;
    padding: 0px 2px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    max-width: 300px;

    .user-name {
      color: #4cbbcb;
      text-align: right;
      font-family: Outfit;
      font-size: 17px;
      font-weight: 400;
      line-height: 16px;
      flex-grow: 1;
    }
  }

  .dropdown-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    z-index: 9;
    width: 200px;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #e3eced;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);

    .item-div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      padding: 5px 7px;
      border-radius: 5px;

      span {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        width: 100%;
      }
    }

    .item-div:hover {
      background: rgba(76, 187, 203, 0.2);
    }
  }
`;

export { MainContainer };
