import { useState, useEffect } from "react";
import { InstanceWithAuth } from "../App";
import { useSelector } from "react-redux";
import { useGlobalAlertMessage } from "./useAlertMessage";

const useGetData = (url, initialData = null, showErrorMSg) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { auth } = useSelector((state) => state);
  const { showMessage } = useGlobalAlertMessage();
  const fetchData = async (postData) => {
    try {
      setLoading(true);
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      setError(error);
      if (showErrorMSg) {
        showMessage({
          type: "error",
          content: error?.response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const refetchData = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [url]);
  return { data, loading, error, fetchData, refetchData };
};

export default useGetData;
