import styled from "styled-components";

const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  width: 100% !important;

  .separator-div {
    width: 100% !important;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 10px 0;
    .date {
      font-family: Outfit;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
      margin: 0 15px;
    }
    .line {
      flex: 1;
      background: rgba(239, 245, 250, 1);
      height: 1px;
    }
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: ${(props) =>
    props.currentUserId === props.userId ? "flex-end" : "flex-start"};
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 30px 15px 0 15px;
  border: 1px solid #e3eced;
`;

const MessageContent = styled.div`
  text-align: left;
  max-width: 70%;

  .reply-message-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px; /* Thickness of the blue border */
    border-left: 3px solid #4cbbcb;
    border-top-left-radius: 10px; /* For the top-left curve */
    border-bottom-left-radius: 0px; /* For the bottom-left curve */
    border-top: 3px solid #4cbbcb;
  }

  .reply-message-div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 20px; /* Adjust height of the curve */
    width: 20px; /* Adjust width of the curve */
    border-top: 3px solid #4cbbcb;
    border-left: 3px solid #4cbbcb;
    border-top-left-radius: 10px; /* Round the corner for smooth effect */
  }

  .reply-message-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #f7f9fa;
    position: relative; /* Needed for the pseudo-element */
    margin: 0;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    margin-top: 10px;

    .reply-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        .image-container {
          height: 30px;
          width: 30px;
          overflow: hidden;
          border-radius: 30px;
        }
        .name {
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: var(--Gray-100, #556373);
        }
      }

      .cross-icon {
        cursor: pointer;
      }
    }

    .quoted-message {
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
      margin-top: 5px;
    }
  }

  .sender-div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    .make-row {
      display: flex;
      align-items: center;
    }

    .emoji-div {
      position: absolute;
      top: 30px;
      z-index: 1000;
      left: ${(props) => props.currentUserId !== props.userId && "0px"};
      right: ${(props) => props.currentUserId === props.userId && "0px"};
    }
  }
`;

const SelectedEmojis = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 1.5px;
  justify-content: ${(props) =>
    props.currentUserId !== props.userId ? "flex-start" : "flex-end"};
  span {
    border: 1px solid rgb(227, 236, 237);
    background: rgba(85, 99, 115, 0.05);
    align-items: center;
    border-radius: 7px;
    padding: 5px;
    color: #4cbbcb;
  }
`;

const SenderName = styled.div`
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--Gray-100, #556373);
  margin-bottom: 5px;
`;

const Timestamp = styled.div`
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--Gray-100, #556373);
  margin-left: 10px;
`;

const MessageText = styled.div`
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--Gray-100, #556373);
  border-radius: ${(props) =>
    props.replied_to_message !== null ? "0px 0px 10px 10px" : "10px"};

  padding: 1px 15px;
  background-color: ${(props) =>
    props.currentUserId !== props.userId
      ? "#f5f8f9"
      : "rgba(76, 187, 203, 0.2)"};
  border: 1px solid #e3eced;
  white-space: pre-wrap;
  word-break: break-word;
`;

const ThreadContainer = styled.div`
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #e3eced;
`;

const ThreadMessage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) =>
    props.currentUserId === props.userId ? "flex-end" : "flex-start"};
  margin-bottom: 15px;
`;

const ReactionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  cursor: pointer;
  position: absolute;
  right: 0px !important;
  top: -5px;
  span {
    font-size: 20px;
  }
`;

export {
  ChatMessagesContainer,
  MessageContainer,
  Avatar,
  MessageContent,
  SenderName,
  Timestamp,
  MessageText,
  ThreadContainer,
  ThreadMessage,
  ReactionContainer,
  SelectedEmojis,
};
