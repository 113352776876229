import React, { useState, useEffect } from "react";
import { SecondColumnContainer } from "./styles";
import ChatSection from "./chatSection/ChatSection";
import useGetData from "../../../hooks/useGetData";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenThread,
  setQuotedMessage,
  setThreadQuotedMessage,
} from "../../../redux/app/appSlice";

const SpaceSmallerView = ({
  space,
  refetchSpaces,
  selectedDirectMessage,
  firstRender,
  setFirstRender,
  mainDivWidth,
  setMainDivWidth,
}) => {
  const {
    data: spaceMessages,
    spaceMessagesError,
    refetchData: refetchSpaceMessages,
    loading: loadingMessages,
  } = useGetData(`internal-chat/spaces/${space?.id}/messages`);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  const quotedMessage = app?.quotedMessage;
  const threadQuotedMessage = app?.threadQuotedMessage;
  const openThread = app?.openThread;

  const updateQuotedMessage = (message) => dispatch(setQuotedMessage(message));
  const updateThreadQuotedMessage = (message) =>
    dispatch(setThreadQuotedMessage(message));
  const updateOpenThread = (thread) => dispatch(setOpenThread(thread));

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchSpaceMessages();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [refetchSpaceMessages]);

  // useEffect(() => {
  //   if (spaceMessages) {
  //     refetchSpaces();
  //   }
  // }, [spaceMessages]);

  return (
    <SecondColumnContainer>
      <div className="chat-section">
        <ChatSection
          spaceMessages={spaceMessages}
          loadingMessages={loadingMessages}
          selectedSpace={space}
          refetchSpaceMessages={refetchSpaceMessages}
          quotedMessage={quotedMessage}
          setQuotedMessage={updateQuotedMessage}
          threadQuotedMessage={threadQuotedMessage}
          setThreadQuotedMessage={updateThreadQuotedMessage}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          openThread={openThread}
          setOpenThread={updateOpenThread}
          mainDivWidth={mainDivWidth}
          setMainDivWidth={setMainDivWidth}
        />
      </div>
    </SecondColumnContainer>
  );
};

export default SpaceSmallerView;
