import React, { useState, useEffect, useRef } from 'react'
import { Container } from './styles'
import GetLogo from '../../getlogo/getlogo'
import './styles'
import CreateTicketModel from '../createTicketModel/CreateTicketModel'

const TicketCenterSubHeader = ({}) => {
  const [createTikcetModelOpen, setCreateTikcetModelOpen] = useState(false)

  const handleOk = async () => {
    setCreateTikcetModelOpen(false)
  }

  const handleCancel = () => {
    setCreateTikcetModelOpen(false)
  }

  return (
    <Container>
      <div className="title">Tasks & Tickets</div>
      <div className="filter">
        {/* <button onClick={''} className="add-btn">
          Actions
        </button>
        <button onClick={''} className="add-btn">
          Import
        </button> */}
        <button
          onClick={() => setCreateTikcetModelOpen(true)}
          className="add-btn"
        >
          Create Ticket
        </button>
      </div>

      <CreateTicketModel
        createTikcetModelOpen={createTikcetModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </Container>
  )
}

export default TicketCenterSubHeader
