import React, { useState } from "react";
import { ChatItemContainer } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdPhotoSizeSelectSmall } from "react-icons/md";

const ChatItem = ({ space, selectedSpace, handleAddSpace }) => {
  const [isOnline, setIsOnline] = useState(true);

  const TruncateText = ({ text }) => {
    const maxLength = 17;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };
  return (
    <ChatItemContainer>
      <div
        className="outer-div"
        style={{
          backgroundColor:
            selectedSpace?.id === space?.id &&
            "var(--Blue-20, rgba(76, 187, 203, 0.2))",
          boxShadow:
            selectedSpace?.id === space?.id &&
            "0px 0px 10px 0px rgba(86, 191, 207, 0.24)",
          borderRadius: selectedSpace?.id === space?.id && "30px",
        }}
      >
        <div className="left">
          <div className="image-div">
            <span>{space?.icon}</span>
          </div>

          <span
            style={{
              fontWeight: space?.unread_count > 0 && "600",
              fontSize: space?.unread_count > 0 && "15px",
            }}
            className="name"
          >
            <TruncateText text={space?.name}></TruncateText>
          </span>
        </div>

        <div className="right">
          <span className="icon" onClick={() => handleAddSpace(space)}>
            <MdPhotoSizeSelectSmall color="#2b3746" />
          </span>
          {/* <span className="icon">
            <BsThreeDotsVertical color="#2b3746" />
          </span> */}
        </div>
        {space?.unread_count > 0 && (
          <span className="unread_count">{space?.unread_count}</span>
        )}
      </div>
    </ChatItemContainer>
  );
};

export default ChatItem;
