import React from 'react'
import './styles'
import { Container, Title, UserAvatar, ContentContainer } from './styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LuExternalLink } from 'react-icons/lu'
import { Skeleton } from 'antd'
import NameAvatar from '../../../../common/NameAvatar'
import Ticket from '../../../componyFlowComponents/companyDetailSidebar/companyDetailComponents/ticket/Ticket'

const Unknown = ({ email, selectedEmailId, showDrawerContactDrawer }) => {
  return (
    <>
      <Container>
        <Title>
          <div className="avatar-wrapper">
            <div className="user-img">
              {email?.data?.avatar ? (
                <img src={email?.data?.avatar} alt="" />
              ) : (
                <NameAvatar
                  val={40}
                  userName={`${email?.data?.name || 'UV'}`}
                ></NameAvatar>
              )}
            </div>

            <div className="name-wrapper">
              <span className="name">Unknown Visitor</span>
              <button onClick={showDrawerContactDrawer} className="btn">
                Associate Contact
              </button>
            </div>
          </div>
        </Title>
      </Container>
    </>
  )
}

export default Unknown
