import React, { useState } from 'react'
import { MainContainer, BtnWarapper } from './styles'
import { useNavigate, useParams } from 'react-router-dom'
import CommonHaeder from '../../components/header/commonHeader/CommonHaeder'
import FirstColumn from '../../components/ticket/FirstColumn/FirstColumn'
import dummyData from './dummyData.json'
import SecondColumn from '../../components/ticket/SecondColumn/SecondColumn'
import ThirdColumn from '../../components/ticket/ThirdColumn/ThirdColumn'
import useGetData from '../../hooks/useGetData'
import { Skeleton } from 'antd'
import Spinner from '../../common/Spinner'

const Ticket = () => {
  const [isComposerVisible, setIsComposerVisible] = useState(false)
  const [showSaveBtn, setShowSaveBtn] = useState(false)

  const { companyID, ticketID } = useParams()
  const {
    data: companyData,
    loading: companyDataLoading,
    error: companyDataErro,
  } = useGetData(`companies/${companyID}`)

  const {
    data: ticketData,
    loading: ticketDataLoading,
    error: ticketDataErro,
    refetchData,
  } = useGetData(`support/tickets/${ticketID}`)

  const openComposer = () => {
    setIsComposerVisible(true)
  }

  const closeComposer = () => {
    setIsComposerVisible(false)
  }

  const handleCancel = () => {
    setShowSaveBtn(false)
  }
  return (
    <>
      <CommonHaeder
        firstBcItem={'Tickets'}
        firstBcIcon={'logTickets'}
        secondBcItem={`${
          ticketData?.data?.type === 'Ticket' ? 'Ticket #' : 'Task #'
        }${ticketData?.data?.number}`}
        secondBcIcon={'logTickets'}
      />
      <MainContainer>
        <div className="first-column">
          {ticketDataLoading ? (
            <div style={{ padding: '12px' }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <FirstColumn
                ticket={ticketData?.data}
                refetchData={refetchData}
                // data={dummyData}
              />
            </>
          )}
        </div>
        <div className="second-column">
          {ticketDataLoading ? (
            <div style={{ padding: '12px' }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <SecondColumn
                openComposer={openComposer}
                ticket={ticketData?.data}
                ticketID={ticketID}
                refetchDataTicket={refetchData}
              />
            </>
          )}
        </div>
        <div className="third-column">
          {ticketDataLoading ? (
            <div style={{ padding: '12px' }}>
              <Skeleton active />
            </div>
          ) : (
            <>
              <ThirdColumn ticket={ticketData?.data} />
            </>
          )}
        </div>
      </MainContainer>
    </>
  )
}

export default Ticket
