import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.333px;
  height: 30px;

  .custom-dropdown {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .search-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    list-style: none !important;
    position: absolute;
    margin-left: -16px;
    ::marker {
      display: none;
    }
  }

  .search-bar {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    border: 1px solid #e3eced;
    border-radius: 4px;
    outline: none;
  }

  .search-bar:focus {
    border-color: #4cbbcb;
  }
  .dropdown-header {
    cursor: pointer;
    display: flex;
    padding: 0px 2px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    max-width: 230px;

    .user-name {
      color: #4cbbcb;
      text-align: right;
      font-family: Outfit;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      flex-grow: 1;
    }
    .MdOutlineIcon {
      ${'' /* color: #4cbbcb; */}
      color: rgb(0 0 0 / 45%);
    }
  }

  .dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    z-index: 9;
    width: 200px;
    padding: 16px;
    position: absolute;
    top: 95%;
    left: 0;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #e3eced;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);
    max-height: 240px;
    overflow-y: auto;

    li {
      justify-content: flex-start;
      align-items: center;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      width: 100%;

      &.role-item {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 8px 0;
        width: 100%;
        border-bottom: 1px solid #e3eced;
        list-style-type: none;

        .items {
          padding: 0;
        }
      }

      &.dropdown-item {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        width: 100%; /* Ensures dropdown items are aligned */

        .user-avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .user-initials {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #4cbbcb;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          margin-right: 10px;
        }
        .employee-name {
          max-width: 119px;
        }
        &:hover {
          border-radius: 4px;
          background-color: #dbf1f5;
        }
      }
    }
  }
`

export { MainContainer }
