import styled from "styled-components";

const Container = styled.div`
  
  .form-main {
    display: flex;
    width: 335px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex-shrink: 0;
    position: relative;

    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }
    .label {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .ant-form-item {
      height: 61px;
      margin-bottom: unset;
    }

    .add-user-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .ant-form-item-row {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start;
        gap: 4px;

        align-self: stretch;
        margin-bottom: 0;

        label {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          height: auto;
          /* line-height: 14.4px; */
        }
        .username-input ::placeholder {
          color: #888;
        }
        .ant-input[disabled],
        .ant-input-disabled {
          border: 1px solid #e3eced;
          background: #fff;
        }
        .username-input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          width: 548px;
          border: none;
          outline: none;
          align-items: center;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
          height: 41px;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .username-input:focus::placeholder {
          color: transparent;
        }
        .username-input:focus {
          box-shadow: none;
        }

        label::after {
          content: "";
        }
      }
      .email-input {
        display: flex;
        padding: 6px 12px;
        justify-content: space-between;
        height: 41px;
        border: none;
        width: 398px;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
      }
      .email-input:focus {
        box-shadow: none;
      }
      .email-main {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        max-width: 335px;

        .email-input {
          display: flex;
          padding: 6px 12px;
          justify-content: space-between;
          height: 41px;
          border: none;
          width: 398px;
          outline: none;
          align-items: center;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
        }
        .email-input:focus {
          box-shadow: none;
        }

        .email-btns {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          .btn-disabled {
            opacity: 50%;
          }
          .btn {
            display: flex;
            height: 41px;
            width: 134px;
            padding: 7px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            color: var(--Dark-Gray-100, #2b3746);
            text-align: center;
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            border-radius: 4px;
            border: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3));
            background: var(--Blue-20, rgba(76, 187, 203, 0.2));
          }
        }
      }
    }
    .password-main {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      max-width: 335px;

      .password-input {
        display: flex;
        padding: 6px 12px;
        justify-content: space-between;
        height: 41px;
        border: none;
        outline: none;
        align-items: center;

        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
      }
      .password-input:focus {
        box-shadow: none;
      }

      .password-btns {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        .btn-disabled {
          opacity: 50%;
        }
        .btn {
          display: flex;
          height: 41px;
          width: 134px;
          padding: 7px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: var(--Dark-Gray-100, #2b3746);
          text-align: center;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          border-radius: 4px;
          border: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3));
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
        }
      }
    }

    .dashboard-email-main {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      width: 335px;

      .dashboard-email-input {
        display: flex;
        padding: 6px 12px;
        justify-content: space-between;
        height: 41px;
        border: none;
        outline: none;
        align-items: center;

        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
      }
      .dashboard-email-input:focus {
        box-shadow: none;
      }

      .dashboard-email-btns {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        flex: 1 0 0;
        margin-top: 26px;
        .btn-disabled {
          opacity: 50%;
        }
        .btn {
          display: flex;
          height: 41px;
          width: 134px;
          padding: 7px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: var(--Dark-Gray-100, #2b3746);
          text-align: center;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          border-radius: 4px;
          border: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3));
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
        }
      }
    }
  }
`;

const EnableNotification = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 41px;
  border-radius: 4px;
  border: 1px solid #d0dee1;
  background: #fff;
  margin-top: 20px;

  .label {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    align-self: unset;
  }
  .options {
    border-bottom: 1px solid rgba(208, 222, 225, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
  .ant-switch {
    min-width: 26px !important;
    /* height: 22px; */
    /* height: 16px;   */
  }
  .ant-switch.ant-switch-checked {
    background-color: #4cbbcb;
  }
  .ant-switch:hover {
    background-color: #4cbbcb !important;
  }
`;

export { Container, EnableNotification };
