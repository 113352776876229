import styled from "styled-components";

const ChatItemContainer = styled.div`
  cursor: pointer;

  .outer-div:hover {
    background: var(--Blue-20, rgba(76, 187, 203, 0.2));
    box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
    border-radius: 30px;
  }
  .outer-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 3px 10px;
    position: relative;
    align-items: center;
    justify-content: space-between;

    .unread_count {
      color: var(--White-100, #fff);
      text-align: center;
      font-family: Outfit;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      background-color: #2b3746;
      right: 2px;
      border-radius: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 12px;

      .image-div {
        height: 30px;
        width: 30px;
        border-radius: 20px;
        overflow: hidden;
        background: #4cbbcb;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        border: 1px solid #e3eced;

        .initials-avatar {
        }

        img {
          height: 35px;
          width: 35px;
          z-index: -10;
        }

        .status-div {
          position: absolute;
          bottom: 5px;
          left: 33px;
          background: green;
          height: 10px;
          width: 10px;
          border-radius: 10px;
          z-index: 100;
          padding: 1.5px;
          border: 1.5px solid white;
        }
      }

      .name {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.49px;
        width: 120px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: 0; /* Initially hidden */
      transition: opacity 0.3s ease; /* Smooth transition */

      .icon {
        cursor: pointer;
      }
    }

    &:hover .right {
      opacity: 1; /* Visible on hover */
    }
  }
`;

export { ChatItemContainer };
