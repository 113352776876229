import React, { useEffect, useState } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { Modal } from 'antd'
import GetLogo from '../../getlogo/getlogo'
import useGetData from '../../../hooks/useGetData'
import DataNotFound from '../../../common/DataNotFound'
import {
  Container,
  ProductContainer,
  MainConatiner,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledCheckboxText,
  HiddenCheckboxInput,
} from './styles'

const AddOnsModal = ({
  isModalOpen,
  handleCancel,
  handleOk,
  setSelectedAddOns,
  selectedAddOns,
  handleDelete,
  selectedServices,
  handleShowSaveBtn,
}) => {
  const { data: AddOnsData, loading, error } = useGetData('addons')
  const [addAllChecked, setAddAllChecked] = useState(false)

  useEffect(() => {
    const filteredAddOns = getFilteredAddOns(
      AddOnsData?.data || [],
      selectedServices
    )
    const allSelected =
      filteredAddOns.length > 0 &&
      filteredAddOns.every((addon) =>
        selectedAddOns.some((selectedAddon) => selectedAddon.id === addon.id)
      )
    setAddAllChecked(allSelected)
  }, [selectedAddOns, AddOnsData, selectedServices])

  const handleaddAddOn = (selectedAddon) => {
    handleShowSaveBtn()
    setSelectedAddOns((prevSelectedAddons) => {
      const alreadySelected = prevSelectedAddons.find(
        (item) => item.id === selectedAddon.id
      )
      if (alreadySelected) {
        return prevSelectedAddons.filter((item) => item.id !== selectedAddon.id)
      } else {
        return [...prevSelectedAddons, selectedAddon]
      }
    })
  }

  const handleCheckboxClick = () => {
    if (!addAllChecked) {
      const allAddOns = getFilteredAddOns(
        AddOnsData?.data || [],
        selectedServices
      )
      setSelectedAddOns(allAddOns)
    } else {
      const filteredAddOns = getFilteredAddOns(
        AddOnsData?.data || [],
        selectedServices
      )
      const remainingAddOns = selectedAddOns.filter(
        (addon) =>
          !filteredAddOns.some((filteredAddon) => filteredAddon.id === addon.id)
      )
      setSelectedAddOns(remainingAddOns)
    }
    setAddAllChecked(!addAllChecked)
  }

  const getFilteredAddOns = (addons, selectedServices) => {
    const selectedServiceIds = selectedServices.map((service) => service.id)
    return addons.filter((addon) =>
      selectedServiceIds.includes(addon.service_id)
    )
  }

  const filteredAddOns = getFilteredAddOns(
    AddOnsData?.data || [],
    selectedServices
  )

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Included Add-ons</p>
            <div className="add-all-wrapper">
              {filteredAddOns.length > 0 && (
                <StyledCheckboxContainer>
                  <HiddenCheckboxInput
                    type="checkbox"
                    checked={addAllChecked}
                    onChange={handleCheckboxClick}
                  />
                  <StyledCheckbox checked={addAllChecked} />
                  <StyledCheckboxText>Add All</StyledCheckboxText>
                </StyledCheckboxContainer>
              )}
            </div>
          </div>

          <ProductContainer>
            <div className="product-items">
              {filteredAddOns.length === 0 ? (
                <DataNotFound label={'No Included Add-ons Found!'} />
              ) : (
                filteredAddOns.map((item) => (
                  <div className="item-main" key={item.id}>
                    <div className="item">
                      <div className="labels">
                        <p className="item-title" style={{ margin: '0px' }}>
                          {item.name}
                        </p>
                        <small className="mailbox-title">
                          {item.service?.name}
                        </small>
                      </div>
                      <span
                        className="delete-icon"
                        onClick={
                          selectedAddOns.find(
                            (selectedItem) => selectedItem.id === item.id
                          )
                            ? () => handleDelete(item.id)
                            : () => handleaddAddOn(item)
                        }
                      >
                        {selectedAddOns.find(
                          (selectedItem) => selectedItem.id === item.id
                        ) ? (
                          <RxCross2 color="red" />
                        ) : (
                          <IoAddOutline />
                        )}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </ProductContainer>
        </Container>
        <div className="button-div">
          <button type="button" onClick={handleCancel}>
            Close
          </button>
        </div>
      </MainConatiner>
    </Modal>
  )
}

export default AddOnsModal
