import styled from 'styled-components'

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? 'var(--Blue-100, #4cbbcb)'
            : 'var(--Gray-5, rgba(85, 99, 115, 0.05))'};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? 'var(--White-100, #fff)'
              : 'var(--Dark-Gray-100, #2B3746)'};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? 'var(--White-100, #fff)'
                  : 'var(--Dark-Gray-100, #2B3746)'};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 7px;
  }

  .form-collapased {
    border: none;
    .panel {
      border: none;
      .ant-collapse-header {
        width: 339px;
        display: flex;
        height: 48px;
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
        opacity: 0.8;
        background: var(--Gray-2, rgba(85, 99, 115, 0.02));
        .ant-collapse-header-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: #2b3746;
            }
          }
        }
      }
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const DrowpdowmsContainer = styled.div`
  display: flex;
  padding-top: 16px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .click-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    width: 157.5px;

    .ant-space-item {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.49px;

      span {
        svg {
          width: 8px;
          height: 8px;
          flex-shrink: 0;
          stroke-width: 1.333px;
          stroke: var(--Dark-Gray-100, #2b3746);
        }
      }
    }
  }
`

const InvoiceCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #fff;
  margin-bottom:8px ;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }
      .company-name {
        color: var(--Gray-100, #556373);

        /* P1 */
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        margin: 0px;
      }
    }
  }

  .icons-and-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .invoice-amount {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .company-id {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      text-decoration-line: underline;
    }
    .active {
      margin-left: 5px;
      display: flex;
      padding: 0px 6px;
      align-items: center;
      gap: 10px;
      /* cursor: pointer; */
      border-radius: 4px;
      border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
      background: rgba(76, 203, 96, 0.2);
      .icon {
        svg {
          width: 8px;
          height: 8px;
        }
      }

      .text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
    .btns {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .amount {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      .active {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
        background: rgba(76, 203, 96, 0.2);
        .icon {
          svg {
            width: 8px;
            height: 8px;
          }
        }

        .text {
          text-transform: capitalize;
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .open {
        background-color: #ffc107;
        border: 1px solid #ffc107;
        .text {
          color: #2b3746;
        }
      }
      .pastdue {
        background-color: #dc3545;
        border: 1px solid #dc3545;
        .text {
          color: #fff;
        }
      }
      .voided {
        border: 1px solid #6c757d;
        background-color: #6c757d;
        .text {
          color: #fff;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 1px 0px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          flex-shrink: 0;

          path {
            fill: var(--Dark-Gray-100, #2b3746);
            fill-opacity: unset;
          }
        }
      }
    }
  }
`

export { Container, ContentContainer, DrowpdowmsContainer, InvoiceCard }
