import styled from "styled-components";

const SecondColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 55px;

  .header {
    display: flex;
    background: white;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3eced;
    height: 55px;
    top: 2px;
    position: absolute;
    z-index: 1000;
    padding: 0px 20px;

    .left {
      display: flex;
      align-items: center;
      gap: 15px;
      .img {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        overflow: hidden;
        background: #f3f6f7;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
      .name-div {
        display: flex;
        flex-direction: column;

        .members {
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: var(--Gray-100, #556373);
        }
      }
    }

    .right {
      .icon {
        cursor: pointer;
      }
    }
  }

  .chat-section {
    width: 100%;
    height: 100%;
  }
`;

export { SecondColumnContainer };
