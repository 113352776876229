import React, { useState, useRef, useEffect } from 'react'
import './styles'
import { IoAddOutline } from 'react-icons/io5'
import { FaPlay, FaStop, FaMusic } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import { Container, ProductContainer } from './styles'
import TemporaryVoiceMailSettingsModel from '../../modals/temporaryVoiceMailSettingsModel/TemporaryVoiceMailSettingsModel'
import { Form, Input, Select } from 'antd'
import { values } from 'lodash'

const VoiceMailSettings = ({
  id,
  selectedFile,
  setSelectedFile,
  voicemailOption,
  setVoicemailOption,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentPlayingFile, setCurrentPlayingFile] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const fileURLRef = useRef(null)
  const [option, setOption] = useState(null)

  console.log(selectedFile, 'selectedFile')
  useEffect(() => {
    const audioElement = audioRef.current

    // Event listener to reset play state when audio finishes
    const handleAudioEnded = () => {
      setIsPlaying(false)
      setCurrentPlayingFile(null)
    }

    if (audioElement) {
      audioElement.addEventListener('ended', handleAudioEnded)
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleAudioEnded)
      }
      // Clean up the object URL when component unmounts or file changes
      if (fileURLRef.current) {
        URL.revokeObjectURL(fileURLRef.current)
        fileURLRef.current = null
      }
    }
  }, [selectedFile])

  const TruncateText = ({ text }) => {
    const maxLength = 45
    let truncatedText = text?.substring(0, maxLength)
    if (truncatedText?.length < text?.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }

  // Play or Stop the audio
  const toggleAudioPlayback = () => {
    const audioElement = audioRef.current

    if (currentPlayingFile !== selectedFile) {
      setCurrentPlayingFile(selectedFile)
      setIsPlaying(true)

      if (fileURLRef.current) {
        URL.revokeObjectURL(fileURLRef.current)
      }

      // Check if selectedFile is a valid File or Blob
      if (selectedFile instanceof Blob) {
        fileURLRef.current = URL.createObjectURL(selectedFile)
        audioElement.src = fileURLRef.current
        audioElement.play()
      } else {
        console.error(
          'Invalid file format: selectedFile must be a Blob or File.'
        )
      }
    } else {
      // Toggle play/pause for the same file
      if (isPlaying) {
        audioElement.pause()
      } else {
        audioElement.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  // Delete the selected audio file
  const handleDeleteAudio = () => {
    setSelectedFile(null)
    setIsPlaying(false)
    setCurrentPlayingFile(null)

    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0 // Reset playback
    }
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current)
      fileURLRef.current = null
    }
  }

  const showModal = () => setIsModalOpen(true)
  const handleCancel = () => setIsModalOpen(false)

  // Save the selected file from the modal
  const handleSaveFile = (file) => {
    setSelectedFile(file)
    setIsModalOpen(false)
  }

  const voicemailOptions = [
    {
      label: "Use Starthub's Generic Voicemail",
      value: "Use Starthub's Generic Voicemail",
    },
    {
      label: 'Use your own file',
      value: 'Use your own file',
    },
    {
      label: 'Get a professional custom voice Recording',
      value: 'Get a professional custom voice Recording',
    },
  ]

  const handleOptionChange = (value) => {
    const intValue = parseInt(value)
    setOption(intValue)
  }

  return (
    <Container id={id} className="scroll-section">
      <div className="title-main">
        <p className="title">Voicemail Settings</p>
      </div>

      <div className="form-main">
        <div className="form">
          <Form.Item
            label="Voicemail Option"
            name="voicemail_option"
            className="form-items"
          >
            <Select
              // showSearch
              placeholder="Voicemail Option"
              className="location"
              style={{
                width: '100% !important',
              }}
              onChange={handleOptionChange}
              options={voicemailOptions}
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-main">
        <div className="form">
          <Form.Item
            label="Voicemail Script"
            name="voicemail_script"
            className="form-items"
          >
            <Input className="input" placeholder="Voicemail Script" />
          </Form.Item>
        </div>
      </div>

      <ProductContainer>
        <div className="add-products">
          <div className="product-title">
            <p className="title">Add Voicemail File</p>
          </div>
          {!selectedFile && (
            <button className="add-prduct-btn" onClick={showModal}>
              <span className="btn-icon">
                <IoAddOutline />
              </span>
              <span className="btn-text">Add</span>
            </button>
          )}
        </div>

        {selectedFile && (
          <div className="product-items">
            <div className="item-main">
              <div className="audio-file-display">
                <div className="audio-icon">
                  <FaMusic color="#4cbbcb" size={15} />
                </div>
                <div className="audio-details">
                  <span className="audio-filename">
                    <TruncateText text={selectedFile?.name} />
                  </span>
                </div>
                <div className="audio-controls">
                  {isPlaying && currentPlayingFile === selectedFile ? (
                    <FaStop
                      color="#55637366"
                      size={16}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <FaPlay
                      color="#55637366"
                      size={16}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <AiOutlineDelete
                    color="#55637366"
                    size={20}
                    onClick={handleDeleteAudio}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ProductContainer>

      <audio ref={audioRef} />

      <TemporaryVoiceMailSettingsModel
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleSaveFile={handleSaveFile}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </Container>
  )
}

export default VoiceMailSettings
