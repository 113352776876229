import styled from "styled-components";

const MainContainer = styled.div`
  position: fixed;
  left: ${(props) => (props.collapsed ? "68px" : "272px")};
  right: 0;
`;

const FixedContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: calc(100vh - 150px);
  width: 100%;

  .main-form {
    width: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    flex: 1 0 0;
  }
  .fixed::-webkit-scrollbar {
    width: 3px;
  }

  .fixed::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  .fixed::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
`;

const Banner = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  background: var(--White-80, rgba(255, 255, 255, 0.8));

  .label {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.63px;
    display: flex;
    gap: 10px;
    align-items: center;

    .back-arrow {
      display: flex;
      cursor: pointer;
      width: 26px;
      height: 26px;
      padding: 0px 4px;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }

  .btns {
    display: flex;
    height: 32px;
    width: 158px;
    align-items: flex-start;
    gap: 8px;

    .cancel-btn {
      display: flex;
      width: 77px;
      height: 32px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      outline: none;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      cursor: pointer;

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }

    .save-btn {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;

      .btn-text {
        color: var(--White-100, #fff);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }
`;

const PlanFormsContainer = styled.div`
  display: flex;
  padding-left: 40px;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
  width: 100%;
  /* margin-top: 40px; */
  background: #fff;
`;

const FormsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  flex: 1 0 0;
`;

const ProductPlansEnableAndContactContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 220px;
  align-self: stretch;
  /* border:  1px solid black; */
  height: 1909px;
`;

const ComponentsContainer = styled.div`
  display: flex;
  width: 548px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
`;
const TopBar = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  background-color: #ffffff;
`;
const BreadcrumbContainer = styled.div`
  .ant-breadcrumb ol {
    gap: 14px;
  }
  .ant-breadcrumb-link:hover {
    background: transparent;
  }
  .ant-breadcrumb a {
    display: flex;
    gap: 8px;
    align-items: center;
    svg {
      width: 11px;
      height: 11px;
    }
    span {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
export {
  Banner,
  PlanFormsContainer,
  FormsContainer,
  ProductPlansEnableAndContactContainer,
  ComponentsContainer,
  MainContainer,
  FixedContainer,
  BreadcrumbContainer,
  TopBar,
};
