import React, { createContext, useContext } from 'react'
import { message } from 'antd'

const MessageContext = createContext()

export const GlobalMessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage()

  // Helper function to simplify usage
  const showMessage = (type, content) => {
    messageApi.open({ type, content })
  }

  return (
    <MessageContext.Provider value={showMessage}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  )
}

export const useGlobalMessage = () => {
  const context = useContext(MessageContext)
  if (!context) {
    throw new Error(
      'useGlobalMessage must be used within a GlobalMessageProvider'
    )
  }
  return context
}
