import React, { useState, useEffect } from "react";
import { SecondColumnContainer } from "./styles";
import ChatSection from "./chatSection/ChatSection";
import useGetData from "../../../hooks/useGetData";
import { handleAddInternalChatSession } from "../../../redux/app/appSlice";
import { useDispatch, useSelector } from "react-redux";

const DirectChat = ({
  selectedDirectMessage,
  refetchEmployeeList,
  firstRender,
  setFirstRender,
  quotedMessage,
  setQuotedMessage,
}) => {
  const {
    data: directMessage,
    directMessageError,
    refetchData: refetchDirectMessage,
    loading: loadingDirectMessage,
  } = useGetData(`internal-chat/sessions/${selectedDirectMessage?.id}`);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (directMessage) {
      refetchEmployeeList();
    }
  }, [directMessage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchDirectMessage();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [refetchDirectMessage]);

  const handleAddSession = () => {
    if (selectedDirectMessage) {
      const isSessionSelected = app?.internalChatsSpaces?.some(
        (space) => space.id === selectedDirectMessage.id
      );
      if (!isSessionSelected) {
        dispatch(handleAddInternalChatSession(selectedDirectMessage));
      }
    }
  };

  return (
    <SecondColumnContainer>
      <div className="chat-section">
        <ChatSection
          directMessage={directMessage}
          loadingDirectMessage={loadingDirectMessage}
          refetchDirectMessage={refetchDirectMessage}
          selectedDirectMessage={selectedDirectMessage}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          quotedMessage={quotedMessage}
          setQuotedMessage={setQuotedMessage}
          handleAddSession={handleAddSession}
        />
      </div>
    </SecondColumnContainer>
  );
};

export default DirectChat;
