import styled from 'styled-components'

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? 'var(--Blue-100, #4cbbcb)'
            : 'var(--Gray-5, rgba(85, 99, 115, 0.05))'};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? 'var(--White-100, #fff)'
              : 'var(--Dark-Gray-100, #2B3746)'};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? 'var(--White-100, #fff)'
                  : 'var(--Dark-Gray-100, #2B3746)'};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }

  .form-collapased {
    border: none;
    .form-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      /* padding: 24px; */
      background: white;
      .full-width-dropdown .ant-select-dropdown {
        width: 100% !important;
      }
      .multi-select {
        padding: 12px;
        width: 100%;
        height: 41px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        overflow: hidden;

        .ant-select-selector {
          border: none;
          outline: none;
          box-shadow: none;
        }

        .ant-select-selection-item {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
      .form-tittle {
        display: flex;
        padding: 5px 0px;
        align-items: center;
        gap: 40px;
        align-self: stretch;
        /* max-width: 626px; */
        width: 100%;
        .title {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.63px;
          width: 100%;
        }
        .save-btn {
          display: flex;
          cursor: pointer;
          padding: 7px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid var(--Blue-30, rgba(76, 187, 203, 0.3));
          border-radius: 3px;
          background: var(--Blue-100, #4cbbcb);
          color: var(--White-100, #fff);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          margin-top: 10px;
        }
      }

      .add-user-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        align-self: stretch;
        .ant-form-item-control,
        .input-label {
          max-width: 626px;
          width: 100%;
          ::placeholder {
            color: var(--Gray-40, rgba(85, 99, 115, 0.4)) !important;
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
        .ant-form-item-margin-offset {
          margin-bottom: 0px !important;
        }

        .ant-form-item-explain-error {
          font-size: 10px;
          margin-top: 0px !important;
        }

        .card-details {
          display: flex;
          gap: 16px;
          max-width: 626px;
          width: 100%;
          .input-label {
            max-width: 325px;
            width: 100%;
            .ant-picker {
              max-width: 325px;
              width: 100%;
              flex-grow: 1;
              display: flex;
              padding: 12px;
              justify-content: space-between;
              border: none;
              outline: none;
              align-items: center;
              align-self: stretch;
              border-radius: 4px;
              border: 1px solid #d0dee1;
              background: #fff;
              height: 41px;
            }
          }
          label {
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: auto;
            /* line-height: 14.4px; */
          }

          .card-username-input {
            flex-grow: 1;
            display: flex;
            padding: 12px;
            justify-content: space-between;
            border: none;
            outline: none;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid #d0dee1;
            background: #fff;
            height: 41px;
            max-width: 325px;
            width: 100%;
          }
          label {
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: auto;
            /* line-height: 14.4px; */
          }
        }
        .ant-form-item-row {
          display: flex !important;
          flex-direction: column !important;
          align-items: flex-start;
          gap: 4px;

          align-self: stretch;
          margin-bottom: 0;

          label {
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: auto;
            /* line-height: 14.4px; */
          }

          .username-input {
            display: flex;
            padding: 12px;
            justify-content: space-between;

            border: none;
            outline: none;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid #d0dee1;
            background: #fff;
            height: 41px;
          }

          .ant-select-selector {
            border: none;
            padding: 0;
            outline: none;
          }

          .ant-select:not(.ant-select-disabled):not(
              .ant-select-customize-input
            ):not(.ant-pagination-size-changer):hover
            .ant-select-selector {
            border-color: transparent !important;
            border: none !important;
            outline: none !important;
            box-shadow: none;
          }

          .ant-select-selection-item {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .ant-form-item-explain-error {
            font-size: 10px;
            margin-top: 12px;
          }
          .ant-select-selection-item:active {
            background-color: transparent;
            box-shadow: none;
          }
          .username-input::placeholder {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .username-input:focus::placeholder {
            color: transparent;
          }
          .username-input:focus {
            box-shadow: none;
          }

          label::after {
            content: '';
          }
        }
      }
    }
    .panel {
      border: none;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding-right: 16px;
        padding-left: 0px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        background-color: white;
        border-bottom: 1px dotted var(--Gray-20, rgba(85, 99, 115, 0.2));
        border-radius: unset;
        .ant-collapse-header-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: black;
            }
          }
        }
      }
    }
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
`

const AllPaymentMethodsContainer = styled.div`
  display: flex;
  padding: 24px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const ActivePaymentMethodsContainer = styled.div`
  display: flex;
  padding: 24px 2px 16px 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d0dee1;
  background-color: ${(props) => (props.isActive ? '#f7fafb' : '#ffffff')};
  .company {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .content {
      display: flex;
      width: 304px;
      align-items: center;
      justify-content: space-between;

      .company-data {
        display: flex;
        align-items: center;
        gap: 10px;

        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          flex-shrink: 0;
          background-color: #fff;
          border-radius: 5px;
        }
        .dates {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;

          .ending-year {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 16.8px */
            margin: 0px;
            padding: 0px;
          }

          .ending-date {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }

      .icons {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 12px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;
          cursor: pointer;

          svg path {
            fill: #2b3746;
          }
          /* 
          svg:hover {
            fill: #4CBBCB;
            fill-opacity: unset;
          } */
        }
        .edit-icon {
          display: flex;
          order: 20;
          padding: 8px;
          align-items: center;
          gap: 12px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          background: #fff;

          svg path {
            fill: ${(prop) => (prop.isActive ? '#4CBBCB' : '#2b3746')};
          }
          /* 
          svg:hover {
            fill: #4CBBCB;
            fill-opacity: unset;
          } */
        }
      }
    }
  }
`

export {
  Container,
  AllPaymentMethodsContainer,
  ActivePaymentMethodsContainer,
  ContentContainer,
  Card,
}
