import React, { useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import GetLogo from '../../../../getlogo/getlogo'
import DataNotFound from '../../../../../common/DataNotFound'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CompanyCards, Container, ContentContainer } from './styles'
import './styles'
import useGetData from '../../../../../hooks/useGetData'
import { handSelectCompanyFlowTab } from '../../../../../redux/app/appSlice'

const { Panel } = Collapse

const Support = ({ userID }) => {
  const [collopasedKey, setCollopasedKey] = useState(false)
  const [showAllTickets, setShowAllTickets] = useState(false) // State to toggle between open and all tickets
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    data: tickets,
    error: ticketsError,
    loading,
  } = useGetData(
    `support/tickets?order_by=created_at&order=DESC&company_id=${userID}`
  )

  const TruncateText = ({ text }) => {
    const maxLength = 50
    let truncatedText = text?.substring(0, maxLength)
    if (truncatedText?.length < text?.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }

  const handleRedirect = () => {
    dispatch(handSelectCompanyFlowTab('10'))
    navigate(`/companies/${userID}`)
  }

  const handleShowMore = () => {
    setShowAllTickets(true) // Show all tickets (open + closed)
  }

  const hasOpenTickets = tickets?.data?.some(
    (ticket) => ticket.status !== 'Closed'
  )

  // Filter tickets based on the condition
  const filteredTickets = showAllTickets
    ? tickets?.data?.slice().sort((a, b) => {
        if (a.status === 'Open' && b.status !== 'Open') return -1 // Place "Open" tickets first
        if (a.status !== 'Open' && b.status === 'Open') return 1 // Place non-"Open" tickets later
        return 0 // Keep the order for tickets with the same status
      })
    : tickets?.data?.filter((ticket) => {
        // If there are no open tickets, include closed tickets
        if (!hasOpenTickets) return true
        // Otherwise, only show open tickets
        return ticket.status !== 'Closed'
      })

  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Support" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="tickets-container">
                <div
                  className="tickets"
                  style={{
                    width: '100%',
                  }}
                >
                  {filteredTickets?.map((ticket, index) => (
                    <div key={index} className="ticket">
                      <span className="name">{ticket?.name}</span>
                      <span className="des">
                        <TruncateText text={ticket?.description} />
                      </span>
                      <div className="make-row">
                        <div className="left">
                          <span className="priority-label">Priority: </span>
                          <span className="priority">{ticket?.priority}</span>
                        </div>
                        <div className="right">
                          {ticket?.is_closed ? (
                            <div className="in-active">
                              <span
                                className="text"
                                onClick={() =>
                                  navigate(`/tickets/${ticket?.id}`)
                                }
                              >
                                Closed
                              </span>
                            </div>
                          ) : (
                            <div className="active">
                              <span
                                className="text"
                                onClick={() =>
                                  navigate(`/tickets/${ticket?.id}`)
                                }
                              >
                                Open
                              </span>
                            </div>
                          )}
                          <div className="view-btn">
                            <span
                              className="text"
                              onClick={() => navigate(`/tickets/${ticket?.id}`)}
                            >
                              View
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {tickets?.data?.length === 0 && !loading ? (
              <DataNotFound label={'No Task and Ticket Found'} />
            ) : null}
            {!loading && (
              <div className="view-all-container">
                {!showAllTickets &&
                  tickets?.data?.filter((item) => item?.status !== 'Closed')
                    .length > 0 && (
                    <button className="show-more" onClick={handleShowMore}>
                      Show More
                    </button>
                  )}
                {tickets?.data?.length > 0 && (
                  <button className="view-all" onClick={handleRedirect}>
                    View All Tickets
                  </button>
                )}
              </div>
            )}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}

export default Support
