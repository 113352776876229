import styled from "styled-components";

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 41px;
  padding: 5px;
  border: 1px solid #4cbbcb;
  background-color: transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  /* Change border color when the input is focused */
  ${
    "" /* &:focus-within {
    border: 1px solid #4cbbcb;
  } */
  }

  .input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px;
    background-color: transparent;

    ::placeholder {
      color: black; 
      font-style: normal;
    }
  }
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  color: #4cbbcb;
  display: flex;
  align-items: center;
`;

export { SearchBarContainer, SearchButton };
