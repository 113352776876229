// src/features/counterSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Axios } from 'axios'
import { ReactTostify } from '../../common/ReactTostify'
import { InstanceWithAuth } from '../../App'
import { Avatar } from 'antd'

const initialState = {
  userObj: {
    Avatar: 'test.img',
    name: '',
    id: '',
  },
  isLogin: false,
  isLoading: false,
  isError: false,
  errorMsg: '',
  isPasswordChanged: true,
}
export const handleloginUser = createAsyncThunk(
  'loginUser',
  async (fromData) => {
    try {
      const response = InstanceWithAuth.post('login', fromData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return response
    } catch (error) {
      throw new Error('some thing went wrong')
    }
  }
)

export const authSlice = createSlice({
  name: 'UserAuth',
  initialState,
  reducers: {
    handleLogoutUser: (state) => {
      state.userObj = null
      state.isLogin = false
      state.isLoading = false
    },
    handleLogin: (state, action) => {
      state.userObj = action.payload
      state.isLogin = true
    },
    handleAvatar: (state, action) => {
      state.userObj = {
        ...state.userObj,
        avatar: action.payload,
      }
    },
    handleUpdateUserName: (state, action) => {
      const { first_name, last_name } = action.payload
      state.userObj = {
        ...state.userObj,
        first_name,
        last_name,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleloginUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(handleloginUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = false
        state.userObj = action.payload.data
        state.isLogin = true
      })
      .addCase(handleloginUser.rejected, (state, action) => {
        state.isError = true
        state.errorMsg = action.error.message
        state.isLoading = false
      })
  },
})

export const { handleLogoutUser, handleLogin, handleAvatar, handleUpdateUserName } =
  authSlice.actions

export default authSlice.reducer
