import React, { useState } from "react";
import { Container } from "./styles";
import "./styles";
import TicketBoardTypeDropDown from "../ticketBoardType/TicketBoardTypeDropDown";
import BoardCustomDropDown from "../boardCustomDropDown/BoardCustomDropDown";
import useGetData from "../../../hooks/useGetData";
import LocationDateDropDwon from "../../loactionFilters/locationDateDropDwon/LocationDateDropDwon";
import { DatePicker, Drawer, Form, Select } from "antd";
import { FaFilter } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { CloseOutlined } from "@ant-design/icons";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import AdvancedTicketFilters from "../advancedTicketFilters/AdvancedTicketFilters";
import TicketType from "../ticketType/TicketType";

const TicketCenterFilters = ({
  selectedPipeline,
  setSelectedPipeline,
  selectedOwner,
  setSelectedOwner,
  selectedPriority,
  setSelectedPriority,
  selectedCreatedDate,
  setSelectedCreatedDate,
  app,
  setSelectedOption,
  setEndDate,
  setStartDate,
  setSelectedLocation,
  selectedLocation,
  setAdvancedFilters,
  setLoadingFilteredTicktes,
  type,
  setType,
}) => {
  const { auth } = useSelector((state) => state);
  const { data: allUserList, refetchData: refecthUserList } =
    useGetData("users");
  const [startDateToDisabele, setStartDateToDisabele] = useState(null);
  const [openContactDrawer, setOpenContactDrawer] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  const handleStartDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;
    setStartDateToDisabele(date);
    setStartDate(formattedDate);
  };
  const { data: locationData } = useGetData("locations/dropdown/get");
  const handleEndDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
    setSelectedOption();
  };

  const showDrawerContactDrawer = () => {
    setOpenContactDrawer(true);
  };
  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const disabledEndDate = (current) => {
    return (
      current &&
      startDateToDisabele &&
      current.valueOf() < startDateToDisabele.valueOf()
    );
  };

  return (
    <Container>
      <div className="make-row">
        <TicketType type={type} setType={setType} app={app}></TicketType>
        <TicketBoardTypeDropDown
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          app={app}
          type={type}
        ></TicketBoardTypeDropDown>
      </div>

      <div className="filter">
        <div className="filter-btn" onClick={showDrawerContactDrawer}>
          <span
            style={{
              color: "var(--Gray-60, rgba(85, 99, 115, 0.6))",
            }}
          >
            Advanced Filters
          </span>
          <IoFilter
            style={{
              marginLeft: "8px",
              color: "var(--Gray-60, rgba(85, 99, 115, 0.6))",
            }}
            size={12}
          />
        </div>

        <BoardCustomDropDown
          selectedStatusOption={selectedLocation}
          setSelectedStatusOption={setSelectedLocation}
          dropDownTitle="Location"
          filterData={locationData?.data}
        ></BoardCustomDropDown>
        <BoardCustomDropDown
          selectedStatusOption={selectedOwner}
          setSelectedStatusOption={setSelectedOwner}
          dropDownTitle="Ticket Owner"
          filterData={allUserList?.data}
        ></BoardCustomDropDown>
        <BoardCustomDropDown
          selectedStatusOption={selectedCreatedDate}
          setSelectedStatusOption={setSelectedCreatedDate}
          filterData={[
            {
              name: "is",
            },
            {
              name: "between",
            },
            {
              name: "after",
            },
            {
              name: "before",
            },
          ]}
          dropDownTitle="Create Date"
        ></BoardCustomDropDown>
        {selectedCreatedDate?.name == "between" ? (
          <DatePicker
            placeholder="Start Date"
            className="datePicker"
            onChange={handleStartDateChange}
            style={{ height: "27px" }}
          />
        ) : null}
        {selectedCreatedDate != "" && selectedCreatedDate?.name != "all" ? (
          <DatePicker
            placeholder={selectedCreatedDate == "between" ? "End Date" : "Date"}
            className="datePicker dropdown-header"
            onChange={handleEndDateChange}
            disabledDate={disabledEndDate}
            style={{ height: "27px" }}
          />
        ) : null}
        <BoardCustomDropDown
          selectedStatusOption={selectedPriority}
          setSelectedStatusOption={setSelectedPriority}
          filterData={[
            {
              name: "Low",
            },
            {
              name: "Medium",
            },
            {
              name: "High",
            },
            {
              name: "Very High",
            },
          ]}
          dropDownTitle="Priority"
        ></BoardCustomDropDown>
      </div>

      <Drawer
        mask={false}
        className="drawer"
        width={640}
        padding={0}
        paddingLG={0}
        paddingXS={0}
        onClose={onCloseContactDrawer}
        open={openContactDrawer}
        headerStyle={{
          backgroundColor: "#4cbbcb",
          color: "#fff",
        }}
        style={{
          padding: "0px",
          margin: "0px",
        }}
        closeIcon={
          <CloseOutlined style={{ color: "white", fontSize: "bold" }} />
        }
        title="All Filters"
      >
        <AdvancedTicketFilters
          setAdvancedFilters={setAdvancedFilters}
          setLoadingFilteredTicktes={setLoadingFilteredTicktes}
        />
      </Drawer>
    </Container>
  );
};

export default TicketCenterFilters;
