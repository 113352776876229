import styled from "styled-components";

const SupportSearchContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  .first-column {
    height: 100%;
    flex: 2.5;
    padding-top: 40px;
    border-right: 1px solid #e3eced;
  }
  .columns-wrapper {
    height: 100%;
    flex: 10.5;
    display: flex;
    flex-direction: row;
    position: relative;

    .searchBar {
      position: absolute;
      top: 42px;
      right: 0;
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #e3eced;
      background: white;
      z-index: 2;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .second-column {
      height: 100%;
      flex: 2.4;
      padding-top: 40px;
      box-sizing: border-box;
      border-right: 1px solid #e3eced;
    }
    .third-column {
      height: 100%;
      flex: 8;
      padding-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .text {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1px;
      }
    }

    .third-column-1 {
      height: 100%;
      flex: 8;
      padding-top: 90px;
      display: flex;
      flex-direction: row;

      .fourth-column {
        height: 100%;
        padding-top: 50px;
        min-width: 375px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;

export { SupportSearchContainer };
