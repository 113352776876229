import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;

    .name {
      font-size: 14px;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.49px;
    }
    .icon {
      cursor: pointer;
    }
  }

  .heading {
    font-size: 16px;
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    ${"" /* letter-spacing: -0.49px; */}
  }

  .error-total {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #fe6a7c;
    border: 1px solid #fe6a7c;
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px 10px;
    background: #ffecef;
  }

  .not-filter-text-wrapper {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .not-filter-text {
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
      text-align: center;
      width: 100%;
    }
  }

  .outer-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${"" /* width: 100%; */}
    border: 1px solid #d0dee1;
    border-radius: 5px;
    margin-top: 10px;

    gap: 10px;
    cursor: pointer;

    .text {
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--Gray-100, #556373);
      padding: 5px;

      .name {
        font-size: 15px;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .error {
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #fe6a7c;
    }

    .headerr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #d0dee1;
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
      padding: 5px 0px;
      .name {
        font-size: 14px;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.49px;
        padding-left: 10px;
      }
      .icon {
        cursor: pointer;
        padding-right: 10px;
      }
    }
  }

  .outer-div-btn {
    display: flex;
    max-width: 110px;
    flex-direction: column;
    border: 1px solid #d0dee1;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px;
    gap: 10px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d0dee1;

      .name {
        font-size: 14px;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.49px;
      }
    }

    .btn {
      cursor: pointer;
      display: flex;
      padding: 6px 12px;
      gap: 10px;
      align-items: center;
      border-radius: 5px;
      border: 1px solid var(--Blue-100, #4cbbcb);
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
      color: var(--Blue-100, #4cbbcb);
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: var(--Blue-100, #4cbbcb);
    }
  }

  .sprtor {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .sprtor-line {
      height: 1px;
      background: #d0dee1;
      width: 115px;
    }

    .sprtor-text {
      font-size: 14px;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.49px;
    }
  }
`;

export { Container };
