import React, { useState, useEffect, useRef } from "react";
import { SearchBarContainer, SearchButton } from "./styles";
import { IoChevronBackSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <SearchBarContainer>
      <input
        ref={inputRef}
        className="input"
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Search Customer Support"
      />
      <SearchButton>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width="20"
          height="20"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-4.35-4.35m2.4-6.4a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
          />
        </svg>
      </SearchButton>
    </SearchBarContainer>
  );
};

export default SearchBar;
