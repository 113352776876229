import styled from 'styled-components'

const Container = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .title-main {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 528px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
`

const ProductContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  /* border: 1px solid #d0dee1; */
  /* background: #f3f6f7; */
  max-width: 532px;
  width: 100%;
  /* height: 380px; */

  .form {
    display: flex;
    /* padding-top: 20px; */
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    align-self: stretch;
    /* border-top: 1px solid rgba(208, 222, 225, 0.32); */
    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
    .ant-form-item {
      height: 61px;
      margin-bottom: unset;
    }

    .ant-form-item-row {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 4px;

      align-self: stretch;
      margin-bottom: 0;

      label {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: auto;
        /* line-height: 14.4px; */
      }

      .username-input {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 532px;
        /* width: 100%; */
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 41px;
      }
      .form-select:hover {
        /* border: 1px solid #d0dee1; */
        /* border: none; */
      }
      .half-input {
        border: 1px solid #d0dee1;
        border-radius: 4px;

        /* padding: 12px; */
        background: #fff;
      }

      .ant-select-selector {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        width: 528px;
        /* width: 100%; */
        border: none;
        outline: none;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        height: 38px;
      }

      .username-input::placeholder {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .half-input::placeholder {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .ant-select-single {
        height: 41px;
      }
      .username-input:focus::placeholder {
        color: transparent;
      }
      .username-input:focus {
        box-shadow: none;
      }

      label::after {
        content: '';
      }
    }
    .countary-state {
    }
  }
`

const MainConatiner = styled.div`
  padding: 40px 24px 40px 24px;
  .button-div {
    display: flex;
    justify-content: end;
    margin-right: 15px;
    margin-top: 9px;
    gap: 15px;
    width: 100%;
    padding-bottom: 50px;
    button {
      display: flex;
      height: 32px;
      min-width: 73px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .close-btn {
      background-color: #fff;
      color: gray;
      border: 1px solid gray;
    }
  }
`

export { Container, ProductContainer, MainConatiner }
