import React, { useState, useEffect } from "react";
import { MainContainer } from "./styles";
import { IoSearch } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import { Select, Avatar } from "antd";

const { Option } = Select;

const AddedSpaceMembers = ({
  spaceMembers,
  refetchSpaceMembers,
  selectedSpace,
  newCreatedSpace,
  setNewCreatedSpace,
}) => {
  const { auth } = useSelector((state) => state);
  const [deletingMemberId, setDeletingMemberId] = useState(null); // Track the specific member being deleted
  const [selectedMembers, setSelectedMembers] = useState([]); // Holds the selected user IDs
  const [users, setUsers] = useState([]); // List of users to select from
  const [loading, setLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false); // Track adding state

  const removeMember = async (member) => {
    try {
      setDeletingMemberId(member.id); // Track which member is being deleted
      const response = await InstanceWithAuth.get(
        `internal-chat/spaces/${member?.space_id}/members/${member?.id}/remove`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        refetchSpaceMembers();
      }
    } catch (error) {
      console.error("Failed to remove member", error);
    } finally {
      setDeletingMemberId(null); // Reset after deletion
    }
  };

  // Handler for member selection change
  const handleMemberSelect = (value) => {
    setSelectedMembers(value); // Update state with selected member IDs
  };

  // Fetch user list for selection
  const getUserList = async () => {
    try {
      setLoading(true);
      const response = await InstanceWithAuth.get(`users`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error("Failed to fetch users", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  // Add selected members to space
  const addMembers = async () => {
    if (selectedMembers.length === 0) {
      return;
    }

    setIsAdding(true);

    try {
      const formData = new FormData();
      formData.append("user_ids", JSON.stringify(selectedMembers));
      formData.append("status", "1");

      const response = await InstanceWithAuth.post(
        `internal-chat/spaces/${
          newCreatedSpace ? newCreatedSpace?.id : selectedSpace?.id
        }/members`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        refetchSpaceMembers();
        setSelectedMembers([]);
        setNewCreatedSpace(null);
      }
    } catch (error) {
      console.error("Failed to add members", error);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <MainContainer>
      <span className="members-title">Members</span>
      <span className="members-number">
        {spaceMembers?.data?.length} members
      </span>
      <div className="search-container">
        <div className="search-div">
          <IoSearch />
          {/* Ant Design Multi Select */}
          <Select
            mode="multiple"
            allowClear
            showSearch // Enable search
            placeholder="Search members"
            style={{ width: "100%" }}
            onChange={handleMemberSelect}
            value={selectedMembers}
            loading={loading}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            } // Customize search behavior
          >
            {users.map((user) => (
              <Option
                key={user.id}
                value={user.id}
                label={`${user.first_name} ${user.last_name}`}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={user.avatar || "https://via.placeholder.com/40"}
                    alt={user.first_name}
                    size="small"
                    style={{ marginRight: 8 }}
                  />
                  {user.first_name} {user.last_name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <div className="add-btn" onClick={addMembers}>
          {" "}
          {/* Trigger addMembers on click */}
          <FaPlus color="white" />
          <span>{isAdding ? "Adding..." : "Add"}</span>{" "}
          {/* Change text based on state */}
        </div>
      </div>

      {/* Here is the table */}
      <div className="table-container">
        {spaceMembers?.data?.length > 0 && (
          <div className="table-header">
            <div className="table-header-row">
              <div className="table-cell header-cell">Name</div>
              <div className="table-cell header-cell">Email</div>
              <div className="table-cell header-cell">Role</div>
              <div className="table-cell header-cell">Actions</div>
            </div>
          </div>
        )}

        <div className="table-body">
          {spaceMembers?.data?.map((member) => (
            <div className="table-row" key={member?.id}>
              <div className="table-cell">{member?.member_name}</div>
              <div className="table-cell">{member?.member_email}</div>
              <div className="table-cell">{member?.role}</div>

              <div className="table-cell">
                {member?.role !== "Admin" && (
                  <button
                    onClick={() => removeMember(member)}
                    className="remove-btn"
                  >
                    {deletingMemberId === member.id ? (
                      "Deleting..."
                    ) : (
                      <MdOutlineDelete />
                    )}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainContainer>
  );
};

export default AddedSpaceMembers;
