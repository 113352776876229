import React, { useState } from 'react'
import './styles'
import { Container } from './styles'
import { Form, Input, Select } from 'antd'
import useGetData from '../../../hooks/useGetData'

const DetailForm = ({
  id,
  status,
  setStatus,
  preferredAreaCode,
  setPreferredAreaCode,
  companyId,
}) => {
  const [value, setValue] = React.useState(['Miami'])

  const {
    data: areaCodes,
    loading: areaCodesLoading,
    error: areaCodesErro,
  } = useGetData(`area-codes`)

  const { data: phoneNumberData, loading: phoneNumberLoading } = useGetData(
    `live-answering/phone-numbers?company_id=${companyId}`
  )

  const areaCodeOptions =
    areaCodes?.data?.map((item) => ({
      label: item?.area_code,
      value: item?.area_code,
    })) || []

  const options = [
    {
      label: 'Active',
      value: '1',
    },
    {
      label: 'Inactive',
      value: '2',
    },
  ]

  const handleStatusChange = (value) => {
    const intValue = parseInt(value)
    setStatus(intValue)
  }

  return (
    <Container id={id} className="scroll-section">
      <div className="title">General</div>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Virtual Phone Number"
              name="virtual_phone_number"
              className="form-items"
            >
              <Select
                disabled={phoneNumberLoading}
                showSearch
                placeholder="Select a phone number"
                className="location"
                style={{
                  width: '100% !important',
                }}
                options={
                  phoneNumberData?.data?.map((item) => ({
                    label: item?.virtual_phone_number,
                    value: item?.id,
                  })) || []
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item label="Status" name="status" className="form-items">
              <Select
                showSearch
                placeholder="Status"
                className="location"
                style={{
                  width: '100% !important',
                }}
                onChange={handleStatusChange}
                options={[
                  { label: 'Active', value: 1 },
                  { label: 'Inactive', value: 0 },
                  { label: 'Pending', value: 2 },
                ]}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Greeting Company Name"
              name="greeting_company_name"
              className="form-items"
            >
              <Input className="input" placeholder="Greeting Company Name" />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Notification Email"
              name="notification_email"
              className="form-items"
            >
              <Input className="input" placeholder="Notification Email" />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Short Business Description"
              name="short_business_description"
              className="form-items"
            >
              <Input
                className="input"
                placeholder="Short Business Description"
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Special Instructions"
              name="special_instructions"
              className="form-items"
            >
              <Input className="input" placeholder="Special Instructions" />
            </Form.Item>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Area Code"
              name="area_code"
              className="form-items"
            >
              <Select
                disabled={areaCodesLoading}
                showSearch
                placeholder="Area code"
                className="location"
                value={preferredAreaCode}
                style={{
                  width: '100% !important',
                }}
                onChange={setPreferredAreaCode}
                // onChange={handleSelectChange}
                options={areaCodeOptions}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DetailForm
