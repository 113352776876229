import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import {
  MdOutlinePersonAddAlt1,
  MdInfoOutline,
  MdBlock,
  MdExitToApp,
  MdDeleteOutline,
} from "react-icons/md"; // Import relevant icons
import { BsPersonGear } from "react-icons/bs";
import AddedSpaceMembers from "./addedSpaceMembers/AddedSpaceMembers";
import InvitedSpaceMembers from "./invitedSpaceMembers/InvitedSpaceMembers";

const ManageMembers = ({
  spaceMembers,
  refetchSpaceMembers,
  selectedSpace,
  newCreatedSpace,
  setNewCreatedSpace,
}) => {
  return (
    <MainContainer>
      <div className="right">
        <AddedSpaceMembers
          spaceMembers={spaceMembers}
          refetchSpaceMembers={refetchSpaceMembers}
          selectedSpace={selectedSpace}
          newCreatedSpace={newCreatedSpace}
          setNewCreatedSpace={setNewCreatedSpace}
        />
      </div>
    </MainContainer>
  );
};

export default ManageMembers;
