import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  .main-div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    .chat-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 0px;
      overflow-y: auto !important;

      .skelton-wrapper {
        width: 100%;
      }
    }

    .chat-input {
      width: 100%;
      margin-top: 0px;
      justify-content: center;
      display: flex;
      padding: 10px;
      margin-bottom: 40px !important;
    }
  }
`;

export { MainContainer };
