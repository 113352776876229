import React, { useState } from "react";
import { FirstColumnContainer } from "./styles";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import ChatItem from "./chatItem/ChatItem";
import { CiSquarePlus } from "react-icons/ci";
import CreateSpace from "./createSpace/CreateSpace";
import DirectMessageItem from "./directMessageItem/DirectMessageItem";
import { Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddInternalChatSession,
  handleAddInternalChatSpace,
} from "../../../redux/app/appSlice";

const FirstColumn = ({
  spaces,
  setSelectedSpace,
  refetchSpaces,
  EmployeeList,
  setSelectedDirectMessage,
  setQuotedMessage,
  selectedDirectMessage,
  selectedSpace,
  firstRender,
  setFirstRender,
  setManageMembersClick,
  setNewCreatedSpace,
  setOpenThread,
  setMainDivWidth,
  setThreadQuotedMessage,
}) => {
  const [directMessageOpen, setDepartmentMessageOpen] = useState(true);
  const [spacesOpen, setSpacesOpen] = useState(true);
  const [isCreateSpaceModelOpen, setCreateSpaceModelOpen] = useState(false);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleOk = () => {
    setCreateSpaceModelOpen(false);
  };
  const handleCancel = () => {
    setCreateSpaceModelOpen(false);
  };

  const showModal = () => {
    setCreateSpaceModelOpen(true);
  };

  const handleAddSession = (employee) => {
    if (employee) {
      const isSessionSelected = app?.internalChatsSpaces?.some(
        (space) => space.id === employee.id
      );
      if (!isSessionSelected) {
        dispatch(handleAddInternalChatSession(employee));
      }
    }
  };

  const handleAddSpace = (space) => {
    if (space) {
      const isSpaceSelected = app?.internalChatsSpaces?.some(
        (space) => space.id === space.id
      );
      if (!isSpaceSelected) {
        dispatch(handleAddInternalChatSpace(space));
      }
    }
  };

  return (
    <FirstColumnContainer>
      {EmployeeList?.data && spaces ? (
        <div className="bottom-section">
          <div
            style={{ flex: directMessageOpen && "1" }}
            className="most-outer-div"
          >
            <div
              className="header-div"
              onClick={() => setDepartmentMessageOpen(!directMessageOpen)}
            >
              <span className="icon">
                {directMessageOpen ? (
                  <IoMdArrowDropdown size={16} />
                ) : (
                  <IoMdArrowDropright size={16} />
                )}
              </span>
              <span className="header-label">Direct messages</span>
            </div>
            <div className="list-container">
              {directMessageOpen &&
                EmployeeList?.data?.map((employee) => (
                  <div
                    key={employee.id}
                    onClick={() => {
                      if (!firstRender) {
                        setFirstRender(true);
                      }
                      setSelectedDirectMessage(employee);
                      setSelectedSpace(null);
                      setQuotedMessage(null);
                      setOpenThread(null);
                      setMainDivWidth(100);
                      setThreadQuotedMessage(null);
                    }}
                  >
                    <DirectMessageItem
                      employee={employee}
                      selectedDirectMessage={selectedDirectMessage}
                      handleAddSession={handleAddSession}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div
            className="most-outer-div-spaces"
            style={{ flex: spacesOpen && "1" }}
          >
            <div className="header-div">
              <div className="left" onClick={() => setSpacesOpen(!spacesOpen)}>
                <span className="icon">
                  {spacesOpen ? (
                    <IoMdArrowDropdown size={16} />
                  ) : (
                    <IoMdArrowDropright size={16} />
                  )}
                </span>
                <span className="header-label">Spaces</span>
              </div>
              <span className="icon" onClick={showModal}>
                <CiSquarePlus size={20} color="#4cbbcb" />
              </span>
            </div>
            <div className="list-container">
              {spacesOpen &&
                spaces?.map((space) => (
                  <div
                    onClick={() => {
                      if (!firstRender) {
                        setFirstRender(true);
                      }
                      setSelectedSpace(space);
                      setSelectedDirectMessage(null);
                      setQuotedMessage(null);
                      setOpenThread(null);
                      setMainDivWidth(100);
                      setThreadQuotedMessage(null);
                    }}
                    key={space.id}
                  >
                    <ChatItem
                      space={space}
                      selectedSpace={selectedSpace}
                      handleAddSpace={handleAddSpace}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton active rows={15} />
      )}

      <CreateSpace
        isCreateSpaceModelOpen={isCreateSpaceModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        refetchSpaces={refetchSpaces}
        setManageMembersClick={setManageMembersClick}
        setNewCreatedSpace={setNewCreatedSpace}
        setSelectedSpace={setSelectedSpace}
      />
    </FirstColumnContainer>
  );
};

export default FirstColumn;
