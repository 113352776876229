import React, { useState } from 'react'
import {
  AllTabsContainer,
  TabContainer,
  TabHeader,
  TabContent,
  ArrowIcon,
} from './styles'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import AboutThisTicket from './aboutThisTicket/AboutThisTicket'
import { LuExternalLink } from 'react-icons/lu'
import { useNavigate } from 'react-router-dom'

const AllTabs = ({
  ticket,
  refetchData,
  CallingFromTicketCenter,
  refetchTikcedBoardData,
  setOpenTicketDrawer
}) => {
  const [activeTabs, setActiveTabs] = useState([0])
  const navigate = useNavigate()
  const toggleTab = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index))
    } else {
      setActiveTabs([...activeTabs, index])
    }
  }

  return (
    <AllTabsContainer>
      {tabs.map((tab, index) => (
        <TabContainer key={index}>
          <TabHeader
            onClick={() => toggleTab(index)}
            isActive={activeTabs.includes(index)}
          >
            <ArrowIcon isActive={activeTabs.includes(index)}>
              {activeTabs.includes(index) ? (
                <FiChevronDown />
              ) : (
                <FiChevronRight />
              )}
            </ArrowIcon>
            {tab.title}
          </TabHeader>
          {activeTabs.includes(index) && (
            <TabContent>
              <AboutThisTicket
                ticket={ticket}
                refetchData={refetchData}
                refetchTikcedBoardData={refetchTikcedBoardData}
                setOpenTicketDrawer={setOpenTicketDrawer}
                CallingFromTicketCenter={CallingFromTicketCenter}
              />
            </TabContent>
          )}
        </TabContainer>
      ))}
    </AllTabsContainer>
  )
}

const tabs = [
  {
    title: 'About this ticket',
    component: <div>Dunning and recovery component content...</div>,
  },
]

export default AllTabs
