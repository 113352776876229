import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../App";
import useGetData from "../../../hooks/useGetData";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactTostify from "../../../common/ReactTostify";

const { Option } = Select;

const CreateTicketModel = ({
  createTikcetModelOpen,
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [initialContacts, setInitialContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await InstanceWithAuth.get(`/users-home`, {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        });
        const allEmployees = response.data.roles.reduce(
          (acc, role) => [...acc, ...role.users],
          []
        );
        setEmployees(allEmployees);

        const loggedInUser = allEmployees.find(
          (employee) => employee.id === auth?.userObj?.id
        );
        if (loggedInUser) {
          setSelectedEmployee(loggedInUser.id);
          form.setFieldsValue({ owner: loggedInUser.id }); // Set the initial form value
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await InstanceWithAuth.get(
          `contacts?query=&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
        const fetchedContacts = response?.data?.data || [];

        // const associatedContact = fetchedContacts?.find(
        //   (contact) => contact.id === ticket?.contact_id
        // );

        setContacts(fetchedContacts);
        // setSelectedContact(associatedContact?.id || null);
      } catch (error) {
        console.error("Failed to fetch contacts", error);
      }
    }

    fetchContacts();
  }, []);

  const {
    data: pipelines,
    error: pipelinesError,
    loading,
    refetchData,
  } = useGetData(`support/tickets/pipelines?status=1`);

  const { data: customFieldsData, error: customFieldsError } =
    useGetData(`ticket-custom-fields`);

  useEffect(() => {
    if (selectedPipeline) {
      form.setFieldsValue({
        pipeline: selectedPipeline.id,
        status: selectedPipeline.stages[0]?.id || "",
      });
    }
  }, [selectedPipeline, form]);

  const handlePipelineChange = (value) => {
    const pipeline = pipelines?.data?.find((p) => p.id === value);
    setStatusOptions(pipeline?.stages);
    form.setFieldsValue({
      pipeline: value,
      status: "",
    });
  };

  const handleStatusChange = (value) => {
    form.setFieldsValue({
      status: value,
    });
  };

  const handleContactSearch = async (value) => {
    try {
      const response = await InstanceWithAuth.get(
        `contacts?query=${value}&per_page=5`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      setContacts(response?.data?.data || []);
    } catch (error) {
      console.error("Failed to search contacts", error);
    }
  };
  const handleContactChange = (contactId) => {
    setSelectedContact(contactId);
    fetchCompanies(contactId);
    form.setFieldsValue({ associate_company: null });
  };

  const fetchCompanies = async (contactId) => {
    try {
      const response = await InstanceWithAuth.get(
        `companies?contact_id=${contactId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      const fetchedCompanies = response?.data?.data || [];

      setCompanies(fetchedCompanies);
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  };

  const handleSave = async () => {
    try {
      await form.validateFields();
      setIsSaving(true);

      const values = form.getFieldsValue();
      const formdata = new FormData();

      // Append all form fields to formdata
      formdata.append("support_department_id", "1");
      // formdata.append("channel", "email");
      formdata.append("contact_id", selectedContact);
      formdata.append("company_id", selectedCompany);
      formdata.append("support_pipeline_id", values.pipeline);
      formdata.append("support_pipeline_stage_id", values.status);
      formdata.append("name", values.ticketName);
      formdata.append("description", values.description);
      formdata.append("priority", values.priority);
      formdata.append("type", values.type);
      formdata.append("user_id", selectedEmployee);
      formdata.append("status", "Open");

      customFieldsData?.data?.forEach((customField) => {
        const fieldName = customField?.name;
        formdata.append(fieldName, values[fieldName] || "");
      });

      const response = await InstanceWithAuth.post(
        `support/tickets`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        form.resetFields();
        handleOk();
        ReactTostify("success", response?.data?.message);
        navigate(`/tickets/${response?.data?.data?.id}`);
      }
    } catch (error) {
        ReactTostify("error", error?.response?.data?.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={createTikcetModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Craete Ticket</span>
            </div>
            <div className="form-container">
              <div className="form-main">
                <div className="form">
                  <Form.Item
                    label="Ticket name"
                    name="ticketName"
                    className="form-items"
                  >
                    <Input className="location" placeholder="Ticket name" />
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="pipeline"
                    label="Pipeline"
                    className="form-items"
                    // rules={[
                    //   { required: true, message: "Please select a pipeline!" },
                    // ]}
                  >
                    <Select
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      showSearch
                      placeholder="Select a pipeline"
                      optionFilterProp="children"
                      onChange={handlePipelineChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {pipelines &&
                        pipelines?.data?.map((pipeline) => (
                          <Option key={pipeline.id} value={pipeline.id}>
                            {pipeline.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="status"
                    label="Stage"
                    // rules={[
                    //   { required: true, message: "Please select a status!" },
                    // ]}
                  >
                    <Select
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      showSearch
                      placeholder="Select a status"
                      onChange={handleStatusChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {statusOptions?.map((status) => (
                        <Option key={status.id} value={status.id}>
                          {status.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="welcome-message">
                <Form.Item
                  label="Ticket Description"
                  name="description"
                  className="form-items"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a description!",
                  //   },
                  // ]}
                >
                  <TextArea
                    className="input"
                    placeholder="Ticket description here..."
                  />
                </Form.Item>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    label="Priority"
                    name="priority"
                    className="form-items"
                  >
                    <Select
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      className="location"
                      placeholder="Select priority"
                    >
                      <Option value="Low">Low</Option>
                      <Option value="Medium">Medium</Option>
                      <Option value="High">High</Option>
                      <Option value="Very High">Very High</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item label="Type" name="type" className="form-items">
                    <Select
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      className="location"
                      placeholder="Select Type"
                    >
                      <Option value="Ticket">Ticket</Option>
                      <Option value="Task">Task</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              {customFieldsData?.data?.map((customFields) => (
                <>
                  {customFields?.type == "Single Select List" ? (
                    <div className="form-main">
                      <div className="form">
                        <Form.Item
                          label={customFields?.name}
                          name={customFields?.name}
                          className="form-item"
                        >
                          <Select
                            placeholder={customFields?.name}
                            className="location"
                            suffixIcon={
                              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                            }
                          >
                            {customFields?.options.map((option, index) => (
                              <Option key={index} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  ) : customFields?.type == "Multiple Select List" ? (
                    <div className="form-main">
                      <div className="form">
                        <Form.Item
                          label={customFields?.name}
                          name={customFields?.name}
                          className="form-item"
                        >
                          <Select
                            placeholder={customFields?.name}
                            className="location"
                            suffixIcon={
                              <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
                            }
                          >
                            {customFields?.options.map((option, index) => (
                              <Option key={index} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  ) : customFields?.type == "Single Line Text" ? (
                    <div className="form-main">
                      <div className="form">
                        <Form.Item
                          label={customFields?.name}
                          name={customFields?.name}
                          className="form-item"
                        >
                          <Input
                            placeholder={customFields?.name}
                            style={{ width: "100%" }}
                            className="input location"
                          />
                        </Form.Item>{" "}
                      </div>
                    </div>
                  ) : customFields?.type == "Multiple Line Text" ? (
                    <div className="form-main">
                      <div className="form">
                        <Form.Item
                          label={customFields?.name}
                          name={customFields?.name}
                          className="form-item"
                        >
                          <TextArea
                            placeholder={customFields?.name}
                            rows={4}
                            style={{ width: "100%" }}
                            className="input location"
                          />
                        </Form.Item>{" "}
                      </div>
                    </div>
                  ) : null}
                </>
              ))}

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    label="Contact"
                    name="associate_contact"
                    className="form-items"
                  >
                    <Select
                      showSearch
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      placeholder="Select contact"
                      onSearch={handleContactSearch}
                      filterOption={false}
                      value={selectedContact}
                      onChange={handleContactChange}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                    >
                      {contacts.map((contact) => (
                        <Option key={contact.id} value={contact.id}>
                          {contact.first_name} {contact.last_name} (
                          {contact.email})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    label="Company"
                    name="associate_company"
                    className="form-items"
                  >
                    <Select
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      placeholder="Select company"
                      value={selectedCompany}
                      onChange={setSelectedCompany}
                      optionLabelProp="label"
                    >
                      {companies.map((company) => (
                        <Option
                          key={company.id}
                          value={company.id}
                          label={company.name}
                        >
                          {company.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item label="Owner" name="owner" className="form-items">
                    <Select
                      className="location"
                      suffixIcon={
                        <MdOutlineKeyboardArrowDown
                          size={18}
                          width="1rem"
                          height="1rem"
                        ></MdOutlineKeyboardArrowDown>
                      }
                      placeholder="Select Owner"
                      value={selectedCompany}
                      onChange={(employeeId) => {
                        setSelectedEmployee(employeeId);
                      }}
                      optionLabelProp="label"
                    >
                      {employees?.map((employee) => (
                        <Option
                          key={employee.id}
                          value={employee.id}
                          label={`${employee.first_name} ${employee.last_name}`}
                        >
                          {employee.first_name} {employee.last_name} (
                          {employee.email})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
            >
              Close
            </button>
            <button
              className="save-button"
              type="button"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default CreateTicketModel;
