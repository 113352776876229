import React, { useEffect, useState } from "react";
import "./styles";
import { Container, Title } from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LuExternalLink } from "react-icons/lu";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import NameAvatar from "../../../../common/NameAvatar";
import {
  handSelectCompanyFlowTab,
  handSelectUserFlowTab,
} from "../../../../redux/app/appSlice";
import { InstanceWithAuth } from "../../../../App";
const SupportHeader = ({
  userID,
  selectedFolder,
  setClickedCompany,
  clickedCompany,
  ticket,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    dispatch(handSelectUserFlowTab("1"));

    const url = `${window.location.origin}/${
      clickedCompany ? "companies" : "users"
    }/${clickedCompany ? clickedCompany?.id : ticket?.contact?.id}`;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleRedirectToCompany = () => {
    dispatch(handSelectUserFlowTab("1"));
    const url = `${window.location.origin}/${
      clickedCompany ? "users" : "companies"
    }/${
      clickedCompany
        ? ticket?.contact?.id
        : clickedCompany?.id || ticket?.company?.id
    }`;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container>
      <Title>
        {clickedCompany && (
          <span className="back" onClick={() => setClickedCompany(null)}>
            <IoIosArrowBack size={14} color="#2b3746" />
          </span>
        )}

        <div className="avatar-wrapper">
          <div className="user-img">
            {/* <img className="contact-image" src={contactData?.data?.avatar} alt="" /> */}

            {ticket?.contact?.avatar || clickedCompany?.avatar ? (
              <img
                className="contact-image"
                src={
                  clickedCompany
                    ? clickedCompany?.avatar
                    : ticket?.contact?.avatar
                }
                alt=""
              />
            ) : (
              <NameAvatar
                val={40}
                userName={
                  clickedCompany
                    ? clickedCompany?.name
                    : `${ticket?.contact?.first_name || ""} ${
                        ticket?.contact?.last_name || ""
                      }`.trim()
                }
              ></NameAvatar>
            )}
          </div>
          <div className="name-wrapper">
            <span className="name">
              {clickedCompany
                ? clickedCompany?.name
                : `${ticket?.contact?.first_name || ""} ${
                    ticket?.contact?.last_name || ""
                  }`.trim()}
            </span>
            <span onClick={() => handleRedirectToCompany()} className="desc">
              {clickedCompany === null
                ? ticket?.company?.name
                : `${ticket?.contact?.first_name || ""} ${
                    ticket?.contact?.last_name || ""
                  }`.trim()}
            </span>
          </div>
        </div>
        <div className="icons">
          <span className="icon" onClick={() => handleRedirect("1")}>
            <LuExternalLink size={17}></LuExternalLink>
          </span>
        </div>
      </Title>
    </Container>
  );
};

export default SupportHeader;
