import React, { useState, useEffect } from "react";
import { SecondColumnContainer } from "./styles";
import ChatSection from "./chatSection/ChatSection";
import useGetData from "../../../hooks/useGetData";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenThread,
  setQuotedMessage,
  setThreadQuotedMessage,
} from "../../../redux/app/appSlice";

const SessionSmallerView = ({ session }) => {
  const {
    data: directMessage,
    directMessageError,
    refetchData: refetchDirectMessage,
    loading: loadingDirectMessage,
  } = useGetData(`internal-chat/sessions/${session?.id}`);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  const quotedMessage = app?.quotedMessage;

  const updateThreadQuotedMessage = (message) =>
    dispatch(setThreadQuotedMessage(message));

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchDirectMessage();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [refetchDirectMessage]);

  return (
    <SecondColumnContainer>
      <div className="chat-section">
        <ChatSection
          directMessage={directMessage}
          loadingDirectMessage={loadingDirectMessage}
          refetchDirectMessage={refetchDirectMessage}
          selectedDirectMessage={session}
          quotedMessage={quotedMessage}
          setQuotedMessage={updateThreadQuotedMessage}
        />
      </div>
    </SecondColumnContainer>
  );
};

export default SessionSmallerView;
