import React, { useState } from "react";
import { ChatItemContainer } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdPhotoSizeSelectSmall } from "react-icons/md";

const DirectMessageItem = ({
  employee,
  selectedDirectMessage,
  handleAddSession,
}) => {
  const [isOnline, setIsOnline] = useState(true);

  const TruncateText = ({ text }) => {
    const maxLength = 17;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  // Function to get the initials from the employee name and capitalize them
  const getInitials = () => {
    const { first_name, last_name } = employee;
    if (first_name && last_name) {
      return (first_name[0] + last_name[0]).toUpperCase();
    } else if (first_name) {
      return first_name.substring(0, 2).toUpperCase();
    } else if (last_name) {
      return last_name.substring(0, 2).toUpperCase();
    }
    return "";
  };

  return (
    <ChatItemContainer>
      <div
        style={{
          backgroundColor:
            selectedDirectMessage?.id === employee.id &&
            "var(--Blue-20, rgba(76, 187, 203, 0.2))",
          boxShadow:
            selectedDirectMessage?.id === employee.id &&
            "0px 0px 10px 0px rgba(86, 191, 207, 0.24)",
          borderRadius: selectedDirectMessage?.id === employee.id && "30px",
        }}
        className="outer-div"
      >
        <div className="left">
          <div className="image-div">
            {employee?.avatar ? (
              <span>
                <img src={employee.avatar} alt="employee avatar" />
              </span>
            ) : (
              <span className="initials-avatar">{getInitials()}</span>
            )}

            <div
              style={{
                background: employee.online ? "green" : "gray",
              }}
              className="status-div"
            ></div>
          </div>

          <span
            className="name"
            style={{
              fontWeight: employee?.unread_count > 0 && "600",
              fontSize: employee?.unread_count > 0 && "15px",
            }}
          >
            <TruncateText
              text={employee?.first_name + " " + employee?.last_name}
            ></TruncateText>
          </span>
        </div>

        <div className="right">
          <span className="icon" onClick={() => handleAddSession(employee)}>
            <MdPhotoSizeSelectSmall color="#2b3746" />
          </span>
          {/* <span className="icon">
            <BsThreeDotsVertical color="#2b3746" />
          </span> */}
        </div>
        {employee?.unread_count > 0 && (
          <span className="unread_count">{employee?.unread_count}</span>
        )}
      </div>
    </ChatItemContainer>
  );
};

export default DirectMessageItem;
