import React, { useState, useEffect } from "react";
import {
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  MainConatiner,
} from "./styles";
import { Popconfirm, Skeleton, Spin } from "antd";
import useGetData from "../../../../hooks/useGetData";
import { FaExpandAlt, FaMinus, FaSquare } from "react-icons/fa";
import { CrossIcon } from "../../../../common/customPill/styles";
import Avatar from "../../ThirdColumn/chatHeader/avatar/avatar";
import Chat from "./chat/Chat";
import LiveChat from "./liveChat/LiveChat";
import ChatInputComponent from "../../contractedChats/chatCard/chatInputComponent/ChatInputComponent";
import { useSelector, useDispatch } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import ReactTostify from "../../../../common/ReactTostify";
import { useNavigate } from "react-router-dom";
import { RiFullscreenLine } from "react-icons/ri";
import { AiOutlineFullscreen } from "react-icons/ai";
import { FaRegSquareFull } from "react-icons/fa6";
import { handleRefetchMailThread } from "../../../../redux/app/appSlice";
const ChatCard = ({ emailId, removeEmailFromList }) => {
  const [selectedTab, setSelectedTab] = useState("Email");
  const { auth, app } = useSelector((state) => state);
  const [savingComment, setSavingComment] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [sendingMail, setSendingMail] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingEmailThread, setLoadingEmailThread] = useState(false);
  const [email, setEmail] = useState(null);
  const refetchData = async () => {
    try {
      setLoadingEmailThread(true);

      const endpoint =
        emailId?.type === "email"
          ? `support/emails/${emailId?.id}`
          : `live-chat/${emailId?.id}`;

      const response = await InstanceWithAuth.get(endpoint, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        setEmail(response?.data);
      }
      setLoadingEmailThread(false);
    } catch (error) {
      setLoadingEmailThread(false);
    }
  };

  useEffect(() => {
    refetchData();
  }, [emailId]);

  const { data: notes, refetchData: refetchNotes } = useGetData(
    `support/emails/notes/${emailId?.id}`
  );

  
  const TruncateText = ({ text }) => {
    const maxLength = 25;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  const handleLiveChatSend = async (data) => {
    setSendingMail(true);
    const { message, files } = data;
    const formData = new FormData();

    const cleanedMessage = message.replace(/<p><br><\/p>$/, "").trim();

    formData.append("message", cleanedMessage);
    const mostRecentThread = email?.data?.threads
      ? Object.entries(email.data.threads)[0][1]
      : [];

    if (mostRecentThread.length > 0) {
      const firstItem = mostRecentThread[0];
      formData.append("live_chat_id", firstItem.live_chat_id);
    }

    formData.append("user_id", auth?.userObj?.id);

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `live-chat/reply-to-chat`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchData();
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
      setSendingMail(false);
    } finally {
      setSendingMail(false);
    }
  };

  const handleSend = async (data) => {
    setSendingMail(true);
    const { to, cc, bcc, message, files } = data;
    const formData = new FormData();

    const cleanedMessage = message.replace(/<p><br><\/p>$/, "").trim();

    const fromName = `${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`;

    formData.append("from_name", fromName);

    formData.append("message_body", cleanedMessage);

    to.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    cc.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bcc.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/reply/${emailId?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchData();
      }
    } catch (error) {
    } finally {
      setSendingMail(false);
    }
  };

  const handleCloseConversation = async () => {
    try {
      let formData = new FormData();
      formData.append(`email_ids[0]`, emailId?.id);
      let type;
      if (emailId?.type === "email") {
        type = "email";
      } else if (emailId?.type === "chat") {
        type = "chat";
      }
      formData.append("type", type);
      let url;
      if (emailId?.type === "email") {
        url = `support/emails/move_to/open`;
      } else if (emailId?.type === "chat") {
        url = `support/emails/move_to/open`;
      }
      const response = await InstanceWithAuth.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      if (response.status === 201 || response.status === 200) {
        ReactTostify("success", response?.data?.message);
        removeEmailFromList(emailId);
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    } finally {
    }
  };

  const handleOpenConversation = async () => {
    try {
      let formData = new FormData();
      formData.append(`email_ids[0]`, emailId?.id);
      let type;
      if (emailId?.type === "email") {
        type = "email";
      } else if (emailId?.type === "chat") {
        type = "chat";
      }
      formData.append("type", type);
      let url;
      if (emailId?.type === "email") {
        url = `support/emails/move_to/open`;
      } else if (emailId?.type === "chat") {
        url = `support/emails/move_to/close`;
      }

      const response = await InstanceWithAuth.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        ReactTostify("success", response?.data?.message);
        removeEmailFromList(emailId);
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    } finally {
    }
  };

  return (
    <MainConatiner style={{ height: isVisible ? "500px" : "auto" }}>
      <Header>
        <div className="make-row">
          <Avatar
            currentEmployeeImage={
              email && email?.data?.contact && emailId?.type === "email"
                ? `${email?.data?.contact?.first_name || ""} ${
                    email?.data?.contact?.last_name || ""
                  }`.trim()
                : email?.data?.contact_id && emailId?.type === "chat"
                ? `${email?.data?.contact?.first_name || ""} ${
                    email?.data?.contact?.last_name || ""
                  }`.trim()
                : email?.data?.sender_name
                ? email?.data?.sender_name
                : email?.data?.company_id
                ? email?.data?.company_name
                : email?.data?.guest_name || ""
            }
          />

          <HeaderTitle>
            <TruncateText
              text={
                email && email?.data?.contact && emailId?.type === "email"
                  ? `${email?.data?.contact?.first_name || ""} ${
                      email?.data?.contact?.last_name || ""
                    }`.trim()
                  : email?.data?.contact_id && emailId?.type === "chat"
                  ? `${email?.data?.contact?.first_name || ""} ${
                      email?.data?.contact?.last_name || ""
                    }`.trim()
                  : email?.data?.sender_name
                  ? email?.data?.sender_name
                  : email?.data?.company_id
                  ? email?.data?.company_name
                  : email?.data?.guest_name || ""
              }
            />
          </HeaderTitle>
        </div>
        <div className="make-row">
          {isVisible ? (
            <FaMinus
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(false)}
            />
          ) : (
            <FaRegSquareFull
              style={{ cursor: "pointer", marginRight: "5px" }}
              size={16}
              onClick={() => setIsVisible(true)}
            />
          )}
          <FaExpandAlt
            style={{ cursor: "pointer" }}
            size={16}
            onClick={() => {
              removeEmailFromList(emailId);
              localStorage.setItem("id", email?.data?.id);
              localStorage.setItem("folder", email?.data?.folder);
              navigate(`/support`);
            }}
          />

          <CrossIcon
            size={17}
            style={{ cursor: "pointer", marginLeft: "1px" }}
            onClick={() => removeEmailFromList(emailId)}
          />
        </div>
      </Header>
      {isVisible && (
        <>
          <>
            <Body>
              {loadingEmailThread ? (
                <div style={{ width: "330px", padding: "10px" }}>
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {/* {selectedTab === 'Email' ? ( */}
                  {emailId?.type === "email" ? (
                    <Chat
                      email={email}
                      currentEmployeeImage={email?.data?.contact}
                      selectedEmailId={emailId?.id}
                    />
                  ) : (
                    <LiveChat
                      email={email}
                      currentEmployeeImage={email?.data?.contact}
                      selectedEmailId={emailId?.id}
                      chatThread={email}
                      chatLoading={loadingEmailThread}
                      refetchThread={refetchData}
                    />
                  )}
                  {/* ) : ( */}
                  {/* <Commets
                    selectedEmailId={emailId}
                    refetchNotes={refetchNotes}
                    notes={notes}
                    onEdit={handleEditNote}
                  /> */}
                  {/* )} */}_
                </>
              )}
            </Body>

            <ChatInputComponent
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSend={
                emailId?.type === "email" ? handleSend : handleLiveChatSend
              }
              email={email}
              sendingMail={sendingMail}
              loading={loadingEmailThread}
              emailId={emailId}
              handleCloseConversation={handleCloseConversation}
              handleOpenConversation={handleOpenConversation}
            />
          </>
        </>
      )}
    </MainConatiner>
  );
};

export default ChatCard;
