import React, { useState, useEffect, useRef } from "react";
import "./styles";
import { IoAddOutline } from "react-icons/io5";
import GetLogo from "../../getlogo/getlogo";
import { Container, ProductContainer } from "./styles";
import VoiceMailSettingsModel from "../../modals/voiceMailSettingsModel/VoiceMailSettingsModel";

const VoiceMailSettings = ({
  id,
  selectedAddOns,
  setSelectedAddOns,
  initialData,
  selectedFile,
  setSelectedFile,
  voiceMailTitle,
  setVoiceMailTitle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (initialData?.addons) {
      const mappedServices = initialData?.addons?.map((item) => ({ ...item }));
      setSelectedAddOns(mappedServices);
    }
  }, [initialData, setSelectedAddOns]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (itemId) => {
    console.log(itemId);
    const updatedServices = selectedAddOns.filter((item) => item.id !== itemId);
    setSelectedAddOns(updatedServices);
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="title-main">
        <p className="title">Voicemail Settings</p>
      </div>
      <ProductContainer>
        <div className="add-products">
          <div className="product-title">
            <p className="title">All Voicemail Profiles</p>
          </div>
          <button className="add-prduct-btn" onClick={showModal}>
            <span className="btn-icon">
              <IoAddOutline />
            </span>
            <span className="btn-text">Add</span>
          </button>
        </div>
        <div className="product-items">
          {selectedAddOns?.map((item, idx) => (
            <div className="item-main">
              <div className="item">
                <div className="labels">
                  <p className="item-title" style={{ margin: "0px" }}>
                    {item?.name}
                  </p>
                  {/* <p className="description">{item?.description}</p> */}
                  {item?.description !== undefined ? null : (
                    <small className="mailbox-title">{item?.description}</small>
                  )}
                </div>
                <span
                  className="delete-icon"
                  onClick={() => handleDelete(item?.id)}
                >
                  {GetLogo("trash")}
                </span>
              </div>
            </div>
          ))}
        </div>
      </ProductContainer>
      <VoiceMailSettingsModel
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        setSelectedAddOns={setSelectedAddOns}
        selectedAddOns={selectedAddOns}
        handleDelete={handleDelete}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        voiceMailTitle={voiceMailTitle}
        setVoiceMailTitle={setVoiceMailTitle}
        audioSrc={audioSrc}
        setAudioSrc={setAudioSrc}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        audioRef={audioRef}
      />
    </Container>
  );
};

export default VoiceMailSettings;
