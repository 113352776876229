import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;

  .main-div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: ${(props) => props.mainDivWidth}%;
    position: relative;

    .header {
      background: white;
      width: 100%;
      height: 50px;
      top: 2px;
      position: absolute;
      z-index: 1000;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        gap: 10px;

        .img {
          height: 30px;
          width: 30px;
          border-radius: 30px;
          overflow: hidden;
          background: #f3f6f7;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          border: 1px solid #e3eced;
        }

        .initials-avatar {
          height: 30px;
          width: 30px;
          border-radius: 20px;
          overflow: hidden;
          background: #4cbbcb;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          border: 1px solid #e3eced;
        }

        .name {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.49px;
        }
      }
      .right {
        .icon {
          cursor: pointer;
        }
      }
    }

    .chat-div {
      width: 100%;
      height: 100%;
      display: flex;
      ${"" /* align-items: flex-end; */}
      justify-content: center;
      padding-bottom: 0px;
      overflow-y: auto !important;
      padding-top: 60px;

      .skelton-wrapper {
        width: 90%;
      }
    }

    .chat-input {
      width: 100%;
      ${"" /* min-height: 80px !important; */}
      margin-top: 10px;
      justify-content: center;
      display: flex;
      padding: 10px;
      padding-bottom: 40px;
    }
  }
`;

export { MainContainer };
