import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Input } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { TbMoodPlus } from "react-icons/tb";
import CustomTooltip from "../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip";
import TextArea from "antd/es/input/TextArea";
import { InstanceWithAuth } from "../../../../App";
import { useGlobalAlertMessage } from "../../../../hooks/useAlertMessage";

const CreateSpace = ({
  isCreateSpaceModelOpen,
  handleCancel,
  handleOk,
  refetchSpaces,
  setManageMembersClick,
  setNewCreatedSpace,
  setSelectedSpace,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const emojiPickerRef = useRef(null);
  const { showMessage } = useGlobalAlertMessage();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  const handleEmojiClick = (emojiObject) => {
    setSelectedEmoji(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  // Handle form save
  const handleSave = async () => {
    const formValues = form.getFieldsValue();
    setIsSaving(true);
    try {
      const response = await InstanceWithAuth.post(
        "internal-chat/spaces",
        {
          name: formValues?.name,
          icon: selectedEmoji,
          description: formValues?.description,
          guidlines: formValues?.guidelines,
          status: 1,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.status === 201) {
        setIsSaving(false);

        showMessage({
          type: "success",
          content: response?.data?.message,
        });
        handleResetAndClose();
        setNewCreatedSpace(response?.data?.data);
        setSelectedSpace(response?.data?.data);
        refetchSpaces();
      }
    } catch (error) {
      setIsSaving(false);

      showMessage({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  const handleResetAndClose = () => {
    form.resetFields();
    setSelectedEmoji(null);
    handleCancel();
  };

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isCreateSpaceModelOpen}
      onCancel={handleResetAndClose}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Create a space</span>
            </div>

            <div className="main-div">
              <div className="form-container">
                {showEmojiPicker && (
                  <span ref={emojiPickerRef} className="emojis">
                    <EmojiPicker
                      onEmojiClick={(emojiObject) =>
                        handleEmojiClick(emojiObject)
                      }
                    />
                  </span>
                )}

                <div className="make-row">
                  <CustomTooltip marginTop="-30px" text={"Add icon "}>
                    <span
                      className="select-emoji"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      {selectedEmoji && (
                        <span className="emoji-wrapper">{selectedEmoji}</span>
                      )}
                      {!selectedEmoji && <TbMoodPlus size={20} />}
                    </span>
                  </CustomTooltip>

                  <div className="welcome-message">
                    <Form.Item label="Name" name="name" className="form-items">
                      <Input className="input" placeholder="Space name..." />
                    </Form.Item>
                  </div>
                </div>

                <div className="text-area">
                  <Form.Item
                    label="Description"
                    name="description"
                    className="form-items"
                  >
                    <TextArea
                      className="input"
                      placeholder="Space description..."
                    />
                  </Form.Item>
                </div>

                <div className="text-area">
                  <Form.Item
                    label="Guidelines"
                    name="guidelines"
                    className="form-items"
                  >
                    <TextArea
                      className="input"
                      placeholder="Space guidelines..."
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleResetAndClose}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default CreateSpace;
