import styled from "styled-components";

const Container = styled.div`
  .form-main {
    display: flex;
    display: flex;
    flex-direction: column;
    height: auto !important;
    max-width: 548px;
    min-width: 548px;
    width: 100%;
    text-align: left;
    ${"" /* background: lightyellow; */}
    margin-bottom: 10px;

    .title {
      max-width: 548px;
      width: 100%;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
      margin-bottom: 20px;
    }

    .paragraf {
      max-width: 548px;
      width: 100%;
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      padding-bottom: 10px;
    }
    .form-item {
      margin-bottom: 0;
      height: 59px;
      max-width: 548px;
    }

    .input {
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      width: 548px;
      height: 41px;
      border-radius: 4px;
      box-shadow: none;
      border-color: unset;
      border: 1px solid #d0dee1;
      background: #fff;
    }

    .input::placeholder {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .input:focus::placeholder {
      color: transparent;
    }
    .input:focus {
      box-shadow: none;
    }

    .location {
      display: flex;
      /* padding: 12px; */
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      width: 548px;
      height: 41px;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .ant-select-selector {
        border: none;
        outline: none;
        box-shadow: none;
      }

      .ant-select-selection-item {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input {
      border-color: unset;
      border: 1px solid #d0dee1;
      box-shadow: none;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
      font-weight: 400;
      font-family: Outfit;
    }
    .ant-form-item .ant-form-item-label > label::after {
      content: "";
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
      align-self: stretch;

      label {
        height: unset;
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        align-self: stretch;
      }
    }

    .check-box-container {
      display: flex;
      padding: 10px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid rgba(208, 222, 225, 0.4);
    }
    .radio-container {
      display: flex;
      padding: 10px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid rgba(208, 222, 225, 0.4);
      .default-label {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }

      .ant-switch {
        min-width: 26px !important;
        /* height: 22px; */
        /* height: 16px;   */
      }
      .ant-switch.ant-switch-checked {
        background-color: #4cbbcb;
      }
    }

    .datePicker {
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      gap: 5.333px;
      border-radius: 4px 4px 0px 4px;
      border: 1px solid #e3eced;
      background: transparent;
      height: 36px;
      ${
        "" /* .ant-picker-clear {
        display: none;
      } */
      }
      .ant-picker-input > input::placeholder {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
`;

const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-top: 5px;
`;

const HiddenCheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

// Styled custom checkbox
const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  border: ${(props) =>
    props.checked ? "2px solid #4CBBCB" : "2px solid #D8DBE3"};
  background-color: ${(props) =>
    props.checked ? "rgba(76, 187, 203, 0.70)" : "transparent"};
  position: relative;

  &:after {
    content: "\u2713";
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(props) => (props.checked ? "block" : "none")};
  }
`;

export {
  Container,
  HiddenCheckboxInput,
  StyledCheckboxContainer,
  StyledCheckbox,
};
