import styled from "styled-components";

const Container = styled.div`
  min-height: 463px;
  height: auto !important;
  max-width: 548px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;

  .form-main {
    display: flex;
    flex-direction: column;
    height: 66px;
    max-width: 548px;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    margin-bottom: 15px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      height: 134px;
      max-width: 548px;
      width: 100%;
      gap: 16px;

      .form-items {
        margin-bottom: 0;
        height: 64px;
        max-width: 548px;
      }

      .input {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 548px;
        height: 41px;
        border-radius: 4px;
        box-shadow: none;
        border-color: unset;
        border: 1px solid #d0dee1 !important;
        background: #fff;
      }

      .input::placeholder {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .input:focus::placeholder {
        color: transparent;
      }
      .input:focus {
        box-shadow: none;
      }
      .ant-input-status-error:not(.ant-input-disabled):not(
          .ant-input-borderless
        ).ant-input {
        border-color: unset;
        border: 1px solid #d0dee1;
        box-shadow: none;
      }
      .ant-form-item-explain-error {
        font-size: 10px;
        font-weight: 400;
        font-family: Outfit;
      }
      .ant-form-item .ant-form-item-label > label::after {
        content: "";
      }
      .ant-form-item-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
        align-self: stretch;

        label {
          height: unset;
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
        }
      }
      .location {
        align-items: center;
        min-width: 548px;
        height: 41px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        height: auto;
        min-height: 42px;
        padding-top: 4px;
        padding-right: 15px !important;

        .ant-select-selector {
          border: none;
          outline: none;
          box-shadow: none;
          .ant-select-selection.ant-select-selection--single {
            background: green;
          }
        }
      }
      .location::placeholder {
        color: var(--Gray-40, rgba(85, 99, 115, 0.4));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .location:focus::placeholder {
        color: transparent;
      }
      .ant-select-selection-item {
        ${
          "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
        }
      }
    }
  }

  .title-main {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 548px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0dee1;
  background: #f3f6f7;
  max-width: 548px;
  width: 100%;
  min-height: 200px;
  height: auto !important;

  .add-products {
    max-width: 500px;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .product-title {
      display: flex;
      width: 242.5px;
      align-items: center;
      gap: 27px;
      width: 242.5px;
      max-width: 100%;

      .title {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        line-height: 120%; /* 16.8px */
      }
    }
    .add-prduct-btn {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      height: 28px;
      border-radius: 4px;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .btn-icon {
        svg {
          width: 16px;
          height: 16px;
          fill: #4cbbcb;
          color: #4cbbcb;
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }
  .product-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-width: 500px;
    width: 100%;
    ${"" /* height: 336px; */}
    .item-main {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      max-width: 500px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      .top-row {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 10px 0px;

        .item-title {
          font-family: Outfit;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          color: var(--Dark-Gray-100, #2b3746);
        }

        .icon {
          padding: 5px 5px;
          border-radius: 4px;
          border: 1px 1px 1px 1px;
          border: 1px solid #d0dee1;
          opacity: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .audio-file-display {
        display: flex;
        align-items: center;
        background-color: #f9fbfc;
        border: 1px solid #e5e8eb;
        border-radius: 5px;
        padding: 8px;
        margin-top: 10px;
        width: 100%;
        height: 41px;
        position: relative;

        .audio-icon {
          margin-right: 10px;
        }

        .audio-details {
          flex-grow: 1;
        }

        .audio-filename {
          font-size: 14px;
          color: #333;
        }

        .audio-controls {
          display: flex;
          align-items: center;
        }

        .audio-controls svg {
          margin-left: 25px;
        }
      }
    }
  }
`;

export { Container, ProductContainer };
