import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  

  .right {
    width: 100%;
  }
`;

export { MainContainer };
