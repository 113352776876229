import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import { Form, Input, Select } from "antd";
import { RiDeleteBin6Fill } from "react-icons/ri";
import useGetData from "../../../../hooks/useGetData";

const AddFilters = ({
  ticketProperties,
  setSelectedProperty,
  selectedProperty,
  contacts,
  companies,
  pipelines,
  employees,
  filters,
  setFilters,
  groups,
  setGroups,
  selectedGroupIndex,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(null);
  const [form] = Form.useForm();
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");

  useEffect(() => {
    const getStages = () => {
      const pipelineIds = groups
        ?.flatMap((group) =>
          group.filters.filter(
            (filter) =>
              filter?.field?.name === "Pipeline" && Array.isArray(filter?.value)
          )
        )
        ?.flatMap(
          (filter) => filter?.value && filter?.value?.map((item) => item?.id)
        );

      let filteredStages = [];

      if (pipelineIds?.length > 0) {
        filteredStages = pipelines?.data
          ?.filter((pipeline) => pipelineIds.includes(pipeline.id))
          ?.flatMap((pipeline) =>
            pipeline?.stages?.map((stage) => ({
              ...stage,
              pipelineName: pipeline.name,
            }))
          );
      } else {
        filteredStages = pipelines?.data?.flatMap((pipeline) =>
          pipeline?.stages?.map((stage) => ({
            ...stage,
            pipelineName: pipeline.name,
          }))
        );
      }

      setStageOptions(
        filteredStages?.map((stage) => ({
          value: stage.id,
          label: stage.name,
        }))
      );
    };

    getStages();
  }, [groups, pipelines]);
  const filteredProperties = ticketProperties.filter((property) =>
    property.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handlePropertySelect = (property) => {
    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      const selectedGroup = updatedGroups[selectedGroupIndex];

      const isAlreadyPresent = selectedGroup?.filters?.some(
        (filter) => filter?.field?.name === property?.name
      );

      if (isAlreadyPresent) {
        return prevGroups;
      }

      const defaultOperator = property.options?.[0]?.replace(/\s+/g, "_") || "";
      selectedGroup.filters.push({
        field: property,
        operator: defaultOperator,
        value: "",
      });

      return updatedGroups;
    });

    setSelectedProperty(property);
    setSearchValue("");
  };

  const handleOperatorChange = (operator, propertyName) => {
    console.log("operator", operator);
    setSelectedOperator(operator);
    setGroups((prevGroups) =>
      prevGroups.map((group, groupIndex) =>
        groupIndex === selectedGroupIndex
          ? {
              ...group,
              filters: group?.filters.map((filter) =>
                filter?.field?.name === propertyName
                  ? { ...filter, operator }
                  : filter
              ),
            }
          : group
      )
    );
  };
  const handleValueChange = (value, propertyName) => {
    setGroups((prevGroups) =>
      prevGroups.map((group, groupIndex) =>
        groupIndex === selectedGroupIndex
          ? {
              ...group,
              filters: group?.filters?.map((filter) =>
                filter.field.name === propertyName
                  ? { ...filter, value }
                  : filter
              ),
            }
          : group
      )
    );
  };
  const handleDelete = () => {
    setSelectedProperty(null);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.replace(/\s+/g, "_"),
      label: option,
    }));

  useEffect(() => {
    if (selectedProperty?.options?.length > 0) {
      const defaultValue = selectedProperty.options[0].replace(/\s+/g, "_");
      form.setFieldsValue({
        service_id: defaultValue,
      });
      console.log("defaultValue", defaultValue);
      setSelectedOperator(defaultValue);
    }
  }, [selectedProperty, form]);

  const ownerOptions = employees?.roles
    .flatMap((role) => role?.users)
    .map((user) => ({
      value: `${user?.first_name} ${user?.last_name}`,
      label: `${user?.first_name} ${user?.last_name}`,
    }));

  return (
    <Container>
      <span className="heading">
        {!selectedProperty ? "Add Filters" : "Edit Filter"}
      </span>

      {!selectedProperty ? (
        <>
          <div className="search-field">
            <Form.Item name="search" className="form-item">
              <Input
                className="location"
                placeholder="Search in ticket properties"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Form.Item>
          </div>

          <div className="list">
            {filteredProperties.map((property, index) => (
              <div
                key={index}
                className="list-item"
                onClick={() => {
                  handlePropertySelect(property);
                  // setSelectedPropertyIndex(index);
                }}
              >
                <span className="icon">{property.icon}</span>
                <span className="text">{property.name}</span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="outer-div">
          <div className="header">
            <span className="name">{selectedProperty.name}</span>
            <span className="icon" onClick={handleDelete}>
              <RiDeleteBin6Fill color="rgb(76, 187, 203)" />
            </span>
          </div>

          <div className="form-main">
            <div className="form">
              <Form form={form}>
                <Form.Item name="service_id" className="form-item">
                  <Select
                    showSearch
                    placeholder="Select an option"
                    className="location"
                    style={{ width: "100% !important" }}
                    options={formattedOptions(selectedProperty?.options || [])}
                    onChange={(value) =>
                      handleOperatorChange(value, selectedProperty.name)
                    }
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
          {selectedOperator !== "is_known" &&
            selectedOperator !== "is_unknown" && (
              <div className="form-main">
                <div className="form">
                  <Form form={form}>
                    {(() => {
                      switch (selectedProperty.name) {
                        case "Ticket Name":
                          return (
                            <Form.Item name="ticket_name" className="form-item">
                              <Input
                                placeholder="Enter Ticket Name"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                onChange={(e) => {
                                  handleValueChange(
                                    e.target.value,
                                    "Ticket Name"
                                  );
                                }}
                              />
                            </Form.Item>
                          );
                        case "Ticket Description":
                          return (
                            <Form.Item
                              name="ticket_description"
                              className="form-item"
                            >
                              <Input
                                placeholder="Enter Ticket Description"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                onChange={(e) => {
                                  handleValueChange(
                                    e.target.value,
                                    "Ticket Description"
                                  );
                                }}
                              />
                            </Form.Item>
                          );
                        case "Create Date":
                          return (
                            <Form.Item name="create_date" className="form-item">
                              <Input
                                type="date"
                                placeholder="Select Create Date"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                              />
                            </Form.Item>
                          );
                        case "Associated Contact":
                          return (
                            <Form.Item
                              name="associated_contact"
                              className="form-item"
                            >
                              <Select
                                placeholder="Select Associated Contact"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                showSearch
                                options={contacts?.data?.map((contact) => ({
                                  value: contact.id,
                                  label: `${contact.first_name} ${contact.last_name}`,
                                }))}
                                // onChange={(value) => {
                                //   const selected = contacts.data.find(
                                //     (contact) => contact.id === value
                                //   );
                                //   setSelectedContact(selected);
                                // }}

                                onChange={(value) => {
                                  const selected = contacts.data.find(
                                    (contact) => contact.id === value
                                  );

                                  handleValueChange(
                                    selected,
                                    "Associated Contact"
                                  );
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        case "Company":
                          return (
                            <Form.Item name="company" className="form-item">
                              <Select
                                placeholder="Select Company"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                showSearch
                                options={companies?.data?.map((company) => ({
                                  value: company.id,
                                  label: `${company?.name}`,
                                }))}
                                // onChange={(value) => {
                                //   const selected = companies.data.find(
                                //     (company) => company.id === value
                                //   );
                                //   setSelectedCompany(selected);
                                // }}

                                onChange={(value) => {
                                  const selected = companies.data.find(
                                    (company) => company.id === value
                                  );

                                  handleValueChange(selected, "Company");
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        case "Ticket Owner":
                          return (
                            <Form.Item
                              name="ticket_owner"
                              className="form-item"
                            >
                              <Select
                                placeholder="Select Ticket Owner"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                options={ownerOptions}
                                onChange={(value) => {
                                  const selected = employees?.roles
                                    .flatMap((role) => role.users)
                                    .find(
                                      (user) =>
                                        `${user?.first_name} ${user?.last_name}` ===
                                        value
                                    );

                                  handleValueChange(selected, "Ticket Owner");
                                }}
                              />
                            </Form.Item>
                          );
                        case "Priority":
                          return (
                            <Form.Item name="priority" className="form-item">
                              <Select
                                placeholder="Select Priority"
                                className="location"
                                mode="multiple"
                                style={{
                                  width: "100% !important",
                                }}
                                options={[
                                  "Low",
                                  "Medium",
                                  "High",
                                  "Very High",
                                ].map((priority) => ({
                                  value: priority,
                                  label: priority,
                                }))}
                                // onChange={(value) => {
                                //   setPriority(value);
                                // }}
                                onChange={(value) => {
                                  handleValueChange(value, "Priority");
                                }}
                              />
                            </Form.Item>
                          );
                        case "Support Category":
                          return (
                            <Form.Item
                              name="support_category"
                              className="form-item"
                            >
                              <Select
                                placeholder="Select Support Category"
                                className="location"
                                mode="multiple"
                                style={{
                                  width: "100% !important",
                                }}
                                showSearch
                                optionFilterProp="label"
                                options={[
                                  "Sales & General Inquiries",
                                  "Account Updates",
                                  "Billing & Invoices",
                                  "Upgrades",
                                  "Downgrades",
                                  "Mail Support & Virtual Mailbox",
                                  "Phone & Live Answering",
                                  "USPS 1583 & Notarization Support",
                                  "Add-On Services",
                                  "Cancellations",
                                ].map((category) => ({
                                  value: category,
                                  label: category,
                                }))}
                                // onChange={(value) => {
                                //   setCategory(value);
                                // }}
                                onChange={(value) => {
                                  handleValueChange(value, "Support Category");
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        case "Channel":
                          return (
                            <Form.Item name="channel" className="form-item">
                              <Select
                                placeholder="Select Channel"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                mode="multiple"
                                showSearch
                                optionFilterProp="label"
                                options={[
                                  "Checkout",
                                  "Dashboard",
                                  "Email",
                                  "Chat",
                                  "Phone",
                                  "Walkin",
                                  "Referral",
                                ].map((method) => ({
                                  value: method,
                                  label: method,
                                }))}
                                onChange={(value) => {
                                  handleValueChange(value, "Channel");
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        case "Status":
                          return (
                            <Form.Item name="status" className="form-item">
                              <Select
                                placeholder="Select Status"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                mode="multiple"
                                options={[
                                  "Open",
                                  "Closed",
                                  "In progress",
                                  "Resolved",
                                  "Soft Close",
                                  "Await Reply",
                                ].map((status) => ({
                                  value: status,
                                  label: status,
                                }))}
                                onChange={(value) => {
                                  handleValueChange(value, "Status");
                                }}
                              />
                            </Form.Item>
                          );
                        case "Pipeline":
                          return (
                            <Form.Item name="pipeline" className="form-item">
                              <Select
                                placeholder="Select Pipeline"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                allowClear="true"
                                mode="multiple"
                                showSearch
                                options={pipelines?.data?.map((pipeline) => ({
                                  value: pipeline.id,
                                  label: `${pipeline?.name}`,
                                }))}
                                // onChange={(value) => {
                                //   setPipeline(value);
                                // }}
                                onChange={(value) => {
                                  const selectedPipelines =
                                    pipelines.data.filter((pipeline) =>
                                      value.includes(pipeline.id)
                                    );
                                  handleValueChange(
                                    selectedPipelines,
                                    "Pipeline"
                                  );
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        case "Stage":
                          return (
                            <Form.Item name="stage" className="form-item">
                              <Select
                                placeholder="Select Stage"
                                className="location"
                                style={{
                                  width: "100% !important",
                                }}
                                allowClear="true"
                                mode="multiple"
                                showSearch
                                options={stageOptions}
                                // onChange={(value) => {
                                //   setPipeline(value);
                                // }}
                                onChange={(value) => {
                                  const selectedStages = pipelines?.data
                                    .flatMap((pipeline) => pipeline.stages)
                                    .filter((stage) =>
                                      value.includes(stage.id)
                                    );
                                  handleValueChange(selectedStages, "Stage");
                                }}
                                filterOption={(input, option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </Form>
                </div>
              </div>
            )}
        </div>
      )}
    </Container>
  );
};

export default AddFilters;
