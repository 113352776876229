import styled from "styled-components";

const SecondColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .chat-section {
    width: 100%;
    height: 100%;
  }
`;

export { SecondColumnContainer };
