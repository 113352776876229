import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #e3eced;
  padding: 0px 20px;
  gap: 10px;
  min-height: 60px;
  background: white;

  .details-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .contact-name {
      font-size: 16px;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.49px;
    }
    .bottom-line-div {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      overflow: visible;
      flex-wrap: wrap;
      .from {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      .from-date {
        flex: 0 0 auto;
        white-space: nowrap;
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      .company-name {
        font-size: 16px;
        color: var(--Blue-100, #4cbbcb);
        font-family: Outfit;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.49px;
      }
      .dot {
        height: 3px;
        width: 3px;
        background: var(--Gray-100, #556373);
        border-radius: 3px;
        margin-left: 4px;
      }
      .link {
        cursor: pointer;
      }
    }
  }
`

const AvatarPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4cbbcb;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`

export { Container, AvatarPlaceholder }
