import React, { useState, useEffect, useRef } from "react";
import { MainContainer } from "./styles";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import FirstColumn from "../../components/chatWithManager/FirstColumn/FirstColumn";
import SecondColumn from "../../components/chatWithManager/secondColumn/SecondColumn";
import useGetData from "../../hooks/useGetData";
import { useDispatch, useSelector } from "react-redux";
import DirectChat from "../../components/chatWithManager/DirectChat/DirectChat";
import {
  setOpenThread,
  setQuotedMessage,
  setThreadQuotedMessage,
} from "../../redux/app/appSlice";

const ChatManager = () => {
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedDirectMessage, setSelectedDirectMessage] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [manageMembersClick, setManageMembersClick] = useState(false);
  const [newCreatedSpace, setNewCreatedSpace] = useState(null);
  const [mainDivWidth, setMainDivWidth] = useState(100);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  const quotedMessage = app?.quotedMessage;
  const threadQuotedMessage = app?.threadQuotedMessage;
  const openThread = app?.openThread;

  const updateQuotedMessage = (message) => dispatch(setQuotedMessage(message));
  const updateThreadQuotedMessage = (message) =>
    dispatch(setThreadQuotedMessage(message));
  const updateOpenThread = (thread) => dispatch(setOpenThread(thread));

  const {
    data: spaces,
    error,
    refetchData: refetchSpaces,
    loading: loadingSpaces,
  } = useGetData("internal-chat/spaces");
  const {
    data: EmployeeList,
    error: errorEmployeeList,
    refetchData: refetchEmployeeList,
    loading: loadingEmployeeList,
  } = useGetData(`internal-chat/sessions/employee-list`);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchEmployeeList();
      refetchSpaces();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [refetchEmployeeList]);

  return (
    <div style={{ position: "relative" }}>
      <CommonHaeder firstBcItem={"Internal chat"} firstBcIcon={"supportChat"} />
      <MainContainer>
        <div className="first-column">
          <FirstColumn
            spaces={spaces?.data}
            setSelectedSpace={setSelectedSpace}
            setSelectedDirectMessage={setSelectedDirectMessage}
            refetchSpaces={refetchSpaces}
            EmployeeList={EmployeeList}
            loadingEmployeeList={loadingEmployeeList}
            loadingSpaces={loadingSpaces}
            quotedMessage={quotedMessage}
            setQuotedMessage={updateQuotedMessage}
            selectedSpace={selectedSpace}
            selectedDirectMessage={selectedDirectMessage}
            firstRender={firstRender}
            setFirstRender={setFirstRender}
            manageMembersClick={manageMembersClick}
            setManageMembersClick={setManageMembersClick}
            newCreatedSpace={newCreatedSpace}
            setNewCreatedSpace={setNewCreatedSpace}
            setOpenThread={updateOpenThread}
            setMainDivWidth={setMainDivWidth}
            setThreadQuotedMessage={updateThreadQuotedMessage}
          />
        </div>

        <div className="second-column">
          {selectedSpace ? (
            <SecondColumn
              selectedSpace={selectedSpace}
              refetchSpaces={refetchSpaces}
              selectedDirectMessage={selectedDirectMessage}
              quotedMessage={quotedMessage}
              setQuotedMessage={updateQuotedMessage}
              threadQuotedMessage={threadQuotedMessage}
              setThreadQuotedMessage={updateThreadQuotedMessage}
              firstRender={firstRender}
              setFirstRender={setFirstRender}
              manageMembersClick={manageMembersClick}
              setManageMembersClick={setManageMembersClick}
              newCreatedSpace={newCreatedSpace}
              openThread={openThread}
              setOpenThread={updateOpenThread}
              mainDivWidth={mainDivWidth}
              setMainDivWidth={setMainDivWidth}
              setNewCreatedSpace={setNewCreatedSpace}
              setSelectedSpace={setSelectedSpace}
            />
          ) : selectedDirectMessage ? (
            <DirectChat
              selectedDirectMessage={selectedDirectMessage}
              refetchEmployeeList={refetchEmployeeList}
              firstRender={firstRender}
              setFirstRender={setFirstRender}
              quotedMessage={quotedMessage}
              setQuotedMessage={updateQuotedMessage}
            />
          ) : null}
        </div>
      </MainContainer>
    </div>
  );
};

export default ChatManager;
