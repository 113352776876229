import React, { useState, useEffect, useRef, useCallback } from "react";
import { SupportSearchContainer } from "./styles";
import FiltersCoulumn from "./filtersCoulumn/FiltersCoulumn";
import SearchBar from "./searchBar/SearchBar";
import emptyStateCharts from "./empty-state-charts.svg";
import noConversations from "./no-conversations.svg";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../App";
import EmailsList from "./EmailsList/EmailsList";
import ThirdColumn from "../ThirdColumn/ThirdColumn";
import useGetData from "../../../hooks/useGetData";
import { useParams } from "react-router-dom";
import FourthColumnDeatils from "./FourthColumnDeatils/FourthColumnDeatils";
import ReactTostify from "../../../common/ReactTostify";

const SupportSearch = () => {
  const { auth, app } = useSelector((state) => state);
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({
    status: null,
    contact: null,
    assignee: null,
    startDate: null,
    endDate: null,
  });
  const initialRender = useRef(true);
  const { mailID } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [emails, setEmails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [order, setOrder] = useState("DESC");
  const [openContactDrawer, setOpenContactDrawer] = useState(false);
  const [selectedEmailOrChat, setSelectedEmailOrChat] = useState(null);
  const [skipFetch, setSkipFetch] = useState(false);
  const {
    data: email,
    error,
    loading: loadingEmailThread,
    refetchData,
  } = useGetData(
    `support/emails/${selectedEmailOrChat ? selectedEmailOrChat : mailID}`
  );

  const id = localStorage.getItem("id");

  const showDrawerContactDrawer = () => {
    setOpenContactDrawer(true);
  };

  const onCloseContactDrawer = () => {
    setOpenContactDrawer(false);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  useEffect(() => {
    setSelectedEmailOrChat(null);
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (skipFetch) {
      setSkipFetch(false);
      return;
    }

    const areFiltersEmpty = !Object.values(filters).some(
      (value) => value !== null && value !== undefined
    );
    const isSearchTermEmpty = !searchTerm;

    if (areFiltersEmpty && isSearchTermEmpty) {
      setEmails(null);
      return;
    }

    fetchEmails();
  }, [filters, searchTerm, order]);

  const fetchEmails = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(
        Object.entries({
          order: order,
          status: filters.status,
          contact_id: filters.contact,
          user_id: filters.assignee,
          date_from: formatDate(filters.startDate),
          date_to: formatDate(filters.endDate),
          query: searchTerm,
        }).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {})
      );

      if (!queryParams.toString()) {
        setEmails([]); // Clear emails if no query
        setLoading(false);
        return;
      }

      const response = await InstanceWithAuth.get(
        `all-conversations?${queryParams}&filter_data=true`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setEmails(response?.data?.data?.data || []);
      }
    } catch (error) {
      ReactTostify("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [filters, searchTerm, order, auth?.userObj?.api_token]);

  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
  };

  return (
    <SupportSearchContainer>
      <div className="first-column">
        <FiltersCoulumn
          form={form}
          filters={filters}
          setFilters={setFilters}
          setSearchTerms={setSearchTerm}
          setEmails={setEmails}
          setSelectedEmailOrChat={setSelectedEmailOrChat}
          setSkipFetch={setSkipFetch}
        />
      </div>
      <div className="columns-wrapper">
        <div className="searchBar">
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={(term) => setSearchTerm(term)}
          />
        </div>
        <div className="second-column">
          <EmailsList
            loading={loading}
            initialEmails={emails}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            order={order}
            setOrder={setOrder}
            selectedEmailId={selectedEmailOrChat}
            setSelectedEmailOrChat={setSelectedEmailOrChat}
          />
        </div>
        {selectedEmailOrChat ? (
          <div className="third-column-1">
            {selectedEmailOrChat && (
              <ThirdColumn
                selectedEmailId={selectedEmailOrChat}
                onOwnerChange={handleOwnerChange}
                // refetchCount={refetchCountRef.current}
                setSelectedEmailOrChat={setSelectedEmailOrChat}
                showDrawerContactDrawer={showDrawerContactDrawer}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                // selectedFolder={selectedFolder}
                id={id}
                refetchData={refetchData}
                loadingEmailThread={loadingEmailThread}
                email={email}
              />
            )}

            {selectedEmailOrChat && (
              <div className="fourth-column">
                <FourthColumnDeatils
                  showDrawerContactDrawer={showDrawerContactDrawer}
                  onCloseContactDrawer={onCloseContactDrawer}
                  selectedEmailId={selectedEmailOrChat}
                  setSelectedEmailOrChat={setSelectedEmailOrChat}
                  refetchEmails={fetchEmails}
                  selectedEmails={selectedEmails}
                  setSelectedEmails={setSelectedEmails}
                  // selectedFolder={selectedFolder}
                  // selectedCompanyId={selectedCompanyId}
                  // setRefetchEmail={(refetchFn) => {
                  //   refetchEmailRef.current = refetchFn;
                  // }}
                  id={id}
                  refetchEmail={refetchData}
                  refetchData={refetchData}
                  loading={loadingEmailThread}
                  email={email}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="third-column">
            <>
              {emails?.length > 0 ? (
                <>
                  <img src={noConversations} alt="empty state charts" />
                  <span className="text">Select a result to view</span>
                </>
              ) : (
                <>
                  <img src={emptyStateCharts} alt="empty state charts" />
                  <span className="text">
                    Search for anything in Customer Support by typing in a term
                    or applying filters
                  </span>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </SupportSearchContainer>
  );
};

export default SupportSearch;
