import React, { useState, useEffect } from "react";
import { SecondColumnContainer } from "./styles";
import SpaceOptionsDropDown from "./spaceOptionsDropDown/SpaceOptionsDropDownn";
import ManageMembers from "./manageMembers/ManageMembers";
import { IoMdArrowBack } from "react-icons/io";
import ChatSection from "./chatSection/ChatSection";
import useGetData from "../../../hooks/useGetData";
import { MdPhotoSizeSelectSmall } from "react-icons/md";
import EmptyChat from "./chatSection/emptyChat/EmptyChat";
import { useDispatch, useSelector } from "react-redux";
import { handleAddInternalChatSpace } from "../../../redux/app/appSlice";

const SecondColumn = ({
  selectedSpace,
  refetchSpaces,
  selectedDirectMessage,
  quotedMessage,
  setQuotedMessage,
  threadQuotedMessage,
  setThreadQuotedMessage,
  firstRender,
  setFirstRender,
  manageMembersClick,
  setManageMembersClick,
  newCreatedSpace,
  openThread,
  setOpenThread,
  mainDivWidth,
  setMainDivWidth,
  setNewCreatedSpace,
  setSelectedSpace
}) => {
  const {
    data: spaceMembers,
    error,
    refetchData: refetchSpaceMembers,
  } = useGetData(`internal-chat/spaces/${selectedSpace?.id}/members`);
  const {
    data: spaceMessages,
    spaceMessagesError,
    refetchData: refetchSpaceMessages,
    loading: loadingMessages,
  } = useGetData(`internal-chat/spaces/${selectedSpace?.id}/messages`);
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchSpaceMessages();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [refetchSpaceMessages]);

  useEffect(() => {
    if (spaceMessages) {
      refetchSpaces();
    }
  }, [spaceMessages]);

  const handleAddSpace = () => {
    if (selectedSpace) {
      const isSpaceSelected = app?.internalChatsSpaces?.some(
        (space) => space.id === selectedSpace.id
      );
      if (!isSpaceSelected) {
        dispatch(handleAddInternalChatSpace(selectedSpace));
      }
    }
  };

  return (
    <SecondColumnContainer>
      <div className="header">
        <div className="left">
          {manageMembersClick && (
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setManageMembersClick(false)}
            >
              <IoMdArrowBack size={20} color="#556373" />
            </span>
          )}
          <span className="img">{selectedSpace?.icon}</span>
          <div className="name-div">
            <SpaceOptionsDropDown
              setManageMembersClick={setManageMembersClick}
              selectedSpace={selectedSpace}
              setSelectedSpace={setSelectedSpace}
            />
            <span className="members">
              {spaceMembers?.data?.length} members
            </span>
          </div>
        </div>
        <div className="right">
          <span className="icon" onClick={() => handleAddSpace()}>
            <MdPhotoSizeSelectSmall size={20} color="#2b3746" />
          </span>
        </div>
      </div>
      {manageMembersClick ? (
        <ManageMembers
          spaceMembers={spaceMembers}
          refetchSpaceMembers={refetchSpaceMembers}
          selectedSpace={selectedSpace}
          newCreatedSpace={newCreatedSpace}
          setNewCreatedSpace={setNewCreatedSpace}
          setSelectedSpace={setSelectedSpace}
        />
      ) : newCreatedSpace ? (
        <EmptyChat setManageMembersClick={setManageMembersClick} selectedSpace={selectedSpace} />
      ) : (
        <div className="chat-section">
          <ChatSection
            spaceMessages={spaceMessages}
            loadingMessages={loadingMessages}
            selectedSpace={selectedSpace}
            refetchSpaceMessages={refetchSpaceMessages}
            quotedMessage={quotedMessage}
            setQuotedMessage={setQuotedMessage}
            threadQuotedMessage={threadQuotedMessage}
            setThreadQuotedMessage={setThreadQuotedMessage}
            firstRender={firstRender}
            setFirstRender={setFirstRender}
            openThread={openThread}
            setOpenThread={setOpenThread}
            mainDivWidth={mainDivWidth}
            setMainDivWidth={setMainDivWidth}
          />
        </div>
      )}
    </SecondColumnContainer>
  );
};

export default SecondColumn;
