import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { Form, Input, Select } from "antd";
import "./styles";

const UserVisibility = ({
  id,
  liveAnsweringData,
  form,
  numberDetails,
  callHandling,
  setCallHandling,
}) => {
  const options = [
    {
      label: "Forward to Direct Number",
      value: 1,
    },
    {
      label: "Forward to Live Answering Plan",
      value: 2,
    },
    {
      label: "Forward to Voicemail",
      value: 3,
    },
    {
      label: "Forward to IVR (Coming Soon)",
      value: 4,
    },
  ];

  const voicemailOptions = [
    {
      label: "Use Starthub’s Generic Voicemail (does not say Starthub)",
      value: "Use Starthub’s Generic Voicemail (does not say Starthub)",
    },
    {
      label: "Use your own voicemail file",
      value: "Use your own voicemail file",
    },
    {
      label: "Get a professional custom voice recording ($30 one-time fee)",
      value: "Get a professional custom voice recording ($30 one-time fee)",
    },
  ];

  const answeringPlans = [
    { label: "Plan A", value: "plan_a" },
    { label: "Plan B", value: "plan_b" },
    { label: "Plan C", value: "plan_c" },
  ];

  const handleCallHandlingChange = (value) => {
    setCallHandling(value);
  };

  const liveAnsweringPlans = liveAnsweringData?.data?.map((plan) => {
    const firstKey = Object.keys(plan)[0];
    return {
      label: `${plan[firstKey]} (${plan.phone_number})`,
      value: plan.id,
    };
  });

  useEffect(() => {
    if (!numberDetails) {
      form.setFieldsValue({
        call_handling: 1,
      });
    }
  }, [numberDetails]);

  return (
    <Container id={id} className="scroll-section">
      <p className="title">Forwarding Options</p>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Forwarding Options"
              name="call_handling_type"
              className="form-items"
            >
              <Select
                placeholder="Select Option"
                className="location"
                style={{ width: "100%" }}
                onChange={handleCallHandlingChange}
                options={options}
              />
            </Form.Item>
          </div>
        </div>
        {callHandling === 1 && (
          <div className="form-main">
            <div className="form">
              <Form.Item
                label="Forwarding Phone Number"
                name="forward_to_direct_number"
                className="form-items"
              >
                <Input className="input" placeholder="(704) 555-0127" />
              </Form.Item>
            </div>
          </div>
        )}

        {callHandling === 2 && (
          <div className="form-main">
            <div className="form">
              <Form.Item
                label="Live Answering Plan"
                name="forward_to_live_answering_plan"
                className="form-items"
              >
                <Select
                  placeholder="Select a Plan"
                  className="location"
                  style={{ width: "100%" }}
                  options={liveAnsweringPlans}
                />
              </Form.Item>
            </div>
          </div>
        )}

        {callHandling === 3 && (
          <div className="form-main">
            <div className="form">
              <Form.Item
                label="Voicemail Options"
                name="forward_to_voicemail"
                className="form-items"
              >
                <Select
                  placeholder="Select Voicemail Option"
                  className="location"
                  style={{ width: "100%" }}
                  options={voicemailOptions}
                />
              </Form.Item>
            </div>
          </div>
        )}

        {callHandling === 4 && (
          <div className="form-main">
            <div className="form">
              <p>IVR options are coming soon. Stay tuned for updates!</p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default UserVisibility;
